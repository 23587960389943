import React from "react";
import { getClassNames } from "./helpPanel.classNames";
import { Panel, PanelType } from "@fluentui/react";
import {logEvent} from '../../../helpers/telemetryService';

export interface IHelpPanelProps {
	visible: boolean;
	updatePanelVisibility: (visible: boolean) => void;
}

const HelpPanel: React.FC<IHelpPanelProps> = (props) => {
	const classes = getClassNames();

	const onDismiss = () => {
		logEvent("Help Panel Closed");
		props.updatePanelVisibility(false);
	};

	return (
		<Panel
			className={classes.root}
			isBlocking={false}
			isOpen={props.visible}
			type={PanelType.medium}
			headerText="Flicon Guidance"
			closeButtonAriaLabel="Close"
			onDismiss={onDismiss}>
				<p>Thanks for giving Flicon a try! This tool is currently in beta. There are lots more features planned - so keep checking back! In the meantime, please send feedback and suggestions to <a href="mailto:me@thechriskent.com">me@thechriskent.com</a>. Don't like how something was categorized? Have some tag suggestions? Bugs? Snide remarks? Digital gift certificates? All appreciated!</p>
				<p>This tool is using icons from @fluentui/react v7.121.12</p>
				<p>The set of icons is always growing. While this is awesome, the same set isn't always available everywhere. Additional guidance for this is coming soon, but for now be aware that it's possible an icon seen here isn't yet available in the version of SPFx you're using or within SharePoint list views.</p>
				<p>This tool is free and will keep being free. Afterall, <a href="https://aka.ms/sppnp">Sharing is Caring</a>!</p>
		</Panel>
	)
}

export default HelpPanel;