import React, {useContext} from 'react';
import {ThemeContext} from '../../App';
import {getClassNames} from './footer.classNames';
import { Stack, Link, Image } from '@fluentui/react';
import profileImage from './profile.png';

const Footer: React.FC<{}> = () => {
	const {palette} = useContext(ThemeContext);
	const classes = getClassNames(palette);

	return (
		<Stack horizontal className={classes.root} verticalAlign="center">
			<Stack.Item grow className={classes.agreement}>
				<span>Use of these icons is subject to the </span>
				<Link href="https://aka.ms/fabric-assets-license" target="_blank">Microsoft Fabric Assets License Agreement</Link>
			</Stack.Item>
			<Link href="https://twitter.com/thechriskent" target="_blank">
			<Stack horizontal verticalAlign="center">
				<Stack.Item className={classes.credit}>
					<span>Created by Chris Kent</span>
				</Stack.Item>
				<Stack.Item>
					<Image src={profileImage} className={classes.profileImage}/>
				</Stack.Item>
			</Stack>
			</Link>
		</Stack>
	);
}
export default Footer;