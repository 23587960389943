import { Dropdown, IDropdownOption, ScrollablePane, SearchBox, Separator, Stack, Sticky, StickyPositionType } from "@fluentui/react";
import * as OfficialIcons from "@fluentui/react-icons";
import React, { useContext, useRef, useState } from "react";

import { ThemeContext } from "../../App";
import IIconDetails, { combineIconDetails, IconCategory, IconType, categoryTextOverride } from "../../helpers/iconDetails";
import IconBox from "./iconBox/iconBox";
import Pager from "./pager/pager";
import Refiner from "./refiner/refiner";
import { getClassNames } from "./search.classNames";
import IconList from './iconList/iconList';


const autoIcons = Object.keys(OfficialIcons).reduce((acc: Array<IIconDetails>, exportName) => {
	if (!!(OfficialIcons as any)[exportName]?.displayName) {
		const displayName: string = (OfficialIcons as any)[exportName]?.displayName;
		acc.push({
			name: displayName.substring(0, displayName.lastIndexOf("Icon")),
			component: (OfficialIcons as any)[exportName] as React.FunctionComponent,
		});
	}
	return acc;
}, []);

const icons = combineIconDetails(autoIcons);

const iconTypeValues = Object.keys(IconType).filter(key => isNaN(Number(key)));
const iconCategoryValues = Object.keys(IconCategory).filter(key => isNaN(Number(key)));

if(process.env.NODE_ENV === "development") {
	//Debugging to see what icons are missing values
	iconTypeValues.push('None');
	iconCategoryValues.push('None');
}


const Search: React.FC<{}> = () => {
	const [query, setQuery] = useState("");
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const placeholder = useRef(placeholderText());
	const [typeRefinerSelections, setTypeRefinerSelections] = useState(new Array<string>('Solid','Wireframe'));
	const [categoryRefinerSelections, setCategoryRefinerSelections] = useState(new Array<string>());

	const iconResults = filteredIcons(query, typeRefinerSelections, categoryRefinerSelections);

	const total = iconResults.length;
	const pages = Math.floor(total / pageSize) + (total % pageSize > 0 ? 1 : 0);

	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette);

	return (
		<Stack verticalFill className={classes.root}>
			<Stack.Item className={classes.searchBoxRow}>
				<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
					<Stack.Item grow>
						<SearchBox
							value={query}
							placeholder={placeholder.current}
							onChange={(e, value) => {
								setQuery(value || "");
								setPage(1);
							}} />
					</Stack.Item>
					<Stack.Item>
						<Dropdown
							className={classes.pageSize}
							selectedKey={pageSize}
							options={[
								{ key: 50, text: 'Groups of 50' },
								{ key: 100, text: 'Groups of 100' },
								{ key: 300, text: 'Groups of 300' },
								{ key: 500, text: 'Groups of 500' },
								{ key: 0, text: 'Show All' },
							]}
							onChange={(ev, option?: IDropdownOption) => setPageSize(option!.key as number)} />
					</Stack.Item>
				</Stack>
			</Stack.Item>
			<Stack.Item grow>
				<Stack horizontal verticalFill>
					<Stack.Item>
						<Stack>
							<Stack.Item>
								<Refiner
									label="Type"
									options={iconTypeValues}
									checkedOptions={typeRefinerSelections}
									onChange={checkedOptions => {
										setPage(1);
										setTypeRefinerSelections(checkedOptions);
									}} />
							</Stack.Item>
							<Stack.Item>
								<Refiner
									label="Category"
									options={iconCategoryValues}
									checkedOptions={categoryRefinerSelections}
									onChange={checkedOptions => {
										setPage(1);
										setCategoryRefinerSelections(checkedOptions);
									}}
									textForOptions={categoryTextOverride} />
							</Stack.Item>
						</Stack>
					</Stack.Item>
					<Stack.Item grow className={classes.resultsSection}>
						{pageSize === 0 &&
							<>
								<Separator className={classes.resultsCount}>{`${iconResults.length.toLocaleString()} of ${icons.length.toLocaleString()} icons`}</Separator>
								<IconList icons={iconResults} query={query} />
							</>
						}
						{pageSize > 0 &&
							<ScrollablePane scrollbarVisibility="auto">
								<Stack wrap horizontal horizontalAlign="center" verticalAlign="start">
									{iconResults
										.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
										.map((iconDetails) => (
											<IconBox key={iconDetails.name} iconDetails={iconDetails} query={query} />
										))}
								</Stack>
								<Sticky stickyPosition={StickyPositionType.Footer}>
									<div className={classes.pagerRow}>
										<Separator className={classes.resultsCount}>{`${iconResults.length.toLocaleString()} of ${icons.length.toLocaleString()} icons`}</Separator>
										<Pager pageCount={pages} currentPage={page} setPage={(newPage) => setPage(newPage)} />
									</div>
								</Sticky>
							</ScrollablePane>
						}
					</Stack.Item>
				</Stack>
			</Stack.Item>
		</Stack>
	);
}

const filteredIcons = (query: string, typeRefiners: Array<string>, categoryRefiners: Array<string>): Array<IIconDetails> => {
	const searchTerm = query.toLowerCase();
	return icons.filter((icon) => {
		return (
			// Type Refiner
			(typeRefiners.length === 0 || ((typeof icon.type !== "undefined" && typeRefiners.includes(IconType[icon.type]))) || (typeof icon.type === "undefined" && typeRefiners.includes("None"))) &&

			// Category Refiner
			(categoryRefiners.length === 0 || ((typeof icon.category !== "undefined" && categoryRefiners.includes(IconCategory[icon.category]))) || (typeof icon.category === "undefined" && categoryRefiners.includes("None"))) &&

			// Query in tags or name
			(icon.name.toLowerCase().indexOf(searchTerm) > -1 ||
				(typeof icon.tags !== "undefined" && icon.tags.some(tag => tag.includes(searchTerm))))
		);
	});
}

/**
 * Provides a random search suggestion
 */
const placeholderText = (): string => {
	const placeholderSuggestions = ["dog", "cat", "food", "face", "configuration", "contact", "arrow", "clock", "luggage", "heart", "star", "athletics", "email", "shield", "locked", "phone"];
	return `Try searching "${placeholderSuggestions[Math.floor(Math.random() * placeholderSuggestions.length)]}"`;
}

export default Search;