import React, { useContext, useRef, useState } from 'react';
import { getClassNames } from './colorSelector.classNames';
import { Stack, Dropdown, IDropdownOption, IPalette, IconButton, Callout, Label, DirectionalHint, ColorPicker, IColor } from '@fluentui/react';
import { ThemeContext } from '../../../../../App';

export interface IColorSelectorProps {
	label: string;
	disabled?: boolean;
	selectedKey: string;
	customColor: string;
	onChange: (slot: string, color: string) => void;
}

const ColorSelector: React.FC<IColorSelectorProps> = (props) => {
	const colorContainer = useRef(null);
	const [colorPickerVisible, setColorPickerVisible] = useState(false);
	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette);

	const onRenderPaletteOption = (option?: IDropdownOption): JSX.Element => {
		const color = option!.key === "custom" ? '#000' : palette[option!.key as keyof IPalette];
		return (
			<div className={classes.paletteOption}>
				<div style={{ backgroundColor: color }} className={classes.paletteOptionColorBlock}></div>
				<span>{option!.text}</span>
			</div>
		)
	};

	return (
		<div className={classes.root}>
			<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
				<Stack.Item grow>
					<Dropdown
						label={props.label}
						disabled={props.disabled}
						selectedKey={props.selectedKey}
						onRenderOption={onRenderPaletteOption}
						onChange={(e, option?: IDropdownOption) => {
							if (option!.key !== 'custom') {
								props.onChange(option!.key.toString(), palette[option!.key as keyof IPalette]);
							} else {
								props.onChange(option!.key.toString(), props.customColor);
							}
						}}
						options={props.selectedKey === 'custom' ?
							[...Object.keys(palette).map((key: string) => {
								return { key: key, text: key };
							}), { key: 'custom', text: 'Custom color' }]
							: Object.keys(palette).map((key: string) => {
								return { key: key, text: key };
							})} />
				</Stack.Item>
				<Stack.Item>
					<div ref={colorContainer}>
						<IconButton
							disabled={props.disabled}
							className={classes.colorPickerIcon}
							iconProps={{ iconName: "ColorSolid" }}
							title="Choose a custom color"
							onClick={() => { setColorPickerVisible(!colorPickerVisible) }} />
						{colorPickerVisible && (
							<Callout
								className={classes.colorPickerCallout}
								target={colorContainer}
								onDismiss={() => setColorPickerVisible(false)}
								directionalHint={DirectionalHint.leftBottomEdge}>

								<Label className={classes.colorPickerLabel}>{props.label} custom color</Label>
								<ColorPicker
									color={props.customColor}
									alphaType="none"
									showPreview={true}
									onChange={(e, newValue: IColor) => props.onChange('custom', `#${newValue.hex}`)} />

							</Callout>
						)}
					</div>
				</Stack.Item>
			</Stack>
		</div>
	);
};

export default ColorSelector;