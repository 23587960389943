import { loadTheme } from '@fluentui/react';

//** Defines Basic theme colors (leaves out named colors) */
export interface IPalette {
	themeDarker: string;
	themeDark: string;
	themeDarkAlt: string;
	themePrimary: string;
	themeSecondary: string;
	themeTertiary: string;
	themeLight: string;
	themeLighter: string;
	themeLighterAlt: string;
	black: string;
	blackTranslucent40: string;
	neutralDark: string;
	neutralPrimary: string;
	neutralPrimaryAlt: string;
	neutralSecondary: string;
	neutralSecondaryAlt: string;
	neutralTertiary: string;
	neutralTertiaryAlt: string;
	neutralQuaternary: string;
	neutralQuaternaryAlt: string;
	neutralLight: string;
	neutralLighter: string;
	neutralLighterAlt: string;
	accent: string;
	white: string;
	whiteTranslucent40: string;
}

export interface ITheme {
	name: string;
	palette: IPalette;
}

export const StandardThemes: Array<ITheme> = [
	{
		name: 'Blue',
		palette: { "themeDarker": "#004578", "themeDark": "#005a9e", "themeDarkAlt": "#106ebe", "themePrimary": "#0078d4", "themeSecondary": "#2b88d8", "themeTertiary": "#71afe5", "themeLight": "#c7e0f4", "themeLighter": "#deecf9", "themeLighterAlt": "#eff6fc", "black": "#000000", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#212121", "neutralPrimary": "#333333", "neutralPrimaryAlt": "#3c3c3c", "neutralSecondary": "#666666", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#a6a6a6", "neutralTertiaryAlt": "#c8c8c8", "neutralQuaternary": "#d0d0d0", "neutralQuaternaryAlt": "#dadada", "neutralLight": "#eaeaea", "neutralLighter": "#f4f4f4", "neutralLighterAlt": "#f8f8f8", "accent": "#8764b8", "white": "#ffffff", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Orange',
		palette: { "themeDarker": "#712d09", "themeDark": "#993e0c", "themeDarkAlt": "#b5490f", "themePrimary": "#ca5010", "themeSecondary": "#d06228", "themeTertiary": "#df8f64", "themeLight": "#efc4ad", "themeLighter": "#f6dfd2", "themeLighterAlt": "#fdf7f4", "black": "#000000", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#212121", "neutralPrimary": "#333333", "neutralPrimaryAlt": "#3c3c3c", "neutralSecondary": "#666666", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#a6a6a6", "neutralTertiaryAlt": "#c8c8c8", "neutralQuaternary": "#d0d0d0", "neutralQuaternaryAlt": "#dadada", "neutralLight": "#eaeaea", "neutralLighter": "#f4f4f4", "neutralLighterAlt": "#f8f8f8", "accent": "#986f0b", "white": "#ffffff", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Red',
		palette: { "themeDarker": "#5b1519", "themeDark": "#7c1d21", "themeDarkAlt": "#932227", "themePrimary": "#a4262c", "themeSecondary": "#ae383e", "themeTertiary": "#c86c70", "themeLight": "#e3afb2", "themeLighter": "#f0d3d4", "themeLighterAlt": "#fbf4f4", "black": "#000000", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#212121", "neutralPrimary": "#333333", "neutralPrimaryAlt": "#3c3c3c", "neutralSecondary": "#666666", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#a6a6a6", "neutralTertiaryAlt": "#c8c8c8", "neutralQuaternary": "#d0d0d0", "neutralQuaternaryAlt": "#dadada", "neutralLight": "#eaeaea", "neutralLighter": "#f4f4f4", "neutralLighterAlt": "#f8f8f8", "accent": "#ca5010", "white": "#ffffff", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Purple',
		palette: { "themeDarker": "#4b3867", "themeDark": "#664b8c", "themeDarkAlt": "#7959a5", "themePrimary": "#8764b8", "themeSecondary": "#9372c0", "themeTertiary": "#b29ad4", "themeLight": "#d7c9ea", "themeLighter": "#e9e2f4", "themeLighterAlt": "#f9f8fc", "black": "#000000", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#212121", "neutralPrimary": "#333333", "neutralPrimaryAlt": "#3c3c3c", "neutralSecondary": "#666666", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#a6a6a6", "neutralTertiaryAlt": "#c8c8c8", "neutralQuaternary": "#d0d0d0", "neutralQuaternaryAlt": "#dadada", "neutralLight": "#eaeaea", "neutralLighter": "#f4f4f4", "neutralLighterAlt": "#f8f8f8", "accent": "#038387", "white": "#ffffff", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Green',
		palette: { "themeDarker": "#294903", "themeDark": "#386304", "themeDarkAlt": "#427505", "themePrimary": "#498205", "themeSecondary": "#5a9117", "themeTertiary": "#85b44c", "themeLight": "#bdda9b", "themeLighter": "#dbebc7", "themeLighterAlt": "#f6faf0", "black": "#000000", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#212121", "neutralPrimary": "#333333", "neutralPrimaryAlt": "#3c3c3c", "neutralSecondary": "#666666", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#a6a6a6", "neutralTertiaryAlt": "#c8c8c8", "neutralQuaternary": "#d0d0d0", "neutralQuaternaryAlt": "#dadada", "neutralLight": "#eaeaea", "neutralLighter": "#f4f4f4", "neutralLighterAlt": "#f8f8f8", "accent": "#038387", "white": "#ffffff", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Gray',
		palette: { "themeDarker": "#3a4346", "themeDark": "#4f5b5f", "themeDarkAlt": "#5d6c70", "themePrimary": "#69797e", "themeSecondary": "#78888d", "themeTertiary": "#9fadb1", "themeLight": "#cdd5d8", "themeLighter": "#e4e9ea", "themeLighterAlt": "#f8f9fa", "black": "#000000", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#212121", "neutralPrimary": "#333333", "neutralPrimaryAlt": "#3c3c3c", "neutralSecondary": "#666666", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#a6a6a6", "neutralTertiaryAlt": "#c8c8c8", "neutralQuaternary": "#d0d0d0", "neutralQuaternaryAlt": "#dadada", "neutralLight": "#eaeaea", "neutralLighter": "#f4f4f4", "neutralLighterAlt": "#f8f8f8", "accent": "#0078d4", "white": "#ffffff", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Dark Yellow',
		palette: { "themeDarker": "#ffe092", "themeDark": "#ffd56c", "themeDarkAlt": "#ffce51", "themePrimary": "#ffc83d", "themeSecondary": "#e0b036", "themeTertiary": "#997825", "themeLight": "#4d3c12", "themeLighter": "#29200a", "themeLighterAlt": "#0a0802", "black": "#f8f8f8", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#f4f4f4", "neutralPrimary": "#ffffff", "neutralPrimaryAlt": "#dadada", "neutralSecondary": "#d0d0d0", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#c8c8c8", "neutralTertiaryAlt": "#6d6d6d", "neutralQuaternary": "#4f4f4f", "neutralQuaternaryAlt": "#484848", "neutralLight": "#3f3f3f", "neutralLighter": "#313131", "neutralLighterAlt": "#282828", "accent": "#ffc83d", "white": "#1f1f1f", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
	{
		name: 'Dark Blue',
		palette: { "themeDarker": "#8ac2ec", "themeDark": "#65aee6", "themeDarkAlt": "#4ba0e1", "themePrimary": "#3a96dd", "themeSecondary": "#3385c3", "themeTertiary": "#235a85", "themeLight": "#112d43", "themeLighter": "#091823", "themeLighterAlt": "#020609", "black": "#f8f8f8", "blackTranslucent40": "rgba(0,0,0,.4)", "neutralDark": "#f4f4f4", "neutralPrimary": "#ffffff", "neutralPrimaryAlt": "#dadada", "neutralSecondary": "#d0d0d0", "neutralSecondaryAlt": "#767676", "neutralTertiary": "#c8c8c8", "neutralTertiaryAlt": "#4f637a", "neutralQuaternary": "#374a5f", "neutralQuaternaryAlt": "#324459", "neutralLight": "#2b3d51", "neutralLighter": "#223244", "neutralLighterAlt": "#1d2b3c", "accent": "#3a96dd", "white": "#182534", "whiteTranslucent40": "rgba(255,255,255,.4)" }
	},
];



export const setTheme = (isCustom:boolean, name:string) => {
	let palette;

	if(isCustom) {
		//Load from file system (if not already loaded)
	} else {
		//Find the theme by name from the Standard Themes
		const theme = StandardThemes.find((theme:ITheme) => {
			return theme.name === name;
		});
		if (typeof theme !== "undefined") {
			palette = theme.palette;
		}
	}

	if (typeof palette !== "undefined") {
		loadTheme({
			palette: palette
		});
	}
}