import { mergeStyleSets, memoizeFunction, IPalette } from "@fluentui/react"

export interface IPagerClassNames {
	root: string;
	pageLink: string;
	navButton: string;
	navButtonRight: string;
}

export const getClassNames = memoizeFunction((palette: IPalette): IPagerClassNames => {
	return mergeStyleSets({
		root: {
			fontSize: '11px',
			paddingBottom: '12px',
		},
		pageLink: {
			color: palette.themeDark,
			fontSize: '11px',
			borderRadius: '50%',
			width: '20px',
			height: '20px',
			minWidth: '20px',
			border: 'none',
			padding: '0 3px',
			selectors: {
				'&:hover': {
					color: palette.themeDark,
				},
				'&:disabled': {
					backgroundColor: 'transparent',
					color: palette.neutralSecondary,
					fontWeight: '600',
				},
			},
		},
		navButton: {
			width: '20px',
			height: '20px',
			borderRadius: '50%',
			selectors: {
				'& i': {
					fontSize: '10px',
					marginTop: '1px',
					marginLeft: '4px',
				},
				'&:disabled': {
					backgroundColor: 'transparent',
				},
				'&:disabled i': {
					color: palette.neutralQuaternaryAlt,
				},
			},
		},
		navButtonRight: {
			selectors: {
				'& i': {
					marginLeft: '6px',
				},
			}
		}
	});
});
