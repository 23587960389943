import { IPalette, memoizeFunction, mergeStyleSets } from "@fluentui/react";

export interface IRefinerClassNames {
	root: string;
	label: string;
	clearLink: string;
	options: string;
	checkbox: string;
}

export const getClassNames = memoizeFunction((palette: IPalette): IRefinerClassNames => {
	return mergeStyleSets({
		root: {
			padding: '0 18px 24px',
			color: palette.black,
		},
		label: [
			{
				fontWeight: '600',
			},
		],
		clearLink: {
			color: palette.neutralQuaternary,
			fontSize: '12px',
			marginLeft: '4px',
			selectors: {
				'& i': {
					fontSize: '6px',
					margin: '0 3px',
				},
				'&:hover': {
					color: palette.themeDark,
					textDecoration: 'none',
				}
			},
		},
		options: {
			padding: '6px 0 0 12px',
		},
		checkbox: {
			selectors: {
				'& .ms-Checkbox-checkbox': {
					width: '14px',
					height: '14px',
					marginTop: '3px',
				},
				'& .ms-Checkbox-checkbox i': {
					fontSize: '10px',
				},
			}
		},
	});
});
