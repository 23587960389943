import { mergeStyleSets, memoizeFunction, IPalette } from "@fluentui/react"

export interface ISearchClassNames {
	root: string;
	searchBoxRow: string;
	pageSize: string;
	resultsSection: string;
	pagerRow: string;
	resultsCount: string;
}

export const getClassNames = memoizeFunction((palette:IPalette): ISearchClassNames => {
	return mergeStyleSets({
		root: {

		},
		searchBoxRow: {
			padding: '12px',
		},
		pageSize: {
			width: '110px',
			selectors: {
				'& .ms-Dropdown-title': {
					fontSize: '11px',
					color: palette.neutralSecondary,
				},
			},
		},
		resultsSection: {
			padding: '0 12px',
			position: 'relative',
			selectors: {
				'& .ms-ScrollablePane [aria-hidden="true"]': {
					overflow: 'hidden', //fixes issue with sticky component overlapping real components
				},
			},
		},
		pagerRow: {
			padding: '12px 24px 0 24px',
			textAlign: 'center',
			backgroundColor: palette.white, //Issue with Sticky items not getting theme updates
		},
		resultsCount: {
			marginBottom: '2px',
			selectors: {
				'& div': {
					color: palette.neutralTertiary,
					fontSize: '12px',
				},
			},
		},
	});
});
