import {mergeStyleSets, memoizeFunction} from "@fluentui/react"

export interface IIconOptionsClassNames {
	root: string;
	mainOptions: string;
	colorOptions: string;
	colorOptionsExpander: string;
}

export const getClassNames = memoizeFunction((): IIconOptionsClassNames => {
	return mergeStyleSets({
		root: {
			selectors: {
				'& .ms-Dropdown-label, & .ms-Toggle-label': {
					fontSize: '12px',
				},
				'& .ms-Dropdown-title': {
					fontSize: '12px',
					lineHeight: '20px',
					height: '22px'
				},
				'& .ms-Dropdown-caretDownWrapper': {
					lineHeight: '20px',
					height: '22px',
				},
				'& .ms-Toggle-innerContainer': {
					marginTop: '1px',
				}
			},
		},
		mainOptions: {

		},
		colorOptions: {
			marginTop: '6px',
		},
		colorOptionsExpander: {
			borderRadius: '50%',
		},
	});
});
