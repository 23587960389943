import { mergeStyleSets, memoizeFunction} from "@fluentui/react";

export interface IHelpPanelClassNames {
	root: string;
}

export const getClassNames = memoizeFunction((): IHelpPanelClassNames => {
	return mergeStyleSets({
		root: {
			top: '50px',
			height: 'calc(100% - 50px)',
		},
	});
});