import { Dropdown, IconButton, IDropdownOption, Label, Stack, Toggle } from "@fluentui/react";
import React, { useState } from "react";

import { IconSize } from "../../../../App";
import ColorSelector from "./colorSelector/colorSelector";
import { getClassNames } from "./iconOptions.classNames";

export interface IIconOptionsProps {
	size: IconSize;
	showName: boolean;
	showBox: boolean;
	onSizeChange: (size: IconSize) => void;
	onShowNameChange: (show: boolean) => void;
	onShowBoxChange: (show: boolean) => void;

	iconSlot: { theme: string; customColor: string; };
	iconHoverSlot: { theme: string; customColor: string; };
	backgroundSlot: { theme: string; customColor: string; };
	backgroundHoverSlot: { theme: string; customColor: string; };

	onChangeSlot: (slot: string, theme: string, customColor: string) => void;
}

const IconOptions: React.FC<IIconOptionsProps> = (props) => {
	const [colorOptionsExpanded, setColorOptionsExpanded] = useState(false);
	const classes = getClassNames();

	return (
		<div className={classes.root}>
			<div className={classes.mainOptions}>
				<Label>Icon Options</Label>
				<Stack horizontal verticalAlign="start" tokens={{ childrenGap: 12 }}>
					<Stack.Item grow>
						<Dropdown
							label="Size"
							selectedKey={IconSize[props.size]}
							onChange={(e, option?: IDropdownOption) => props.onSizeChange(IconSize[option!.key as keyof typeof IconSize])}
							options={[
								{ key: 'small', text: 'Small' },
								{ key: 'medium', text: 'Medium' },
								{ key: 'large', text: 'Large' },
							]} />
					</Stack.Item>
					<Stack.Item>
						<Toggle
							label="Box"
							checked={props.showBox}
							onChange={(e, checked) => props.onShowBoxChange(checked!)} />
					</Stack.Item>
					<Stack.Item>
						<Toggle
							label="Name"
							checked={props.showName}
							onChange={(e, checked) => props.onShowNameChange(checked!)} />
					</Stack.Item>
				</Stack>
			</div>

			<div className={classes.colorOptions}>
				<Stack horizontal>
					<Stack.Item grow>
						<Label>Advanced Color Options</Label>
					</Stack.Item>
					<Stack.Item>
						<IconButton
							className={classes.colorOptionsExpander}
							iconProps={{ iconName: colorOptionsExpanded ? 'ChevronUpMed' : 'ChevronDownMed' }}
							onClick={() => setColorOptionsExpanded(!colorOptionsExpanded)} />
					</Stack.Item>
				</Stack>

				{colorOptionsExpanded &&
					<>
						<ColorSelector
							label="Icon"
							selectedKey={props.iconSlot.theme}
							customColor={props.iconSlot.customColor}
							onChange={(theme, color) => { props.onChangeSlot('icon', theme, color) }} />
						<ColorSelector
							label="Icon hover"
							selectedKey={props.iconHoverSlot.theme}
							customColor={props.iconHoverSlot.customColor}
							onChange={(theme, color) => { props.onChangeSlot('iconHover', theme, color) }} />
						<ColorSelector
							label="Box background"
							disabled={!props.showBox}
							selectedKey={props.backgroundSlot.theme}
							customColor={props.backgroundSlot.customColor}
							onChange={(theme, color) => { props.onChangeSlot('background', theme, color) }} />
						<ColorSelector
							label="Box background hover"
							disabled={!props.showBox}
							selectedKey={props.backgroundHoverSlot.theme}
							customColor={props.backgroundHoverSlot.customColor}
							onChange={(theme, color) => { props.onChangeSlot('backgroundHover', theme, color) }} />
					</>
				}

			</div>
		</div>
	);
}

export default IconOptions;