import { mergeStyleSets, memoizeFunction } from "@fluentui/react";

export interface IIconListClassNames {
	root: string;
}

export const getClassNames = memoizeFunction((): IIconListClassNames => {
	return mergeStyleSets({
		root: {
			position: 'relative',
			height: 'calc(100% - 29px)',
		},
	});
});
