import { StandardThemes } from "../../../helpers/themes";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react";
import React, { useContext, useState, useRef } from "react";
import { DisplayContext, IconSize } from '../../../App';

import ThemePicker from "./themePicker/themePicker";
import { getClassNames } from './themePanel.classNames';
import IconOptions from './iconOptions/iconOptions';
import {logEvent} from '../../../helpers/telemetryService';

export interface IThemePanelProps {
	visible: boolean;
	currentTheme: string;

	updateThemeSelection: (theme: string) => void;
	tryThemeSelection: (theme: string) => void;
	updatePanelVisibility: (visible: boolean) => void;
}

const ThemePanel: React.FC<IThemePanelProps> = (props) => {
	const displayContext = useContext(DisplayContext);
	const [selectedTheme, setSelectedTheme] = useState(props.currentTheme);
	const activeDisplayContext = useRef(displayContext);

	const classes = getClassNames();

	const onDismiss = () => {
		logEvent("Display Panel Closed - no changes");
		//restore the previous theme
		setSelectedTheme(props.currentTheme);
		props.updateThemeSelection(props.currentTheme);

		//restore icon options
		displayContext.changeSize(activeDisplayContext.current.iconSize);
		displayContext.changeShowBox(activeDisplayContext.current.showBox);
		displayContext.changeShowName(activeDisplayContext.current.showName);
		displayContext.changeSlot('icon',activeDisplayContext.current.iconSlot.theme, activeDisplayContext.current.iconSlot.customColor);
		displayContext.changeSlot('iconHover',activeDisplayContext.current.iconHoverSlot.theme, activeDisplayContext.current.iconHoverSlot.customColor);
		displayContext.changeSlot('background',activeDisplayContext.current.backgroundSlot.theme, activeDisplayContext.current.backgroundSlot.customColor);
		displayContext.changeSlot('backgroundHover',activeDisplayContext.current.backgroundHoverSlot.theme, activeDisplayContext.current.backgroundHoverSlot.customColor);

		activeDisplayContext.current = displayContext;

		props.updatePanelVisibility(false);
	}

	const onSave = () => {
		//Telemetry stuff
		if(selectedTheme !== props.currentTheme) {
			logEvent("Changed Theme",{"Theme": selectedTheme});
		}
		if(activeDisplayContext.current.showName !== displayContext.showName) {
			logEvent("Changed ShowName", {"ShowName": displayContext.showName});
		}
		if(activeDisplayContext.current.showBox !== displayContext.showBox) {
			logEvent("Changed ShowBox", {"ShowBox": displayContext.showBox});
		}
		if(activeDisplayContext.current.iconSize !== displayContext.iconSize) {
			logEvent("Changed IconSize", {"IconSize": IconSize[displayContext.iconSize]});
		}
		logEvent("Closed Display Panel");
		
		activeDisplayContext.current = displayContext;
		props.updateThemeSelection(selectedTheme);
		props.updatePanelVisibility(false);
	}

	return (
		<Panel
			className={classes.root}
			isBlocking={false}
			isOpen={props.visible}
			type={PanelType.smallFixedFar}
			headerText="Display Options"
			closeButtonAriaLabel="Close"
			isFooterAtBottom={true}
			onDismiss={onDismiss}
			onRenderFooterContent={(): JSX.Element => {
				return (
					<div>
						<PrimaryButton onClick={onSave} style={{ marginRight: '4px' }}>Save</PrimaryButton>
						<DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
					</div>
				);
			}}>

			<ThemePicker
				label="Theme"
				themes={StandardThemes}
				selectedKey={typeof selectedTheme == "undefined" ? undefined : (selectedTheme)}
				onChange={(themeName: string) => {
					props.tryThemeSelection(themeName);
					setSelectedTheme(themeName);
				}} />

			<IconOptions
				size={displayContext.iconSize}
				onSizeChange={displayContext.changeSize}
				showBox={displayContext.showBox}
				onShowBoxChange={displayContext.changeShowBox}
				showName={displayContext.showName}
				onShowNameChange={displayContext.changeShowName}
				iconSlot={displayContext.iconSlot}
				iconHoverSlot={displayContext.iconHoverSlot}
				backgroundSlot={displayContext.backgroundSlot}
				backgroundHoverSlot={displayContext.backgroundHoverSlot}
				onChangeSlot={displayContext.changeSlot} />
		</Panel>
	);
};

export default ThemePanel;