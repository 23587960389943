import { FocusZone, IRectangle, List, ScrollablePane } from "@fluentui/react";
import React, { useCallback, useContext, useRef } from "react";

import { DisplayContext } from "../../../App";
import IIconDetails from "../../../helpers/iconDetails";
import IconBox from "../iconBox/iconBox";
import {getClassNames} from './iconList.classNames';

const IconList: React.FC<{ icons: IIconDetails[], query: string }> = ({ icons, query }) => {
	const displayContext = useContext(DisplayContext);
	const numOfColumns = useRef(0);
	const rowsPerPage = 5;
	const iconHeight = displayContext.iconSize;
	const classes = getClassNames();

	const getItemCountForPage = (itemIndex?: number, visibleRect?: IRectangle): number => {
		if (itemIndex === 0) {
			//First Row, so calculate column count per row
			numOfColumns.current = Math.ceil(visibleRect!.width / iconHeight);
		}
		return numOfColumns.current * rowsPerPage;
	}

	const getPageHeight = (): number => {
		return rowsPerPage * iconHeight;
	}

	const onRenderCell = useCallback((item?: IIconDetails) => {
		return (
			<IconBox iconDetails={item!} query={query} />
		);
	}, [query]);

	return (
		<div className={classes.root}>
			<ScrollablePane scrollbarVisibility="auto">
				<FocusZone>
					<List
						items={icons}
						onRenderCell={onRenderCell}
						getItemCountForPage={getItemCountForPage}
						getPageHeight={getPageHeight}
						renderedWindowsAhead={4} />
				</FocusZone>
			</ScrollablePane>
		</div>
	);
}

export default IconList;