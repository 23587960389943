import { ITheme } from "../../../../helpers/themes";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React, { useContext } from "react";

import ThemeItem from "./themeItem/themeItem";
import { ThemeContext } from '../../../../App';
import { getClassNames, IThemePickerClassNames } from './themePicker.classNames '


export interface IThemePickerProps {
	themes: Array<ITheme>;
	label: string;
	selectedKey?: string;
	onChange: (key: string) => void;
}

const ThemePicker: React.FC<IThemePickerProps> = (props) => {
	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette);

	return (
		<div className={classes.root}>
			<ChoiceGroup
				label={props.label}
				options={themesToOptions(classes, props.themes)}
				selectedKey={props.selectedKey}
				onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
					if (typeof option == "undefined") {
						props.onChange("");
					} else {
						props.onChange(option.key.toString());
					}
				}} />
		</div>
	);
}

const themesToOptions = (classes: IThemePickerClassNames, themes: ITheme[]): IChoiceGroupOption[] => {
	let options = new Array<IChoiceGroupOption>();
	themes.forEach((theme: ITheme) => {
		options.push({
			key: theme.name,
			text: theme.name,
			onRenderLabel: (option) => {
				return (
					<div className={classes.item}>
						<ThemeItem
							name={theme.name}
							white={theme.palette.white}
							black={theme.palette.black}
							themePrimary={theme.palette.themePrimary}
							themeSecondary={theme.palette.themeSecondary}
							themeTertiary={theme.palette.themeTertiary}
							accent={theme.palette.accent || theme.palette.themePrimary} />
					</div>
				)
			}
		})
	});
	return options;
}

export default ThemePicker;