import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Customizer, mergeStyles, Customizations} from '@fluentui/react'
import * as serviceWorker from './serviceWorker';
import TelemetryProvider from './components/telemetryProvider/telemetryProvider';

import { initializeIcons } from '@uifabric/icons';

initializeIcons();

mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

ReactDOM.render(
  <Customizer {...Customizations}>
    <TelemetryProvider instrumentationKey="e72f43a5-e2af-408e-b2ea-2ee6f72d7af3">
      <App />
    </TelemetryProvider>
  </Customizer>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
