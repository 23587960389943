import { Checkbox, Icon, Link } from "@fluentui/react";
import React, { useContext } from "react";

import { ThemeContext } from "../../../App";
import { getClassNames } from "./refiner.classNames";
import { isFunction } from "util";

declare interface IRefinerProps {
	label: string;
	options: Array<string>;
	textForOptions?: (option:string) => string;
	checkedOptions: Array<string>;
	onChange: (checkedOptions: Array<string>) => void;
};

const Refiner: React.FC<IRefinerProps> = (props) => {
	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette);

	return (
		<div className={classes.root}>
			<span className={classes.label}>{props.label}</span>
			{props.checkedOptions.length > 0 &&
				<Link className={classes.clearLink} onClick={() => props.onChange([])}>
					<Icon iconName="Clear" />
					<span>Clear</span>
				</Link>
			}
			<div className={classes.options}>
				{
					props.options.map((option) => {
						return (
							<Checkbox
								label={typeof props.textForOptions === "undefined" || !isFunction(props.textForOptions) ? option : props.textForOptions(option)}
								key={`refiner-${props.label}-${option}`}
								className={classes.checkbox}
								checked={props.checkedOptions.includes(option)}
								onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
									if (typeof checked !== "undefined" && checked === true) {
										props.onChange([...props.checkedOptions, option]);
									} else {
										props.onChange([...props.checkedOptions.slice(0, props.checkedOptions.indexOf(option)),
										...props.checkedOptions.slice(props.checkedOptions.indexOf(option) + 1)]);
									}
								}} />
						);
					})
				}
			</div>
		</div>
	)
}

export default Refiner;