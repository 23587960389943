import {mergeStyleSets, memoizeFunction, IPalette} from "@fluentui/react"

export interface IColorSelectorClassNames {
	root: string;
	paletteOption: string;
	paletteOptionColorBlock: string;
	colorPickerCallout: string;
	colorPickerIcon: string;
	colorPickerLabel: string;
}

export const getClassNames = memoizeFunction((palette: IPalette): IColorSelectorClassNames => {
	return mergeStyleSets({
		root: {
			
		},
		paletteOption: {
			whiteSpace: 'nowrap',
		},
		paletteOptionColorBlock: {
			width: '12px',
			height: '12px',
			display: 'inline-block',
			marginRight: '6px',
		},
		colorPickerCallout: {
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: palette.neutralQuaternary,
		},
		colorPickerIcon: {
			width: '20px',
			height: '20px',
			padding: '0',
			borderRadius: '50%',
			marginTop: '24px',
			selectors: {
				'& i': {
					fontSize: '16px',
					lineHeight: '20px',
				},
			},
		},
		colorPickerLabel: {
			margin: '6px 16px -10px 16px',
		},
	});
});