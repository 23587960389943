import {mergeStyleSets, memoizeFunction, IPalette} from "@fluentui/react"

export interface IThemePickerClassNames {
	root: string;
	item: string;
}

export const getClassNames = memoizeFunction((palette:IPalette): IThemePickerClassNames => {
	return mergeStyleSets({
		root: {
			marginTop: '0',
			marginBottom: '20px',
			width: '100%',
			color: palette.black,
			selectors: {
				'& .ms-ChoiceField-field::after, & .ms-ChoiceField-field::before': {
					top: '50% !important',
					transform: 'translateY(-50%)',
				}
			}
		},
		item: {
			padding: '4px 36px',
			cursor: 'pointer',
			position: 'relative',
			outline: 'transparent',
			paddingRight: '0'
		},
	});
});
