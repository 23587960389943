import React, {useContext} from "react";
import { ThemeContext } from "../../../../App";
import { getClassNames } from "./iconVariation.classNames";

const IconVariation: React.FC<{paletteKey:string, darkMode?:boolean}> = ({paletteKey, darkMode = false, children}) => {
	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette, paletteKey, darkMode);
	return (
		<div className={classes.root} title={`${paletteKey} on '${darkMode ? 'black' : 'white'}'`}>
			{children}
		</div>
	)
}

export default IconVariation;