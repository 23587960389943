import React, { useContext } from "react";
import { getClassNames } from './themeItem.classNames'
import { ThemeContext } from "../../../../../App";

export interface IThemeItemProps {
	name: string;
	white: string;
	black: string;
	themePrimary: string;
	themeSecondary: string;
	themeTertiary: string;
	accent: string;
}

const ThemeItem: React.FC<IThemeItemProps> = (props) => {

	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette);

	return (
		<div className={classes.root}>
			<div className={classes.colorIcon}>
				<svg
					role="presentation"
					viewBox="0 0 84 46"
					aria-hidden="true">
					<rect
						height="100%"
						width="100%"
						fill={props.white} />
					<rect
						y="0%"
						height="50%"
						width="50%"
						stroke="none"
						fill={props.themePrimary} />
					<rect
						x="50%"
						y="0%"
						height="50%"
						width="16.667%"
						stroke="none"
						fill={props.themeSecondary} />
					<rect
						x="66.667%"
						y="0%"
						height="50%"
						width="16.667%"
						stroke="none"
						fill={props.themeTertiary} />
					<rect
						x="83.334%"
						y="0%"
						height="50%"
						width="16.667%"
						stroke="none"
						fill={props.accent} />
					<text
						x="10%"
						y="84%"
						role="presentation"
						fill={props.black}>
						Abc
						</text>
				</svg>
			</div>
			<div className={classes.content}>
				<span>{props.name}</span>
			</div>
		</div>
	);
}

export default ThemeItem;