import { Stack, Text, HoverCard, IExpandingCardProps, IconButton, Pivot, PivotItem, PrimaryButton, IPalette } from "@fluentui/react";
import React, { useContext, useRef } from "react";

import { DisplayContext, ThemeContext } from "../../../App";
import IIconDetails, {IconCategory, IconType} from "../../../helpers/iconDetails";
import { getClassNames } from "./iconBox.classNames";
import { LogException } from "../../../helpers/telemetryService";
import IconVariation from "./iconVariation/iconVariation";
import { saveAs } from "file-saver";

const isFileSaverSupported = !!new Blob();

const IconBox: React.FC<{ iconDetails: IIconDetails, query: string }> = ({ iconDetails, query }) => {
	const dc = useContext(DisplayContext);
	const { palette } = useContext(ThemeContext);
	const classes = getClassNames(palette, dc.iconSize, dc.showBox, dc.showName, dc.iconSlot.theme, dc.iconSlot.customColor, dc.iconHoverSlot.theme, dc.iconHoverSlot.customColor, dc.backgroundSlot.theme, dc.backgroundSlot.customColor, dc.backgroundHoverSlot.theme, dc.backgroundHoverSlot.customColor);

	let shouldHighlight = false;
	const qIndex = iconDetails.name.toLowerCase().indexOf(query);
	let beforeText = '';
	let highlightText = '';
	let afterText = '';
	if (query.length > 0 && qIndex > -1) {
		shouldHighlight = true;

		beforeText = iconDetails.name.substring(0, qIndex);
		highlightText = iconDetails.name.substr(qIndex, query.length);
		afterText = iconDetails.name.substring(qIndex + query.length);
	}

	const cardLabelRef = useRef<HTMLSpanElement>(null);
	const cardIconRef = useRef<HTMLDivElement>(null);

	const copyCardText = (text: string) => {
		if(typeof cardLabelRef.current !== "undefined") {
			const textArea = document.createElement("textarea");
			textArea.style.position = 'fixed';
			textArea.style.top = '-1000';
			textArea.style.left = '-1000';
			textArea.style.width = '2em';
			textArea.style.height = '2em';
			textArea.style.padding = '0';
			textArea.style.border = 'none';
			textArea.style.outline = 'none';
			textArea.style.boxShadow = 'none';
			textArea.style.background = 'transparent';
			textArea.value = text;
			cardLabelRef.current?.appendChild(textArea);
			textArea.select();
			try {
				document.execCommand('copy');
			} catch (err) {
				LogException(err);
				if (window.console && window.console.log) {
					console.log('Unable to copy Icon name to the clipboard!');
				}
			}
			cardLabelRef.current?.removeChild(textArea);
		}
	};

	const exportToSVG = (iconName:string) => {
		const iconColor = dc.iconSlot.theme === "custom" ? dc.iconSlot.customColor : palette[dc.iconSlot.theme as keyof IPalette];

		if(typeof cardIconRef.current !== "undefined") {
			const svgs = cardIconRef.current?.getElementsByTagName("svg");
			if(svgs && svgs.length >= 1) {
				let svgElem = svgs[0].cloneNode(true);
				(svgElem as HTMLElement).removeAttribute('class');
				const paths = (svgElem as HTMLElement).getElementsByTagName("path");
				for(let p = 0; p<paths.length; p++) {
					paths[p].setAttribute("fill", iconColor);
				}
				const tempParent = document.createElement("div")
				tempParent.appendChild(svgElem);
				const blob = new Blob([tempParent.innerHTML], {type: 'image/svg+xml'});
				saveAs(blob, `${iconName}.svg`);
			}
		}
	}

	const expandingCardProps: IExpandingCardProps = {
		onRenderCompactCard: (item: IIconDetails): JSX.Element => {
			return (
				<Stack className={classes.compactCard} verticalAlign="center" horizontal>
					<Stack.Item>
						<Stack className={classes.cardIconBox} horizontalAlign="center" verticalAlign="center">
							<Stack.Item className={classes.cardIcon}>
								<div ref={cardIconRef}>
									<item.component />
								</div>
							</Stack.Item>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<span ref={cardLabelRef} className={classes.cardLabel}>{item.name}</span>
					</Stack.Item>
					<Stack.Item>
						<IconButton className={classes.copyButton} iconProps={{ iconName: 'Copy' }} title="Copy name" onClick={() => copyCardText(item.name)} />
					</Stack.Item>
				</Stack>
			);
		},
		onRenderExpandedCard: (item: IIconDetails): JSX.Element => {
			return (
				<Pivot className={classes.expandedCard}>
					<PivotItem headerText="Details">
						<Stack className={classes.cardTabContent}>
							<Stack.Item>
								<Stack horizontal>
									<Stack.Item className={classes.cardValueLabel}>
										<span>Type: </span>
									</Stack.Item>
									<Stack.Item className={classes.cardValue}>
										<span>{item.type ? IconType[item.type] : 'None'}</span>
									</Stack.Item>
								</Stack>
							</Stack.Item>
							<Stack.Item>
								<Stack horizontal>
									<Stack.Item className={classes.cardValueLabel}>
										<span>Category: </span>
									</Stack.Item>
									<Stack.Item className={classes.cardValue}>
										<span>{item.category ? IconCategory[item.category] : 'None'}</span>
									</Stack.Item>
								</Stack>
							</Stack.Item>
							<Stack.Item>
								<Stack horizontal>
									<Stack.Item className={classes.cardValueLabel}>
										<span>Unicode: </span>
									</Stack.Item>
									<Stack.Item className={classes.cardValue}>
										<span>{item.unicode ? item.unicode : '?'}</span>
									</Stack.Item>
									<Stack.Item>
										<IconButton disabled={!item.unicode} className={classes.copyButton} iconProps={{ iconName: 'Copy' }} title="Copy unicode value" onClick={() => copyCardText(item.unicode!)} />
									</Stack.Item>
								</Stack>
							</Stack.Item>
							<Stack.Item>
								<Stack horizontal>
									<Stack.Item className={classes.cardValueLabel}>
										<span>Tags: </span>
									</Stack.Item>
									<Stack.Item className={classes.cardValue}>
										<span>{typeof item.tags !== undefined ? item.tags?.filter((tag:string) => {return tag !== IconCategory[item.category!].toLowerCase() && tag !== item.unicode?.toLowerCase();}).join(', ') : 'None'}</span>
									</Stack.Item>
								</Stack>
							</Stack.Item>
						</Stack>
					</PivotItem>

					<PivotItem headerText="Variations">
						<Stack className={classes.cardTabContent}>
							<Stack.Item className={classes.cardValueLabel}>
								<span>Neutrals</span>
							</Stack.Item>
							<Stack.Item>
								<Stack horizontal className={classes.iconVariations} wrap>
									<IconVariation paletteKey="neutralPrimary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralPrimaryAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralSecondary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralSecondaryAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralTertiary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralTertiaryAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralQuaternary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralQuaternaryAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralLighter">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralLighterAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="black">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blackTranslucent40">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralPrimary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralPrimaryAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralSecondary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralSecondaryAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralTertiary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralTertiaryAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralQuaternary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralQuaternaryAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralLighter" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="neutralLighterAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="white" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="whiteTranslucent40" darkMode>
										<item.component/>
									</IconVariation>
								</Stack>
							</Stack.Item>

							<Stack.Item className={classes.cardValueLabel}>
								<span>Theme Colors</span>
							</Stack.Item>
							<Stack.Item>
								<Stack horizontal className={classes.iconVariations} wrap>
									<IconVariation paletteKey="themeDarker">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeDarkAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themePrimary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeSecondary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeTertiary">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeLighter">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeLighterAlt">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="accent">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeDarker" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeDarkAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themePrimary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeSecondary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeTertiary" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeLighter" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="themeLighterAlt" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="accent" darkMode>
										<item.component/>
									</IconVariation>
								</Stack>
							</Stack.Item>

							<Stack.Item className={classes.cardValueLabel}>
								<span>Shared</span>
							</Stack.Item>
							<Stack.Item>
								<Stack horizontal className={classes.iconVariations} wrap>
									<IconVariation paletteKey="yellowLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="yellow">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="yellowDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="orangeLighter">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="orangeLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="orange">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="red">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="redDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="magentaLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="magenta">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="magentaDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="purpleLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="purple">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="purpleDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blueLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blue">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blueMid">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blueDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="tealLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="teal">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="tealDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="greenLight">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="green">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="greenDark">
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="yellowLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="yellow" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="yellowDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="orangeLighter" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="orangeLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="orange" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="red" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="redDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="magentaLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="magenta" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="magentaDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="purpleLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="purple" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="purpleDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blueLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blue" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blueMid" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="blueDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="tealLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="teal" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="tealDark" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="greenLight" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="green" darkMode>
										<item.component/>
									</IconVariation>
									<IconVariation paletteKey="greenDark" darkMode>
										<item.component/>
									</IconVariation>
								</Stack>
							</Stack.Item>
						</Stack>
					</PivotItem>

					<PivotItem headerText="Export">
						<Stack className={classes.cardTabContent} horizontalAlign="center">
							<Stack.Item className={classes.instructions}>
								<span>Save the icon file as a Scalable Vector Graphic (SVG) file. The icon will use the configured primary color and have a transparent background. You can change the color using the Display Options &gt; Advanced Color Options.</span>
							</Stack.Item>
							<Stack.Item>
								<PrimaryButton text="Save as SVG" disabled={!isFileSaverSupported} onClick={() => exportToSVG(item.name)} />
							</Stack.Item>
						</Stack>
					</PivotItem>
				</Pivot>
			)
		},
		renderData: iconDetails,
		compactCardHeight: 108,
	};

	return (
		<HoverCard
			instantOpenOnClick={true}
			expandingCardProps={expandingCardProps}
			cardOpenDelay={250}
			expandedCardOpenDelay={750}>

			<Stack horizontalAlign="center" className={classes.root} verticalAlign="center">
				<Stack.Item className={classes.icon}>
					<iconDetails.component />
				</Stack.Item>
				<Stack.Item className={classes.labelBox + (shouldHighlight ? ' ' + classes.highlightBox : '')}>
					{shouldHighlight &&
						<>
							<Text className={classes.label}>{beforeText}</Text>
							<Text className={classes.label + ' ' + classes.highlight}>{highlightText}</Text>
							<Text className={classes.label}>{afterText}</Text>
						</>
					}
					{!shouldHighlight &&
						<Text className={classes.label + ' ' + classes.noHighlight}>{iconDetails.name}</Text>
					}
				</Stack.Item>
			</Stack>

		</HoverCard>
	)
}

export default IconBox;