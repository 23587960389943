import {mergeStyleSets, memoizeFunction, IPalette} from "@fluentui/react"

export interface IFooterClassNames {
	root: string;
	agreement: string;
	credit: string;
	profileImage: string;
}

export const getClassNames = memoizeFunction((palette: IPalette): IFooterClassNames => {
	return mergeStyleSets({
		root: {
			backgroundColor: palette.neutralDark,
			height: '36px',
			padding: '0 6px 0 12px',
		},
		agreement: {
			fontSize: '10px',
			color: palette.neutralLight,
			selectors: {
				'& a': {
					color: palette.themeLighter,
				},
				'& a:hover': {
					color: palette.themeLight,
				},
			}
		},
		credit: {
			fontSize: '12px',
			paddingRight: '6px',
			color: palette.white,
		},
		profileImage: {
			borderRadius: '50%',
			selectors: {
				'& img': {
					width: '28px',
					height: '28px',
				}
			},
		},
	});
});
