import { mergeStyleSets, memoizeFunction, IPalette } from "@fluentui/react"

export interface IThemeItemClassNames {
	root: string;
	colorIcon: string;
	content: string;
}

export const getClassNames = memoizeFunction((palette: IPalette): IThemeItemClassNames => {
	return mergeStyleSets({
		root: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		colorIcon: {
			height: '48px',
			width: '86px',
			border: '1px solid',
			borderColor: palette.neutralLight,
			boxSizing: 'border-box',
			flexShrink: '0',
			selectors: {
				'& svg': {
					width: '100%',
					height: '100%',
				},
			}
		},
		content: {
			minWidth: '30px',
			selectors: {
				'& span': {
					padding: '0 8px',
				},
			}
		},
	});
});
