import React from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {ai} from '../../helpers/telemetryService';

//Code adapted from https://github.com/Azure-Samples/application-insights-react-demo/blob/master/src/telemetry-provider.jsx

export interface ITelemetryProviderProps {
	instrumentationKey: string;
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends React.Component<ITelemetryProviderProps> {
    state = {
        initialized: false
    };

    componentDidMount() {
        const {initialized} = this.state;
        const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey)) {
            ai.initialize(AppInsightsInstrumentationKey);
            this.setState({initialized: true});
        }
    }

    render() {
        const {children} = this.props;
        return (
            <>
                {children}
            </>
        );
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);