import { mergeStyleSets, memoizeFunction, IPalette } from "@fluentui/react";
import { IconSize } from "../../../App";

export interface IIconBoxClassNames {
	root: string;
	icon: string;
	labelBox: string;
	label: string;
	highlightBox: string;
	highlight: string;
	noHighlight: string;
	compactCard: string;
	cardIconBox: string;
	cardIcon: string;
	cardLabel: string;
	copyButton: string;
	expandedCard: string;
	cardTabContent: string;
	cardValueLabel: string;
	cardValue: string;
	iconVariations: string;
	instructions: string;
}

export const getClassNames = memoizeFunction((palette: IPalette, size: IconSize, showBox: boolean, showName: boolean, iconThemeSlot: string, iconCustomColor: string, iconHoverThemeSlot: string, iconHoverCustomColor: string, backgroundThemeSlot: string, backgroundCustomColor: string, backgroundHoverThemeSlot: string, backgroundHoverCustomColor: string): IIconBoxClassNames => {
	const small = size === IconSize.small;
	const medium = size === IconSize.medium;
	const large = size === IconSize.large;

	const iconColor = iconThemeSlot === "custom" ? iconCustomColor : palette[iconThemeSlot as keyof IPalette];
	const iconHoverColor = iconHoverThemeSlot === "custom" ? iconHoverCustomColor : palette[iconHoverThemeSlot as keyof IPalette];
	const backgroundColor = backgroundThemeSlot === "custom" ? backgroundCustomColor : palette[backgroundThemeSlot as keyof IPalette];
	const backgroundHoverColor = backgroundHoverThemeSlot === "custom" ? backgroundHoverCustomColor : palette[backgroundHoverThemeSlot as keyof IPalette];

	return mergeStyleSets({
		root: ['iconBox-root',
			{
				borderRadius: '2px',
				float: 'left',
				width: `${size}px`,
				height: `${size}px`,
				overflow: 'hidden',
				cursor: 'pointer',
				margin: '6px',
				selectors: {
					'&:hover': {
						//ensures overlapping icons (extend beyond box) are visible
						overflow: 'visible',
						zIndex: 2,
					},
				},
			},
			showBox && {
				boxShadow: `0 2px 4px -0.75px ${palette.neutralLight}`,
				backgroundColor: backgroundColor,
				selectors: {
					'&:hover': {
						backgroundColor: backgroundHoverColor,
					}
				},
			},
		],
		icon: [
			{
				color: iconColor,
				selectors: {
					'.iconBox-root:hover &': {
						color: iconHoverColor,
					},
					'.iconBox-root:hover & svg': {
						//ensures overlapping icons (extend beyond box) are visible
						backgroundColor: palette.white,
					},
					'& span': {
						width: '100%', //centers icons wider than fontSize
					},
				},
			},
			showBox && {
				selectors: {
					'.iconBox-root:hover & svg': {
						//ensures overlapping icons (extend beyond box) are visible
						backgroundColor: backgroundHoverColor,
					},
				},
			},
			small && {
				fontSize: '28px',
				lineHeight: '28px',
			},
			medium && {
				fontSize: '36px',
				lineHeight: '36px',
			},
			large && {
				fontSize: '48px',
				lineHeight: '48px',
			},
		],
		labelBox: [
			{
				display: 'flex',
				justifyContent: 'center',
				paddingTop: '4px',
			},
			!showName && {
				display: 'none',
			},
		],
		label: [
			{
				color: palette.neutralSecondary,
				textAlign: 'center',
			},
			small && {
				fontSize: '11px',
				height: '30px',
			},
			medium && {
				fontSize: '14px',
				height: '38px',
			},
			large && {
				fontSize: '16px',
				height: '42px',
			},
		],
		highlightBox: {
			selectors: {
				'.iconBox-root:hover &': {
					backgroundColor: backgroundHoverColor,
					paddingRight: '8px',
					paddingLeft: '8px',
				},
			},
		},
		highlight: {
			color:palette.neutralPrimary,
			fontWeight: '500',
		},
		noHighlight: {
			wordBreak: 'break-all',
		},
		compactCard: {

		},
		cardIconBox: ['iconBox-cardIconBox',
			{
				width: '96px',
				height: '96px',
				overflow: 'hidden',
				margin: '6px',
			},
			showBox && {
				backgroundColor: backgroundColor,
				selectors: {
					'&:hover': {
						backgroundColor: backgroundHoverColor,
					}
				},
			},
		],
		cardIcon: [
			{
				color: iconColor,
				fontSize: '72px',
				lineHeight: '50px',
				selectors: {
					'.iconBox-cardIconBox:hover &': {
						color: iconHoverColor,
					},
					'.iconBox-cardIconBox:hover & svg': {
						//ensures overlapping icons (extend beyond box) are visible
						backgroundColor: palette.white,
					},
					'& span': {
						width: '100%', //centers icons wider than fontSize
					},
				},
			},
			showBox && {
				selectors: {
					'.iconBox-cardIconBox:hover & svg': {
						//ensures overlapping icons (extend beyond box) are visible
						backgroundColor: backgroundHoverColor,
					},
				},
			},
		],
		cardLabel: {
			color: palette.neutralPrimary,
			fontSize: '18px',
			fontWeight: 'bolder',
		},
		copyButton: {
			marginLeft: '6px',
			borderRadius: '50%',
			width: '22px',
			height: '22px',
			selectors: {
				'& i': {
					fontSize: '12px',
					color: palette.themeDarker,
				},
				'&:hover i': {
					color: palette.themePrimary,
				},
			},
		},
		expandedCard: {
			margin: '0 6px',
		},
		cardTabContent: {
			margin: '12px',
			color: palette.neutralSecondary,
			overflowY: 'auto',
			height: '316px',
		},
		cardValueLabel: {
			fontWeight: '600',
			marginRight: '6px',
		},
		cardValue: {
			minHeight: '22px',
		},
		iconVariations: {
			margin: '2px 4px 10px 4px',
		},
		instructions: {
			marginBottom: '12px',
		},
	});
});
