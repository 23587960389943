import "./App.css";

import { getTheme, Stack, IPalette } from "@fluentui/react";
import React, { useState } from "react";

import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Search from "./components/search/search";
import { setTheme } from "./helpers/themes";

//Go ahead and set the default theme
const initialTheme = "Blue";
setTheme(false, initialTheme);

//Setup a context so that sub components can access theme info and change as necessary
export const ThemeContext = React.createContext({
  theme: initialTheme,
  palette: getTheme().palette,
  changeTheme: (name: string) => { },
  tryTheme: (name: string) => { },
});

export enum IconSize {
  small = 88,
  medium = 114,
  large = 150
}

export const DisplayContext = React.createContext({
  iconSize: IconSize.medium,
  showBox: true,
  showName: true,
  iconSlot: {theme:'',customColor:''},
  iconHoverSlot: {theme:'',customColor:''},
  backgroundSlot: {theme:'',customColor:''},
  backgroundHoverSlot: {theme:'',customColor:''},
  changeSize: (iconSize:IconSize) => {},
  changeShowBox: (show:boolean) => {},
  changeShowName: (show:boolean) => {},
  changeSlot: (slot:string, theme: string, customColor: string) => {}
});

function App() {
  //Store theme info as state to pass through context and apply everywhere
  const [themeName, setThemeName] = useState(initialTheme);
  const [palette, setPallette] = useState(getTheme().palette);
  const [iconSize, setIconSize] = useState(IconSize.medium);
  const [showBox, setShowBox] = useState(true);
  const [showName, setShowName] = useState(false);
  const [iconSlot, setIconSlot] = useState({
    theme: 'neutralPrimary',
    customColor: palette.neutralPrimary,
  });
  const [iconHoverSlot, setIconHoverSlot] = useState({
    theme: 'themePrimary',
    customColor: palette.themePrimary,
  });
  const [backgroundSlot, setBackgroundSlot] = useState({
    theme: 'white',
    customColor: palette.white,
  });
  const [backgroundHoverSlot, setBackgroundHoverSlot] = useState({
    theme: 'white',
    customColor: palette.white,
  });

  const updateTheme = (name: string, save: boolean) => {
    setTheme(false, name);
    if(save) {
      setThemeName(name);
    }
    const newPalette = getTheme().palette;
    setPallette(newPalette);

    //Update slot custom colors so that they start with the slot color
    if(iconSlot.theme !== 'custom') {
      setIconSlot({
        theme: iconSlot.theme,
        customColor: newPalette[iconSlot.theme as keyof IPalette],
      })
    }
    if(iconHoverSlot.theme !== 'custom') {
      setIconHoverSlot({
        theme: iconHoverSlot.theme,
        customColor: newPalette[iconHoverSlot.theme as keyof IPalette],
      })
    }
    if(backgroundSlot.theme !== 'custom') {
      setBackgroundSlot({
        theme: backgroundSlot.theme,
        customColor: newPalette[backgroundSlot.theme as keyof IPalette],
      })
    }
    if(backgroundHoverSlot.theme !== 'custom') {
      setBackgroundHoverSlot({
        theme: backgroundHoverSlot.theme,
        customColor: newPalette[backgroundHoverSlot.theme as keyof IPalette],
      })
    }
  };

  return (
    <ThemeContext.Provider value={{
      theme: themeName,
      palette: palette,
      changeTheme: (name: string) => updateTheme(name, true),
      tryTheme: (name: string) => updateTheme(name, false),
    }}>
      <DisplayContext.Provider value={{
        iconSize: iconSize,
        showBox: showBox,
        showName: showName,
        iconSlot: iconSlot,
        iconHoverSlot: iconHoverSlot,
        backgroundSlot: backgroundSlot,
        backgroundHoverSlot: backgroundHoverSlot,
        changeSize: (iconSize:IconSize) => {
          setIconSize(iconSize);
        },
        changeShowBox: (show:boolean) => {
          setShowBox(show);
        },
        changeShowName: (show:boolean) => {
          setShowName(show);
        },
        changeSlot: (slot:string, theme:string, customColor: string) => {
          const slotValue = {theme,customColor}
          switch(slot) {
            case "icon":
              setIconSlot(slotValue);
              break;
            case "iconHover":
              setIconHoverSlot(slotValue);
              break;
            case "background":
              setBackgroundSlot(slotValue);
              break;
            case "backgroundHover":
              setBackgroundHoverSlot(slotValue);
              break;
            default:
              break;
          }
        }
      }}>
        <Stack verticalFill styles={{ root: { backgroundColor: palette.white } }}>
          <Stack.Item>
            <Header />
          </Stack.Item>
          <Stack.Item grow>
            <Search />
          </Stack.Item>
          <Stack.Item>
            <Footer />
          </Stack.Item>
        </Stack>
      </DisplayContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
