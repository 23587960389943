import React, {useContext} from 'react';
import { Stack, IconButton, DefaultButton } from '@fluentui/react';
import {getClassNames} from './pager.classNames';
import {ThemeContext} from '../../../App';

declare interface IPagerProps {
	pageCount: number;
	currentPage: number;
	setPage: (page:number) => void;
}

const Pager: React.FC<IPagerProps> = ({pageCount, currentPage, setPage}) => {
	const {palette} = useContext(ThemeContext);
	const classes = getClassNames(palette);

	const prevPages = Array<number>();
	for(let p = 1; p < currentPage; p++) {
		prevPages.push(p);
	}

	const nextPages = Array<number>();
	for(let p = currentPage+1; p <= pageCount; p++) {
		nextPages.push(p);
	}

	return (
		<Stack horizontal horizontalAlign="center" verticalAlign="center" className={classes.root}>
			<IconButton
				className={classes.navButton}
				title="Previous"
				iconProps={{iconName:'ChevronLeftSmall'}}
				disabled={currentPage === 1}
				onClick={() => setPage(currentPage-1)}/>
			{prevPages.map(page => {
				return <DefaultButton className={classes.pageLink} key={`page-${page}`} onClick={() => setPage(page)}>{page}</DefaultButton>
			})}
			<DefaultButton disabled className={classes.pageLink}>{currentPage}</DefaultButton>
			{nextPages.map(page => {
				return <DefaultButton className={classes.pageLink} key={`page-${page}`} onClick={() => setPage(page)}>{page}</DefaultButton>
			})}
			<IconButton
				className={classes.navButton + ' ' + classes.navButtonRight}
				title="Next"
				iconProps={{iconName:'ChevronRightSmall'}}
				disabled={currentPage >= pageCount}
				onClick={() => setPage(currentPage+1)}/>
		</Stack>
	);
}

export default Pager;