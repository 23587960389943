import { IPalette, memoizeFunction, mergeStyleSets } from "@fluentui/react";

export interface IHeaderClassNames {
	root: string;
	icon: string;
	label: string;
	menu: string;
}

export const getClassNames = memoizeFunction((palette: IPalette): IHeaderClassNames => {
	return mergeStyleSets({
		root: {
			backgroundColor: palette.themeDark,
			color: palette.white,
			height: '50px',
		},
		icon: {
			fontSize: "20px",
			fontWeight: '400',
			marginTop: '6px',
			marginLeft: '12px',
			marginRight: '8px',
		},
		label: {
			fontSize: '22px',
			fontWeight: '400',
		},
		menu: {
			selectors: {
				'& .ms-CommandBar': {
					backgroundColor: 'transparent',
					paddingRight: '12px',
				},
				'& .ms-Button--commandBar': {
					backgroundColor: 'transparent',
					borderRadius: '50%',
					width: '44px',
				},
				'& .ms-Button-icon': {
					color: palette.white,
				},
			},
		},
	});
});
