import { mergeStyleSets, memoizeFunction, IPalette } from "@fluentui/react";

export interface IIconVariationClassNames {
	root: string;
}

export const getClassNames = memoizeFunction((palette: IPalette, paletteKey: string, darkMode: boolean): IIconVariationClassNames => {
	return mergeStyleSets({
		root: [
			{
				color: palette[paletteKey as keyof IPalette],
				padding: '2px',
				width: '28px',
				height: '28px',
				overflow: 'hidden',
				fontSize: '18px',
				lineHeight: '18px',
				textAlign: 'center',
				boxSizing: 'border-box',
			},
			!darkMode && {
				backgroundColor: palette.white,
			},
			darkMode && {
				backgroundColor: palette.black,
			},
		],
	})
});