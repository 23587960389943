export enum IconType {
	Solid,
	Wireframe,
	Colorfill
};

export enum IconCategory {
	Animals,
	Arrows,
	Charts,
	Communication,
	Controls,
	Development,
	Devices,
	Documents,
	Emoji,
	Events,
	Folders,
	Food,
	Formatting,
	Games,
	Interface,
	Health,
	Layout,
	Logos,
	Maps,
	Math,
	Media,
	Photography,
	Security,
	Shapes,
	Stars,
	Stuff,
	Tables,
	Tags,
	Tasks,
	User,
	Weather,
	Vehicles
};

export const categoryTextOverride = (option:string) => {
	switch(option) {
		case "Events":
			return "Events & Time";
		case "Food":
			return "Food & Beverage";
		case "Games":
			return "Games & Sports";
		case "Interface":
			return "General Interface";
		case "Maps":
			return "Maps & Locations";
		case "Math":
			return "Math & Symbols";
		case "Media":
			return "Media Controls";
		case "Photography":
			return "Photography & Pictures";
		case "Stars":
			return "Stars & Badges";
		case "Tables":
			return "Tables & Data";
		case "Tags":
			return "Tags & Tests";
		case "Tasks":
			return "Tasks & Notes";
		default:
			return option;
	}
}

export interface IPartialIconDetails {
	name?: string; //when specified it acts as an override (for icons that start with a number)
	type?: IconType;
	unicode?: string;
	category?: IconCategory;
	tags?: Array<string>;
};

export default interface IIconDetails extends IPartialIconDetails {
	name: string;
	component: React.FC;
}

/**
 * Combines icon details pulled automatically with manual details
 * @param icons name and component results pulled from fluentui/icons module
 */
export const combineIconDetails = (icons: Array<IIconDetails>): Array<IIconDetails> => {
	const results = new Array<IIconDetails>();
	const manualDetails = getManualDetails();

	icons.forEach(icon => {
		if (manualDetails.has(icon.name)) {
			const combined = {
				...icon,
				...manualDetails.get(icon.name)
			};
			//Add category to tags to help with searching
			if (typeof combined.category !== "undefined") {
				const categoryString = IconCategory[combined.category].toLowerCase();
				if (typeof combined.tags !== "undefined") {
					combined.tags?.push(categoryString);
				} else {
					combined.tags = [categoryString];
				}
			}
			//Add unicode to tags to enable searching by unicode
			if (typeof combined.unicode !== "undefined") {
				const unicodeString = combined.unicode.toLowerCase();
				if (typeof combined.tags !== "undefined") {
					combined.tags?.push(unicodeString);
				} else {
					combined.tags = [unicodeString];
				}
			}
			results.push(combined);
		} else {
			results.push(icon);
		}
	});

	return results.sort((a:IIconDetails,b:IIconDetails) => a.name.localeCompare(b.name));
};

const getManualDetails = (): Map<string, IPartialIconDetails> => {
	const mDetails = new Map<string, IPartialIconDetails>();

	mDetails.set("TwelvePointStar", {
		name: '12PointStar',
		type: IconType.Wireframe,
		unicode: 'F505',
		category: IconCategory.Stars,
		tags: ['twelve','burst'],
	});
	mDetails.set("SixPointStar", {
		name: '6PointStar',
		type: IconType.Wireframe,
		unicode: 'F504',
		category: IconCategory.Stars,
		tags: ['six','burst'],
	});
	mDetails.set("AADLogo", {
		type: IconType.Wireframe,
		unicode: 'ED68',
		category: IconCategory.Logos,
		tags: ['azure','active','directory','microsoft','brand','m365','o365'],
	});
	mDetails.set("Accept", {
		type: IconType.Wireframe,
		unicode: 'E8FB',
		category: IconCategory.Interface,
		tags: ['checkmark','approved','complete','done'],
	});
	mDetails.set("AcceptMedium", {
		type: IconType.Wireframe,
		unicode: 'F78C',
		category: IconCategory.Interface,
		tags: ['checkmark','approved','complete','done'],
	});
	mDetails.set("AccessibiltyChecker", {
		type: IconType.Wireframe,
		unicode: 'F835',
		category: IconCategory.Documents,
		tags: ['page','file','arrow','down','process'],
	});
	mDetails.set("AccessLogo", {
		type: IconType.Wireframe,
		unicode: 'ED69',
		category: IconCategory.Logos,
		tags: ['office','brand','microsoft','database'],
	});
	mDetails.set("AccountActivity", {
		type: IconType.Wireframe,
		unicode: 'EFF4',
		category: IconCategory.Tasks,
		tags: ['pencil','edit','clipboard'],
	});
	mDetails.set("AccountBrowser", {
		type: IconType.Wireframe,
		unicode: 'F652',
		category: IconCategory.User,
		tags: ['person','contact'],
	});
	mDetails.set("AccountManagement", {
		type: IconType.Wireframe,
		unicode: 'F55C',
		category: IconCategory.User,
		tags: ['person','contact','luggage','bag','suitcase','briefcase'],
	});
	mDetails.set("Accounts", {
		type: IconType.Wireframe,
		unicode: 'E910',
		category: IconCategory.Math,
		tags: ['symbol','at sign','mention'],
	});
	mDetails.set("ActionCenter", {
		type: IconType.Wireframe,
		unicode: 'E91C',
		category: IconCategory.Communication,
		tags: ['speech bubble','message','talk'],
	});
	mDetails.set("ActivateOrders", {
		type: IconType.Wireframe,
		unicode: 'EFE0',
		category: IconCategory.Documents,
		tags: ['checkmark','approval','complete'],
	});
	mDetails.set("ActivityFeed", {
		type: IconType.Wireframe,
		unicode: 'F056',
		category: IconCategory.Communication,
		tags: ['speech bubble','talk','chat'],
	});
	mDetails.set("Add", {
		type: IconType.Wireframe,
		unicode: 'E710',
		category: IconCategory.Interface,
		tags: ['plus','new','addition'],
	});
	mDetails.set("AddBookmark", {
		type: IconType.Wireframe,
		unicode: 'F5B7',
		category: IconCategory.Interface,
		tags: ['ribbon','plus','new'],
	});
	mDetails.set("AddConnection", {
		type: IconType.Wireframe,
		unicode: 'F4E1',
		category: undefined,
		tags: [],
	});
	mDetails.set("AddEvent", {
		type: IconType.Wireframe,
		unicode: 'EEB5',
		category: IconCategory.Events,
		tags: ['calendar','plus','new','day'],
	});
	mDetails.set("AddFavorite", {
		type: IconType.Wireframe,
		unicode: 'F0C8',
		category: IconCategory.Stars,
		tags: ['plus'],
	});
	mDetails.set("AddFavoriteFill", {
		type: IconType.Colorfill,
		unicode: 'F0C9',
		category: IconCategory.Stars,
		tags: ['plus'],
	});
	mDetails.set("AddField", {
		type: IconType.Wireframe,
		unicode: 'E4C7',
		category: undefined,
		tags: [],
	});
	mDetails.set("AddFriend", {
		type: IconType.Wireframe,
		unicode: 'E8FA',
		category: IconCategory.User,
		tags: ['person','contact','plus','new'],
	});
	mDetails.set("AddGroup", {
		type: IconType.Wireframe,
		unicode: 'EE3D',
		category: IconCategory.User,
		tags: ['person','contact','plus','new','people'],
	});
	mDetails.set("AddHome", {
		type: IconType.Wireframe,
		unicode: 'F17B',
		category: IconCategory.Maps,
		tags: ['plus','new','house','door','building'],
	});
	mDetails.set("AddIn", {
		type: IconType.Wireframe,
		unicode: 'F775',
		category: IconCategory.Development,
		tags: ['plus','new'],
	});
	mDetails.set("AddLink", {
		type: IconType.Wireframe,
		unicode: 'E35E',
		category: IconCategory.Formatting,
		tags: ['plus','chain','new'],
	});
	mDetails.set("AddNotes", {
		type: IconType.Wireframe,
		unicode: 'EAE3',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("AddOnlineMeeting", {
		type: IconType.Wireframe,
		unicode: 'ED8E',
		category: IconCategory.Maps,
		tags: ['globe','new','plus'],
	});
	mDetails.set("AddPhone", {
		type: IconType.Wireframe,
		unicode: 'ED96',
		category: IconCategory.Communication,
		tags: ['plus','telephone'],
	});
	mDetails.set("AddReaction", {
		type: IconType.Wireframe,
		unicode: 'F85D',
		category: IconCategory.Emoji,
		tags: ['face','plus','happy','smile'],
	});
	mDetails.set("AddSpaceAfter", {
		type: IconType.Wireframe,
		unicode: 'E3DF',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("AddSpaceBefore", {
		type: IconType.Wireframe,
		unicode: 'E3DE',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("AddTable", {
		type: IconType.Wireframe,
		unicode: 'E4C6',
		category: undefined,
		tags: [],
	});
	mDetails.set("AddTo", {
		type: IconType.Wireframe,
		unicode: 'ECC8',
		category: IconCategory.Interface,
		tags: ['new','plus','circle'],
	});
	mDetails.set("AddToShoppingList", {
		type: IconType.Wireframe,
		unicode: 'EA9A',
		category: IconCategory.Tables,
		tags: ['plus','new'],
	});
	mDetails.set("AddWork", {
		type: IconType.Wireframe,
		unicode: 'F17C',
		category: IconCategory.Stuff,
		tags: ['luggage','baggage','briefcase','suitcase','plus','new'],
	});
	mDetails.set("Admin", {
		type: IconType.Wireframe,
		unicode: 'E7EF',
		category: IconCategory.Security,
		tags: ['administration','shield'],
	});
	mDetails.set("AdminALogo32", {
		type: IconType.Wireframe,
		unicode: 'F4BA',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminALogoFill32", {
		type: IconType.Colorfill,
		unicode: 'F4BB',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminALogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED6A',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminCLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED6B',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminDLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED6C',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminELogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED6D',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminLLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED6E',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminMLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED6F',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminOLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED70',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminPLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED71',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminSLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED72',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("AdminYLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'ED73',
		category: IconCategory.Logos,
		tags: ['administration','microsoft','gear'],
	});
	mDetails.set("Airplane", {
		type: IconType.Wireframe,
		unicode: 'E709',
		category: IconCategory.Vehicles,
		tags: ['flying','jet'],
	});
	mDetails.set("AirplaneSolid", {
		type: IconType.Solid,
		unicode: 'EB4C',
		category: IconCategory.Vehicles,
		tags: ['flying','jet'],
	});
	mDetails.set("AirTickets", {
		type: IconType.Wireframe,
		unicode: 'EF7A',
		category: IconCategory.Stuff,
		tags: ['airplane','flight'],
	});
	mDetails.set("AlarmClock", {
		type: IconType.Wireframe,
		unicode: 'E919',
		category: IconCategory.Stuff,
		tags: ['time','alert'],
	});
	mDetails.set("Album", {
		type: IconType.Wireframe,
		unicode: 'E7AB',
		category: IconCategory.Stuff,
		tags: ['photos','media','pictures','folder'],
	});
	mDetails.set("AlbumRemove", {
		type: IconType.Wireframe,
		unicode: 'EC62',
		category: IconCategory.Stuff,
		tags: ['photos','media','pictures','folder','delete','x','cancel'],
	});
	mDetails.set("AlertSettings", {
		type: IconType.Wireframe,
		unicode: 'F8B6',
		category: IconCategory.Interface,
		tags: ['exclamation mark','warning','gear','configure'],
	});
	mDetails.set("AlertSolid", {
		type: IconType.Solid,
		unicode: 'F331',
		category: IconCategory.Stars,
		tags: ['warning','exclamation mark'],
	});
	mDetails.set("AlignCenter", {
		type: IconType.Wireframe,
		unicode: 'E8E3',
		category: IconCategory.Formatting,
		tags: ['alignment','text','paragraph'],
	});
	mDetails.set("AlignHorizontalCenter", {
		type: IconType.Wireframe,
		unicode: 'F4F4',
		category: IconCategory.Formatting,
		tags: ['alignment','positioning'],
	});
	mDetails.set("AlignHorizontalLeft", {
		type: IconType.Wireframe,
		unicode: 'F4F3',
		category: IconCategory.Formatting,
		tags: ['alignment','positioning'],
	});
	mDetails.set("AlignHorizontalRight", {
		type: IconType.Wireframe,
		unicode: 'F4F5',
		category: IconCategory.Formatting,
		tags: ['alignment','positioning'],
	});
	mDetails.set("AlignJustify", {
		type: IconType.Wireframe,
		unicode: 'F51E',
		category: IconCategory.Formatting,
		tags: ['alignment','text','paragraph'],
	});
	mDetails.set("AlignLeft", {
		type: IconType.Wireframe,
		unicode: 'E8E4',
		category: IconCategory.Formatting,
		tags: ['alignment','text','paragraph'],
	});
	mDetails.set("AlignRight", {
		type: IconType.Wireframe,
		unicode: 'E8E2',
		category: IconCategory.Formatting,
		tags: ['alignment','text','paragraph'],
	});
	mDetails.set("AlignVerticalBottom", {
		type: IconType.Wireframe,
		unicode: 'F4F8',
		category: IconCategory.Formatting,
		tags: ['alignment','positioning'],
	});
	mDetails.set("AlignVerticalCenter", {
		type: IconType.Wireframe,
		unicode: 'F4F7',
		category: IconCategory.Formatting,
		tags: ['alignment','positioning'],
	});
	mDetails.set("AlignVerticalTop", {
		type: IconType.Wireframe,
		unicode: 'F4F6',
		category: IconCategory.Formatting,
		tags: ['alignment','positioning'],
	});
	mDetails.set("AllApps", {
		type: IconType.Wireframe,
		unicode: 'E71D',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("AllAppsMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA40',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("AllCurrency", {
		type: IconType.Wireframe,
		unicode: 'EAE4',
		category: IconCategory.Stuff,
		tags: ['money','coins','dollars','yen','pounds'],
	});
	mDetails.set("AltText", {
		type: IconType.Wireframe,
		unicode: 'E397',
		category: IconCategory.Photography,
		tags: ['image','picture'],
	});
	mDetails.set("AnalyticsLogo", {
		type: IconType.Wireframe,
		unicode: 'F1DE',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','m365','o365'],
	});
	mDetails.set("AnalyticsQuery", {
		type: IconType.Wireframe,
		unicode: 'F1DF',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("AnalyticsReport", {
		type: IconType.Wireframe,
		unicode: 'F1E1',
		category: IconCategory.Charts,
		tags: ['page','document'],
	});
	mDetails.set("AnalyticsView", {
		type: IconType.Wireframe,
		unicode: 'F5F1',
		category: IconCategory.Charts,
		tags: ['dialog'],
	});
	mDetails.set("AnchorLock", {
		type: IconType.Wireframe,
		unicode: 'F511',
		category: IconCategory.Formatting,
		tags: ['locked','secure','hyperlink'],
	});
	mDetails.set("Annotation", {
		type: IconType.Wireframe,
		unicode: 'E924',
		category: IconCategory.Formatting,
		tags: ['scissors'],
	});
	mDetails.set("AppIconDefault", {
		type: IconType.Wireframe,
		unicode: 'ECAA',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("AppIconDefaultAdd", {
		type: IconType.Wireframe,
		unicode: 'EFDA',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("AppIconDefaultList", {
		type: IconType.Wireframe,
		unicode: 'EFDE',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Archive", {
		type: IconType.Wireframe,
		unicode: 'F03F',
		category: IconCategory.Folders,
		tags: ['box','files'],
	});
	mDetails.set("ArchiveUndo", {
		type: IconType.Wireframe,
		unicode: 'E3A1',
		category: IconCategory.Folders,
		tags: ['box','files','arrow'],
	});
	mDetails.set("AreaChart", {
		type: IconType.Wireframe,
		unicode: 'E9D2',
		category: IconCategory.Charts,
		tags: ['lines'],
	});
	mDetails.set("ArrangeBringForward", {
		type: IconType.Wireframe,
		unicode: 'F509',
		category: IconCategory.Layout,
		tags: ['order'],
	});
	mDetails.set("ArrangeBringToFront", {
		type: IconType.Wireframe,
		unicode: 'F506',
		category: IconCategory.Layout,
		tags: ['order'],
	});
	mDetails.set("ArrangeByFrom", {
		type: IconType.Wireframe,
		unicode: 'F678',
		category: IconCategory.Communication,
		tags: ['email','envelope','person','contact'],
	});
	mDetails.set("ArrangeSendBackward", {
		type: IconType.Wireframe,
		unicode: 'F508',
		category: IconCategory.Layout,
		tags: ['order'],
	});
	mDetails.set("ArrangeSendToBack", {
		type: IconType.Wireframe,
		unicode: 'F507',
		category: IconCategory.Layout,
		tags: ['order'],
	});
	mDetails.set("Arrivals", {
		type: IconType.Wireframe,
		unicode: 'EB34',
		category: IconCategory.Vehicles,
		tags: ['airplane','jet'],
	});
	mDetails.set("ArrowDownRight8", {
		type: IconType.Wireframe,
		unicode: 'EED5',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowDownRightMirrored8", {
		type: IconType.Wireframe,
		unicode: 'EEF0',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowTallDownLeft", {
		type: IconType.Wireframe,
		unicode: 'F2BF',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowTallDownRight", {
		type: IconType.Wireframe,
		unicode: 'F2C0',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowTallUpLeft", {
		type: IconType.Wireframe,
		unicode: 'F2BD',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowTallUpRight", {
		type: IconType.Wireframe,
		unicode: 'F2BE',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowUpRight", {
		type: IconType.Wireframe,
		unicode: 'F069',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowUpRight8", {
		type: IconType.Wireframe,
		unicode: 'EED4',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ArrowUpRightMirrored8", {
		type: IconType.Wireframe,
		unicode: 'EEEF',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Articles", {
		type: IconType.Wireframe,
		unicode: 'EAC1',
		category: IconCategory.Communication,
		tags: ['newspaper'],
	});
	mDetails.set("Ascending", {
		type: IconType.Wireframe,
		unicode: 'EDC0',
		category: IconCategory.Tables,
		tags: ['sort','arrow','down'],
	});
	mDetails.set("AspectRatio", {
		type: IconType.Wireframe,
		unicode: 'E799',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("AssessmentGroup", {
		type: IconType.Wireframe,
		unicode: 'F31A',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("AssessmentGroupTemplate", {
		type: IconType.Wireframe,
		unicode: 'F2B1',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("AssetLibrary", {
		type: IconType.Wireframe,
		unicode: 'EEB6',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("Assign", {
		type: IconType.Wireframe,
		unicode: 'E9D3',
		category: IconCategory.Tasks,
		tags: ['clipboard','arrow','right'],
	});
	mDetails.set("AssignPolicy", {
		type: IconType.Wireframe,
		unicode: 'E461',
		category: IconCategory.Communication,
		tags: ['filing','envelope','email'],
	});
	mDetails.set("Asterisk", {
		type: IconType.Wireframe,
		unicode: 'EA38',
		category: IconCategory.Math,
		tags: ['symbol','star','footnote'],
	});
	mDetails.set("AsteriskSolid", {
		type: IconType.Solid,
		unicode: 'F34D',
		category: IconCategory.Math,
		tags: ['symbol','star','footnote'],
	});
	mDetails.set("ATPLogo", {
		type: IconType.Wireframe,
		unicode: 'EF85',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','advanced threat protection','azure'],
	});
	mDetails.set("Attach", {
		type: IconType.Wireframe,
		unicode: 'E723',
		category: IconCategory.Communication,
		tags: ['email','attachment','paperclip'],
	});
	mDetails.set("AustralianRules", {
		type: IconType.Wireframe,
		unicode: 'EE70',
		category: IconCategory.Games,
		tags: ['football'],
	});
	mDetails.set("AuthenticatorApp", {
		type: IconType.Wireframe,
		unicode: 'F6B1',
		category: IconCategory.Logos,
		tags: ['azure','shield','locked','secure','person','contact','user'],
	});
	mDetails.set("AutoDeploySettings", {
		type: IconType.Wireframe,
		unicode: 'F3FA',
		category: IconCategory.Devices,
		tags: ['computer','monitor','screen','configuration','gear'],
	});
	mDetails.set("AutoEnhanceOff", {
		type: IconType.Wireframe,
		unicode: 'E78E',
		category: IconCategory.Formatting,
		tags: ['wand','checkmark','magic','sparkler'],
	});
	mDetails.set("AutoEnhanceOn", {
		type: IconType.Wireframe,
		unicode: 'E78D',
		category: IconCategory.Formatting,
		tags: ['wand','magic','sparkler'],
	});
	mDetails.set("AutoFillTemplate", {
		type: IconType.Wireframe,
		unicode: 'F313',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("AutoFitContents", {
		type: IconType.Wireframe,
		unicode: 'E3E8',
		category: IconCategory.Layout,
		tags: ['stretch'],
	});
	mDetails.set("AutoFitWindow", {
		type: IconType.Wireframe,
		unicode: 'E3E9',
		category: IconCategory.Layout,
		tags: ['stretch','percentage'],
	});
	mDetails.set("AutoHeight", {
		type: IconType.Wireframe,
		unicode: 'F512',
		category: IconCategory.Layout,
		tags: ['lightning bolt','arrows'],
	});
	mDetails.set("AutomateFlow", {
		type: IconType.Wireframe,
		unicode: 'E3F5',
		category: IconCategory.Development,
		tags: ['lightning bolt'],
	});
	mDetails.set("AutoRacing", {
		type: IconType.Wireframe,
		unicode: 'EB24',
		category: IconCategory.Vehicles,
		tags: ['dashboard','gauge'],
	});
	mDetails.set("AwayStatus", {
		type: IconType.Wireframe,
		unicode: 'EE6A',
		category: IconCategory.Stars,
		tags: ['clock'],
	});
	mDetails.set("AzureAPIManagement", {
		type: IconType.Wireframe,
		unicode: 'F37F',
		category: IconCategory.Logos,
		tags: ['cloud'],
	});
	mDetails.set("AzureDataExplorer", {
		type: IconType.Wireframe,
		unicode: 'E439',
		category: IconCategory.Logos,
		tags: ['paper airplane','fly','kite'],
	});
	mDetails.set("AzureKeyVault", {
		type: IconType.Wireframe,
		unicode: 'F3B4',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("AzureLogo", {
		type: IconType.Wireframe,
		unicode: 'EB6A',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','cloud'],
	});
	mDetails.set("AzureServiceEndpoint", {
		type: IconType.Wireframe,
		unicode: 'F380',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("Back", {
		type: IconType.Wireframe,
		unicode: 'E72B',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("BackgroundColor", {
		type: IconType.Wireframe,
		unicode: 'F42B',
		category: IconCategory.Formatting,
		tags: ['paint bucket'],
	});
	mDetails.set("Backlog", {
		type: IconType.Wireframe,
		unicode: 'F2AC',
		category: IconCategory.Tasks,
		tags: ['planer','trello','sprint','planning','burndown'],
	});
	mDetails.set("BacklogBoard", {
		type: IconType.Wireframe,
		unicode: 'F444',
		category: IconCategory.Tasks,
		tags: ['planer','trello','sprint','planning','burndown'],
	});
	mDetails.set("BacklogList", {
		type: IconType.Wireframe,
		unicode: 'F6BF',
		category: IconCategory.Tasks,
		tags: ['stack'],
	});
	mDetails.set("BackToWindow", {
		type: IconType.Wireframe,
		unicode: 'E73F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Badge", {
		type: IconType.Wireframe,
		unicode: 'EC1B',
		category: IconCategory.Security,
		tags: ['sheriff','police','official'],
	});
	mDetails.set("Balloons", {
		type: IconType.Wireframe,
		unicode: 'ED7E',
		category: IconCategory.Stuff,
		tags: ['party','celebration','helium'],
	});
	mDetails.set("Bank", {
		type: IconType.Wireframe,
		unicode: 'E825',
		category: IconCategory.Maps,
		tags: ['courthouse','finance','building'],
	});
	mDetails.set("BankSolid", {
		type: IconType.Solid,
		unicode: 'F34F',
		category: IconCategory.Maps,
		tags: ['courthouse','finance','building'],
	});
	mDetails.set("BarChart4", {
		type: IconType.Wireframe,
		unicode: 'EAE7',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("BarChartHorizontal", {
		type: IconType.Wireframe,
		unicode: 'E9EB',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("BarChartVertical", {
		type: IconType.Wireframe,
		unicode: 'E9EC',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("BarChartVerticalEdit", {
		type: IconType.Wireframe,
		unicode: 'F89D',
		category: IconCategory.Charts,
		tags: ['pencil'],
	});
	mDetails.set("BarChartVerticalFill", {
		type: IconType.Solid,
		unicode: 'F830',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("BarChartVerticalFilter", {
		type: IconType.Wireframe,
		unicode: 'F77E',
		category: IconCategory.Charts,
		tags: ['funnel'],
	});
	mDetails.set("BarChartVerticalFilterSolid", {
		type: IconType.Solid,
		unicode: 'F77F',
		category: IconCategory.Charts,
		tags: ['funnel'],
	});
	mDetails.set("Baseball", {
		type: IconType.Wireframe,
		unicode: 'EB20',
		category: IconCategory.Games,
		tags: ['sports','athletics'],
	});
	mDetails.set("BeerMug", {
		type: IconType.Wireframe,
		unicode: 'F49E',
		category: IconCategory.Food,
		tags: ['alcohol','drink','beverage','mug','pint','brew'],
	});
	mDetails.set("BIDashboard", {
		type: IconType.Wireframe,
		unicode: 'F543',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("BidiLtr", {
		type: IconType.Wireframe,
		unicode: 'E9AA',
		category: IconCategory.Formatting,
		tags: ['left to right'],
	});
	mDetails.set("BidiRtl", {
		type: IconType.Wireframe,
		unicode: 'E9AB',
		category: IconCategory.Formatting,
		tags: ['right to left'],
	});
	mDetails.set("BingLogo", {
		type: IconType.Wireframe,
		unicode: 'EB6B',
		category: IconCategory.Logos,
		tags: ['brand','search','microsoft'],
	});
	mDetails.set("BirthdayCake", {
		type: IconType.Wireframe,
		unicode: 'EF8D',
		category: IconCategory.Food,
		tags: ['dessert','sweet','candle','celebration'],
	});
	mDetails.set("BlobStorage", {
		type: IconType.Wireframe,
		unicode: 'E436',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("BlockContact", {
		type: IconType.Wireframe,
		unicode: 'E8F8',
		category: IconCategory.User,
		tags: ['banned','person'],
	});
	mDetails.set("Blocked", {
		type: IconType.Wireframe,
		unicode: 'E733',
		category: IconCategory.Stars,
		tags: ['banned','forbidden','unallowed'],
	});
	mDetails.set("Blocked12", {
		type: IconType.Wireframe,
		unicode: 'F62E',
		category: IconCategory.Stars,
		tags: ['remove','delete','collapse'],
	});
	mDetails.set("Blocked2", {
		type: IconType.Wireframe,
		unicode: 'ECE4',
		category: IconCategory.Stars,
		tags: ['remove','delete','collapse'],
	});
	mDetails.set("Blocked2Solid", {
		type: IconType.Solid,
		unicode: 'F737',
		category: IconCategory.Stars,
		tags: ['remove','delete','collapse'],
	});
	mDetails.set("BlockedSite", {
		type: IconType.Wireframe,
		unicode: 'E72F',
		category: IconCategory.Security,
		tags: ['badge','shield','x'],
	});
	mDetails.set("BlockedSiteSolid12", {
		type: IconType.Wireframe,
		unicode: 'F70A',
		category: IconCategory.Security,
		tags: ['badge','shield','x'],
	});
	mDetails.set("BlockedSolid", {
		type: IconType.Solid,
		unicode: 'F531',
		category: IconCategory.Stars,
		tags: ['banned','forbidden','unallowed'],
	});
	mDetails.set("Blog", {
		type: IconType.Wireframe,
		unicode: 'F22B',
		category: IconCategory.Communication,
		tags: ['speech bubble','talk','article','write','chat'],
	});
	mDetails.set("BlowingSnow", {
		type: IconType.Wireframe,
		unicode: 'E9C9',
		category: IconCategory.Weather,
		tags: ['cold','wind','chilly','arctic'],
	});
	mDetails.set("Blur", {
		type: IconType.Wireframe,
		unicode: 'F28E',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("Boards", {
		type: IconType.Wireframe,
		unicode: 'EF68',
		category: IconCategory.Layout,
		tags: ['stack'],
	});
	mDetails.set("Bold", {
		type: IconType.Wireframe,
		unicode: 'E8DD',
		category: IconCategory.Formatting,
		tags: ['weight'],
	});
	mDetails.set("BookAnswers", {
		type: IconType.Wireframe,
		unicode: 'F8A4',
		category: IconCategory.Stuff,
		tags: ['information','guide'],
	});
	mDetails.set("BookingsLogo", {
		type: IconType.Wireframe,
		unicode: 'EDC7',
		category: IconCategory.Logos,
		tags: ['brand','microsoft'],
	});
	mDetails.set("BookmarkReport", {
		type: IconType.Wireframe,
		unicode: 'F76B',
		category: IconCategory.Charts,
		tags: ['tag'],
	});
	mDetails.set("Bookmarks", {
		type: IconType.Wireframe,
		unicode: 'E8A4',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("BookmarksMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA41',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("BorderDash", {
		type: IconType.Wireframe,
		unicode: 'F50A',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("BorderDot", {
		type: IconType.Wireframe,
		unicode: 'F50B',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("BoxAdditionSolid", {
		type: IconType.Solid,
		unicode: 'F2D4',
		category: IconCategory.Math,
		tags: ['plus','new'],
	});
	mDetails.set("BoxCheckmarkSolid", {
		type: IconType.Solid,
		unicode: 'F2D7',
		category: IconCategory.Tasks,
		tags: ['checkbox','approved','completed','done'],
	});
	mDetails.set("BoxMultiplySolid", {
		type: IconType.Solid,
		unicode: 'F2D5',
		category: IconCategory.Math,
		tags: ['x','cancel','clear','delete','remove'],
	});
	mDetails.set("BoxPlaySolid", {
		type: IconType.Solid,
		unicode: 'F2D6',
		category: IconCategory.Media,
		tags: ['control'],
	});
	mDetails.set("BoxSubtractSolid", {
		type: IconType.Solid,
		unicode: 'F2D3',
		category: IconCategory.Math,
		tags: ['minus','remove','collapse'],
	});
	mDetails.set("BranchCommit", {
		type: IconType.Wireframe,
		unicode: 'F293',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchCompare", {
		type: IconType.Wireframe,
		unicode: 'F294',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchFork", {
		type: IconType.Wireframe,
		unicode: 'F173',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchFork2", {
		type: IconType.Wireframe,
		unicode: 'F291',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchLocked", {
		type: IconType.Wireframe,
		unicode: 'F292',
		category: IconCategory.Development,
		tags: ['git','source control','secure','svn'],
	});
	mDetails.set("BranchMerge", {
		type: IconType.Wireframe,
		unicode: 'F295',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchPullRequest", {
		type: IconType.Wireframe,
		unicode: 'F296',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchSearch", {
		type: IconType.Wireframe,
		unicode: 'F297',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("BranchShelveset", {
		type: IconType.Wireframe,
		unicode: 'F298',
		category: IconCategory.Development,
		tags: ['git','source control','svn'],
	});
	mDetails.set("Breadcrumb", {
		type: IconType.Wireframe,
		unicode: 'EF8C',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Breakfast", {
		type: IconType.Wireframe,
		unicode: 'F49C',
		category: IconCategory.Food,
		tags: ['coffee','mug','steam','tea','meal','drink','beverage'],
	});
	mDetails.set("Brightness", {
		type: IconType.Wireframe,
		unicode: 'E706',
		category: IconCategory.Photography,
		tags: ['picture','editing'],
	});
	mDetails.set("Broom", {
		type: IconType.Wireframe,
		unicode: 'EA99',
		category: IconCategory.Stuff,
		tags: ['sweep','nimbus 2000','witch','clean','tool'],
	});
	mDetails.set("BrowserScreenShot", {
		type: IconType.Wireframe,
		unicode: 'EBED',
		category: IconCategory.Photography,
		tags: ['camera'],
	});
	mDetails.set("BrowserTab", {
		type: IconType.Wireframe,
		unicode: 'F5D7',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("BrowserTabScreenshot", {
		type: IconType.Wireframe,
		unicode: 'F5D8',
		category: IconCategory.Photography,
		tags: ['camera'],
	});
	mDetails.set("Brunch", {
		type: IconType.Wireframe,
		unicode: 'F49D',
		category: IconCategory.Food,
		tags: ['meal','drink','beverage'],
	});
	mDetails.set("Brush", {
		type: IconType.Wireframe,
		unicode: 'ECFF',
		category: IconCategory.Stuff,
		tags: ['paint','color','artist','tool'],
	});
	mDetails.set("BucketColor", {
		type: IconType.Wireframe,
		unicode: 'F1B6',
		category: IconCategory.Stuff,
		tags: ['paint','drop','spill','tool'],
	});
	mDetails.set("BucketColorFill", {
		type: IconType.Colorfill,
		unicode: 'F1B7',
		category: IconCategory.Stuff,
		tags: ['paint','drop','spill','tool'],
	});
	mDetails.set("BufferTimeAfter", {
		type: IconType.Wireframe,
		unicode: 'F0D0',
		category: IconCategory.Events,
		tags: ['clock'],
	});
	mDetails.set("BufferTimeBefore", {
		type: IconType.Wireframe,
		unicode: 'F0CF',
		category: IconCategory.Events,
		tags: ['clock'],
	});
	mDetails.set("BufferTimeBoth", {
		type: IconType.Wireframe,
		unicode: 'F0D1',
		category: IconCategory.Events,
		tags: ['clock'],
	});
	mDetails.set("Bug", {
		type: IconType.Wireframe,
		unicode: 'EBE8',
		category: IconCategory.Animals,
		tags: ['insect','beetle','tick'],
	});
	mDetails.set("BugAction", {
		type: IconType.Wireframe,
		unicode: 'E358',
		category: undefined,
		tags: [],
	});
	mDetails.set("BugBlock", {
		type: IconType.Wireframe,
		unicode: 'E400',
		category: IconCategory.Animals,
		tags: ['insect','beetle','tick'],
	});
	mDetails.set("BugSolid", {
		type: IconType.Solid,
		unicode: 'F335',
		category: IconCategory.Animals,
		tags: ['insect','beetle','tick'],
	});
	mDetails.set("BugSync", {
		type: IconType.Wireframe,
		unicode: 'E3FF',
		category: IconCategory.Animals,
		tags: ['insect','beetle','tick'],
	});
	mDetails.set("BugWarning", {
		type: IconType.Wireframe,
		unicode: 'E357',
		category: undefined,
		tags: [],
	});
	mDetails.set("Build", {
		type: IconType.Wireframe,
		unicode: 'F28F',
		category: IconCategory.Development,
		tags: ['arrow','down'],
	});
	mDetails.set("BuildDefinition", {
		type: IconType.Wireframe,
		unicode: 'F6E9',
		category: IconCategory.Development,
		tags: ['crane','construction'],
	});
	mDetails.set("BuildIssue", {
		type: IconType.Wireframe,
		unicode: 'F319',
		category: IconCategory.Development,
		tags: ['crane','construction','exclamation mark'],
	});
	mDetails.set("BuildQueue", {
		type: IconType.Wireframe,
		unicode: 'F24F',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("BuildQueueNew", {
		type: IconType.Wireframe,
		unicode: 'F250',
		category: IconCategory.Development,
		tags: ['add','plus'],
	});
	mDetails.set("BulkUpload", {
		type: IconType.Wireframe,
		unicode: 'F548',
		category: IconCategory.Documents,
		tags: ['pages','arrow'],
	});
	mDetails.set("BulletedList", {
		type: IconType.Wireframe,
		unicode: 'E8FD',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedList2", {
		type: IconType.Wireframe,
		unicode: 'F2C7',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedList2Mirrored", {
		type: IconType.Wireframe,
		unicode: 'F2C8',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedListBullet", {
		type: IconType.Wireframe,
		unicode: 'F793',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedListBulletMirrored", {
		type: IconType.Wireframe,
		unicode: 'F795',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedListMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA42',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedListText", {
		type: IconType.Wireframe,
		unicode: 'F792',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedListTextMirrored", {
		type: IconType.Wireframe,
		unicode: 'F794',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("BulletedTreeList", {
		type: IconType.Wireframe,
		unicode: 'F84C',
		category: IconCategory.Formatting,
		tags: ['text','paragraph','hierarchy'],
	});
	mDetails.set("Bullseye", {
		type: IconType.Wireframe,
		unicode: 'F272',
		category: IconCategory.Stuff,
		tags: ['dartboard'],
	});
	mDetails.set("BullseyeTarget", {
		type: IconType.Wireframe,
		unicode: 'F5F0',
		category: IconCategory.Stuff,
		tags: ['dartboard'],
	});
	mDetails.set("BullseyeTargetEdit", {
		type: IconType.Wireframe,
		unicode: 'E319',
		category: IconCategory.Stuff,
		tags: ['dartboard','pencil'],
	});
	mDetails.set("Bus", {
		type: IconType.Wireframe,
		unicode: 'E806',
		category: IconCategory.Vehicles,
		tags: ['transportation'],
	});
	mDetails.set("BusinessCenterLogo", {
		type: IconType.Wireframe,
		unicode: 'F4B2',
		category: IconCategory.Logos,
		tags: ['briefcase','luggage','suitcase','brand','microsoft','mpsa','volume licensing'],
	});
	mDetails.set("BusinessHoursSign", {
		type: IconType.Wireframe,
		unicode: 'F310',
		category: IconCategory.Stuff,
		tags: ['open','closed'],
	});
	mDetails.set("BusSolid", {
		type: IconType.Solid,
		unicode: 'EB47',
		category: IconCategory.Vehicles,
		tags: ['transportation'],
	});
	mDetails.set("ButtonControl", {
		type: IconType.Wireframe,
		unicode: 'F6C0',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Cafe", {
		type: IconType.Wireframe,
		unicode: 'EC32',
		category: IconCategory.Food,
		tags: ['mug','coffee','tea','drink','beverage'],
	});
	mDetails.set("Cake", {
		type: IconType.Wireframe,
		unicode: 'ECA4',
		category: IconCategory.Food,
		tags: ['slice','candle','dessert'],
	});
	mDetails.set("CalculatedTable", {
		type: IconType.Wireframe,
		unicode: 'E4BE',
		category: undefined,
		tags: [],
	});
	mDetails.set("Calculator", {
		type: IconType.Wireframe,
		unicode: 'E8EF',
		category: IconCategory.Math,
		tags: [],
	});
	mDetails.set("CalculatorAddition", {
		type: IconType.Wireframe,
		unicode: 'E948',
		category: IconCategory.Math,
		tags: ['plus','equation','operator','formula'],
	});
	mDetails.set("CalculatorDelta", {
		type: IconType.Wireframe,
		unicode: 'E406',
		category: IconCategory.Math,
		tags: [],
	});
	mDetails.set("CalculatorEqualTo", {
		type: IconType.Wireframe,
		unicode: 'E94E',
		category: IconCategory.Math,
		tags: ['parallel','equation','operator','formula'],
	});
	mDetails.set("CalculatorGroup", {
		type: IconType.Wireframe,
		unicode: 'E462',
		category: IconCategory.Math,
		tags: [],
	});
	mDetails.set("CalculatorMultiply", {
		type: IconType.Wireframe,
		unicode: 'E947',
		category: IconCategory.Math,
		tags: ['multiplication','equation','operator','formula'],
	});
	mDetails.set("CalculatorNotEqualTo", {
		type: IconType.Wireframe,
		unicode: 'F2D2',
		category: IconCategory.Math,
		tags: ['equation','operator','formula'],
	});
	mDetails.set("CalculatorPercentage", {
		type: IconType.Wireframe,
		unicode: 'E94C',
		category: IconCategory.Math,
		tags: ['equation','operator','formula','modulus'],
	});
	mDetails.set("CalculatorSubtract", {
		type: IconType.Wireframe,
		unicode: 'E949',
		category: IconCategory.Math,
		tags: ['equation','operator','formula','minus','subtraction'],
	});
	mDetails.set("Calendar", {
		type: IconType.Wireframe,
		unicode: 'E787',
		category: IconCategory.Events,
		tags: ['dates','month','time'],
	});
	mDetails.set("CalendarAgenda", {
		type: IconType.Wireframe,
		unicode: 'EE9A',
		category: IconCategory.Events,
		tags: ['dates','day','time'],
	});
	mDetails.set("CalendarDay", {
		type: IconType.Wireframe,
		unicode: 'E8BF',
		category: IconCategory.Events,
		tags: ['dates','time'],
	});
	mDetails.set("CalendarMirrored", {
		type: IconType.Wireframe,
		unicode: 'ED28',
		category: IconCategory.Events,
		tags: ['dates','month','time'],
	});
	mDetails.set("CalendarReply", {
		type: IconType.Wireframe,
		unicode: 'E8F5',
		category: IconCategory.Events,
		tags: ['dates','month','time','arrow','left','email'],
	});
	mDetails.set("CalendarSettings", {
		type: IconType.Wireframe,
		unicode: 'F558',
		category: IconCategory.Events,
		tags: ['dates','month','time','gear','configuration'],
	});
	mDetails.set("CalendarSettingsMirrored", {
		type: IconType.Wireframe,
		unicode: 'F559',
		category: IconCategory.Events,
		tags: ['dates','month','time','gear','configuration'],
	});
	mDetails.set("CalendarWeek", {
		type: IconType.Wireframe,
		unicode: 'E8C0',
		category: IconCategory.Events,
		tags: ['dates','time'],
	});
	mDetails.set("CalendarWorkWeek", {
		type: IconType.Wireframe,
		unicode: 'EF51',
		category: IconCategory.Events,
		tags: ['dates','time'],
	});
	mDetails.set("CalendarYear", {
		type: IconType.Wireframe,
		unicode: 'E371',
		category: IconCategory.Events,
		tags: ['dates','time'],
	});
	mDetails.set("Calories", {
		type: IconType.Wireframe,
		unicode: 'ECAD',
		category: IconCategory.Health,
		tags: ['flame','burn','fire','exercise'],
	});
	mDetails.set("CaloriesAdd", {
		type: IconType.Wireframe,
		unicode: 'F172',
		category: IconCategory.Health,
		tags: ['flame','burn','fire','exercise','plus','new'],
	});
	mDetails.set("Camera", {
		type: IconType.Wireframe,
		unicode: 'E722',
		category: IconCategory.Photography,
		tags: ['picture','lens'],
	});
	mDetails.set("CampaignTemplate", {
		type: IconType.Wireframe,
		unicode: 'F811',
		category: IconCategory.Communication,
		tags: ['megaphone','marketing'],
	});
	mDetails.set("Cancel", {
		type: IconType.Wireframe,
		unicode: 'E711',
		category: IconCategory.Interface,
		tags: ['x','clear','delete','remove'],
	});
	mDetails.set("CannedChat", {
		type: IconType.Wireframe,
		unicode: 'F0F2',
		category: IconCategory.Communication,
		tags: ['speech bubble','talk','message'],
	});
	mDetails.set("Car", {
		type: IconType.Wireframe,
		unicode: 'E804',
		category: IconCategory.Vehicles,
		tags: ['automobile'],
	});
	mDetails.set("CaretBottomLeftCenter8", {
		type: IconType.Wireframe,
		unicode: 'F365',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretBottomLeftSolid8", {
		type: IconType.Solid,
		unicode: 'F121',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretBottomRightCenter8", {
		type: IconType.Wireframe,
		unicode: 'F364',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretBottomRightSolid8", {
		type: IconType.Solid,
		unicode: 'F122',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretDown8", {
		type: IconType.Wireframe,
		unicode: 'EDD8',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretDownSolid8", {
		type: IconType.Solid,
		unicode: 'EDDC',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretHollow", {
		type: IconType.Wireframe,
		unicode: 'E817',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("CaretHollowMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA45',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("CaretLeft8", {
		type: IconType.Wireframe,
		unicode: 'EDD5',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretLeftSolid8", {
		type: IconType.Solid,
		unicode: 'EDD9',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretRight", {
		type: IconType.Wireframe,
		unicode: 'F06B',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretRight8", {
		type: IconType.Wireframe,
		unicode: 'EDD6',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretRightSolid8", {
		type: IconType.Solid,
		unicode: 'EDDA',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretSolid", {
		type: IconType.Solid,
		unicode: 'E818',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("CaretSolid16", {
		type: IconType.Solid,
		unicode: 'EE62',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretSolidAlt", {
		type: IconType.Solid,
		unicode: 'E483',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretSolidDown", {
		type: IconType.Solid,
		unicode: 'F08E',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretSolidLeft", {
		type: IconType.Solid,
		unicode: 'F08D',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretSolidMirrored", {
		type: IconType.Solid,
		unicode: 'EA46',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("CaretSolidRight", {
		type: IconType.Solid,
		unicode: 'F08F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretSolidUp", {
		type: IconType.Solid,
		unicode: 'F090',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretTopLeftCenter8", {
		type: IconType.Wireframe,
		unicode: 'F367',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretTopLeftSolid8", {
		type: IconType.Solid,
		unicode: 'EF54',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretTopRightCenter8", {
		type: IconType.Wireframe,
		unicode: 'F366',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretTopRightSolid8", {
		type: IconType.Solid,
		unicode: 'EF55',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretUp8", {
		type: IconType.Wireframe,
		unicode: 'EDD7',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("CaretUpSolid8", {
		type: IconType.Wireframe,
		unicode: 'EDDB',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Cat", {
		type: IconType.Wireframe,
		unicode: 'ED7F',
		category: IconCategory.Animals,
		tags: ["kitten", "kitty", "pet"],
	});
	mDetails.set("CategoryClassification", {
		type: IconType.Wireframe,
		unicode: 'E48C',
		category: undefined,
		tags: [],
	});
	mDetails.set("CC", {
		type: IconType.Wireframe,
		unicode: 'E7F0',
		category: undefined,
		tags: [],
	});
	mDetails.set("CellPhone", {
		type: IconType.Wireframe,
		unicode: 'E8EA',
		category: IconCategory.Devices,
		tags: ['mobile','telephone'],
	});
	mDetails.set("Certificate", {
		type: IconType.Wireframe,
		unicode: 'EB95',
		category: IconCategory.Documents,
		tags: ['page','award','medal','ribbon','file'],
	});
	mDetails.set("CertifiedDatabase", {
		type: IconType.Wireframe,
		unicode: 'F5BB',
		category: IconCategory.Tables,
		tags: ['award','medal'],
	});
	mDetails.set("ChangeEntitlements", {
		type: IconType.Wireframe,
		unicode: 'E310',
		category: IconCategory.Documents,
		tags: ['page','file','arrows','left','right'],
	});
	mDetails.set("Chart", {
		type: IconType.Wireframe,
		unicode: 'E999',
		category: IconCategory.Charts,
		tags: ['trending'],
	});
	mDetails.set("ChartSeries", {
		type: IconType.Wireframe,
		unicode: 'F513',
		category: IconCategory.Charts,
		tags: ['column'],
	});
	mDetails.set("ChartTemplate", {
		type: IconType.Wireframe,
		unicode: 'F812',
		category: IconCategory.Charts,
		tags: ['bar'],
	});
	mDetails.set("ChartXAngle", {
		type: IconType.Wireframe,
		unicode: 'F514',
		category: IconCategory.Math,
		tags: ['angle','triangle','arrow','graph'],
	});
	mDetails.set("ChartYAngle", {
		type: IconType.Wireframe,
		unicode: 'F515',
		category: IconCategory.Math,
		tags: ['angle','triangle','arrow','graph'],
	});
	mDetails.set("Chat", {
		type: IconType.Wireframe,
		unicode: 'E901',
		category: IconCategory.Communication,
		tags: ['talk','speech bubble','message'],
	});
	mDetails.set("ChatBot", {
		type: IconType.Wireframe,
		unicode: 'F08B',
		category: IconCategory.Communication,
		tags: ['robot','message','speech bubble','smile','beep boop'],
	});
	mDetails.set("ChatInviteFriend", {
		type: IconType.Wireframe,
		unicode: 'ECFE',
		category: IconCategory.Communication,
		tags: ['talk','speech bubble','contact','person'],
	});
	mDetails.set("ChatSolid", {
		type: IconType.Solid,
		unicode: 'F344',
		category: IconCategory.Communication,
		tags: ['talk','speech bubble','message'],
	});
	mDetails.set("Checkbox", {
		type: IconType.Wireframe,
		unicode: 'E739',
		category: IconCategory.Controls,
		tags: ['box','square','empty','task'],
	});
	mDetails.set("CheckboxComposite", {
		type: IconType.Wireframe,
		unicode: 'E73A',
		category: IconCategory.Controls,
		tags: ['done','checkmark','complete','task'],
	});
	mDetails.set("CheckboxCompositeReversed", {
		type: IconType.Wireframe,
		unicode: 'E73D',
		category: IconCategory.Controls,
		tags: ['done','checkmark','complete','task'],
	});
	mDetails.set("CheckboxFill", {
		type: IconType.Colorfill,
		unicode: 'E73B',
		category: IconCategory.Controls,
		tags: ['box','square','task'],
	});
	mDetails.set("CheckboxIndeterminate", {
		type: IconType.Wireframe,
		unicode: 'E73C',
		category: IconCategory.Controls,
		tags: ['task'],
	});
	mDetails.set("CheckboxIndeterminateCombo", {
		type: IconType.Wireframe,
		unicode: 'F16E',
		category: undefined,
		tags: [],
	});
	mDetails.set("CheckedOutByOther12", {
		type: IconType.Wireframe,
		unicode: 'F630',
		category: IconCategory.Arrows,
		tags: ['circle'],
	});
	mDetails.set("CheckedOutByYou12", {
		type: IconType.Wireframe,
		unicode: 'F631',
		category: IconCategory.Arrows,
		tags: ['circle'],
	});
	mDetails.set("CheckList", {
		type: IconType.Wireframe,
		unicode: 'E9D5',
		category: IconCategory.Tasks,
		tags: ['text','paragraph','checkmark'],
	});
	mDetails.set("CheckListCheck", {
		type: IconType.Colorfill,
		unicode: 'F7A9',
		category: IconCategory.Tasks,
		tags: ['text','paragraph','checkmark'],
	});
	mDetails.set("CheckListCheckMirrored", {
		type: IconType.Colorfill,
		unicode: 'F7AB',
		category: IconCategory.Tasks,
		tags: ['text','paragraph','checkmark'],
	});
	mDetails.set("CheckListText", {
		type: IconType.Colorfill,
		unicode: 'F7A8',
		category: IconCategory.Tasks,
		tags: ['text','paragraph','checkmark'],
	});
	mDetails.set("CheckListTextMirrored", {
		type: IconType.Colorfill,
		unicode: 'F7AA',
		category: IconCategory.Tasks,
		tags: ['text','paragraph','checkmark'],
	});
	mDetails.set("CheckMark", {
		type: IconType.Wireframe,
		unicode: 'E73E',
		category: IconCategory.Tasks,
		tags: ['approved','complete','done'],
	});
	mDetails.set("ChevronDown", {
		type: IconType.Wireframe,
		unicode: 'E70D',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronDownEnd6", {
		type: IconType.Solid,
		unicode: 'F36F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronDownMed", {
		type: IconType.Wireframe,
		unicode: 'E972',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronDownSmall", {
		type: IconType.Solid,
		unicode: 'E96E',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronFold10", {
		type: IconType.Solid,
		unicode: 'F36A',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronLeft", {
		type: IconType.Wireframe,
		unicode: 'E76B',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronLeftEnd6", {
		type: IconType.Solid,
		unicode: 'F371',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronLeftMed", {
		type: IconType.Wireframe,
		unicode: 'E973',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronLeftSmall", {
		type: IconType.Solid,
		unicode: 'E96F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronRight", {
		type: IconType.Wireframe,
		unicode: 'E76C',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronRightEnd6", {
		type: IconType.Solid,
		unicode: 'F372',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronRightMed", {
		type: IconType.Wireframe,
		unicode: 'E974',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronRightSmall", {
		type: IconType.Solid,
		unicode: 'E970',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronUnfold10", {
		type: IconType.Wireframe,
		unicode: 'F369',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronUp", {
		type: IconType.Wireframe,
		unicode: 'E70E',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronUpEnd6", {
		type: IconType.Solid,
		unicode: 'F370',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronUpMed", {
		type: IconType.Wireframe,
		unicode: 'E971',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("ChevronUpSmall", {
		type: IconType.Solid,
		unicode: 'E96D',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Childof", {
		type: IconType.Wireframe,
		unicode: 'F82D',
		category: IconCategory.Arrows,
		tags: ['node','hierarchy'],
	});
	mDetails.set("ChoiceColumn", {
		type: IconType.Wireframe,
		unicode: 'E4AE',
		category: undefined,
		tags: [],
	});
	mDetails.set("Chopsticks", {
		type: IconType.Wireframe,
		unicode: 'F4A2',
		category: IconCategory.Food,
		tags: ['asian','bowl'],
	});
	mDetails.set("ChromeBack", {
		type: IconType.Wireframe,
		unicode: 'E830',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("ChromeBackMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA47',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("ChromeClose", {
		type: IconType.Wireframe,
		unicode: 'E8BB',
		category: IconCategory.Controls,
		tags: ['cancel','delete','remove'],
	});
	mDetails.set("ChromeFullScreen", {
		type: IconType.Wireframe,
		unicode: 'E92D',
		category: IconCategory.Controls,
		tags: ['arrows','expand'],
	});
	mDetails.set("ChromeMinimize", {
		type: IconType.Wireframe,
		unicode: 'E921',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("ChromeRestore", {
		type: IconType.Wireframe,
		unicode: 'E923',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("CircleAddition", {
		type: IconType.Wireframe,
		unicode: 'F2E3',
		category: IconCategory.Stars,
		tags: ['plus','new'],
	});
	mDetails.set("CircleAdditionSolid", {
		type: IconType.Solid,
		unicode: 'F2E4',
		category: IconCategory.Stars,
		tags: ['plus','new'],
	});
	mDetails.set("CircleFill", {
		type: IconType.Colorfill,
		unicode: 'EA3B',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("CircleHalfFull", {
		type: IconType.Wireframe,
		unicode: 'ED9E',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("CirclePause", {
		type: IconType.Wireframe,
		unicode: 'F2D9',
		category: IconCategory.Media,
		tags: ['playback','controls'],
	});
	mDetails.set("CirclePauseSolid", {
		type: IconType.Solid,
		unicode: 'F2D8',
		category: IconCategory.Media,
		tags: ['playback','controls'],
	});
	mDetails.set("CirclePlus", {
		type: IconType.Wireframe,
		unicode: 'EAEE',
		category: IconCategory.Stars,
		tags: ['plus','new'],
	});
	mDetails.set("CircleRing", {
		type: IconType.Wireframe,
		unicode: 'EA3A',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("CircleShape", {
		type: IconType.Wireframe,
		unicode: 'F1A5',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("CircleShapeSolid", {
		type: IconType.Solid,
		unicode: 'F63C',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("CircleStop", {
		type: IconType.Wireframe,
		unicode: 'F2DC',
		category: IconCategory.Media,
		tags: ['playback','controls'],
	});
	mDetails.set("CircleStopSolid", {
		type: IconType.Solid,
		unicode: 'F2DB',
		category: IconCategory.Media,
		tags: ['playback','controls'],
	});
	mDetails.set("CityNext", {
		type: IconType.Wireframe,
		unicode: 'EC06',
		category: IconCategory.Maps,
		tags: ['town','skyscraper','building'],
	});
	mDetails.set("CityNext2", {
		type: IconType.Wireframe,
		unicode: 'EC07',
		category: IconCategory.Maps,
		tags: ['town','skyscraper','building'],
	});
	mDetails.set("ClassNotebookLogo16", {
		type: IconType.Wireframe,
		unicode: 'F488',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassNotebookLogo32", {
		type: IconType.Wireframe,
		unicode: 'F486',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassNotebookLogoFill16", {
		type: IconType.Colorfill,
		unicode: 'F489',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassNotebookLogoFill32", {
		type: IconType.Colorfill,
		unicode: 'F487',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassNotebookLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EDC8',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassNotebookLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F48B',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassNotebookLogoInverse32", {
		type: IconType.Wireframe,
		unicode: 'F48A',
		category: IconCategory.Logos,
		tags: ['microsoft','education','onenote'],
	});
	mDetails.set("ClassroomLogo", {
		type: IconType.Wireframe,
		unicode: 'EF75',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','leaf','plant'],
	});
	mDetails.set("Clear", {
		type: IconType.Wireframe,
		unicode: 'E894',
		category: IconCategory.Interface,
		tags: ['x','cancel','delete','remove'],
	});
	mDetails.set("ClearFilter", {
		type: IconType.Wireframe,
		unicode: 'EF8F',
		category: IconCategory.Tables,
		tags: ['funnel','x','close','cancel','remove','delete'],
	});
	mDetails.set("ClearFormatting", {
		type: IconType.Wireframe,
		unicode: 'EDDD',
		category: IconCategory.Formatting,
		tags: ['a','letter','eraser','remove'],
	});
	mDetails.set("ClearFormattingA", {
		type: IconType.Colorfill,
		unicode: 'F79D',
		category: IconCategory.Formatting,
		tags: ['a','letter','remove'],
	});
	mDetails.set("ClearFormattingEraser", {
		type: IconType.Colorfill,
		unicode: 'F79E',
		category: IconCategory.Formatting,
		tags: ['eraser','remove'],
	});
	mDetails.set("ClearNight", {
		type: IconType.Wireframe,
		unicode: 'E9C2',
		category: IconCategory.Weather,
		tags: ['moon'],
	});
	mDetails.set("ClearSelection", {
		type: IconType.Wireframe,
		unicode: 'E8E6',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("ClearSelectionMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA48',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("Clicked", {
		type: IconType.Wireframe,
		unicode: 'F268',
		category: undefined,
		tags: [],
	});
	mDetails.set("ClipboardList", {
		type: IconType.Wireframe,
		unicode: 'F0E3',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("ClipboardListMirrored", {
		type: IconType.Wireframe,
		unicode: 'F0E4',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("ClipboardSolid", {
		type: IconType.Solid,
		unicode: 'F5DC',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("Clock", {
		type: IconType.Wireframe,
		unicode: 'E917',
		category: IconCategory.Events,
		tags: ['time'],
	});
	mDetails.set("CloneToDesktop", {
		type: IconType.Wireframe,
		unicode: 'F28C',
		category: IconCategory.Devices,
		tags: ['computer','monitor','screen','arrow','down','download','install'],
	});
	mDetails.set("ClosedCaption", {
		type: IconType.Wireframe,
		unicode: 'EF84',
		category: IconCategory.Media,
		tags: ['subtitles','hearing impaired'],
	});
	mDetails.set("ClosePane", {
		type: IconType.Wireframe,
		unicode: 'E89F',
		category: IconCategory.Layout,
		tags: ['arrow','right'],
	});
	mDetails.set("ClosePaneMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA49',
		category: IconCategory.Layout,
		tags: ['arrow','left'],
	});
	mDetails.set("Cloud", {
		type: IconType.Wireframe,
		unicode: 'E753',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("CloudAdd", {
		type: IconType.Wireframe,
		unicode: 'ECA9',
		category: IconCategory.Stuff,
		tags: ['plus','new'],
	});
	mDetails.set("CloudDownload", {
		type: IconType.Wireframe,
		unicode: 'EBD3',
		category: IconCategory.Stuff,
		tags: ['arrow'],
	});
	mDetails.set("CloudEdit", {
		type: IconType.Wireframe,
		unicode: 'E4C8',
		category: IconCategory.Stuff,
		tags: ['pencil'],
	});
	mDetails.set("CloudImportExport", {
		type: IconType.Wireframe,
		unicode: 'EE55',
		category: IconCategory.Stuff,
		tags: ['arrows'],
	});
	mDetails.set("CloudLink", {
		type: IconType.Wireframe,
		unicode: 'E4C9',
		category: IconCategory.Stuff,
		tags: ['chain'],
	});
	mDetails.set("CloudSearch", {
		type: IconType.Wireframe,
		unicode: 'EDE4',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("CloudSecure", {
		type: IconType.Wireframe,
		unicode: 'E4D5',
		category: IconCategory.Stuff,
		tags: ['shield'],
	});
	mDetails.set("CloudUpload", {
		type: IconType.Wireframe,
		unicode: 'EC8E',
		category: IconCategory.Stuff,
		tags: ['arrow'],
	});
	mDetails.set("CloudWeather", {
		type: IconType.Wireframe,
		unicode: 'E9BE',
		category: IconCategory.Weather,
		tags: [],
	});
	mDetails.set("Cloudy", {
		type: IconType.Wireframe,
		unicode: 'E9BF',
		category: IconCategory.Weather,
		tags: [],
	});
	mDetails.set("Cocktails", {
		type: IconType.Wireframe,
		unicode: 'EA9D',
		category: IconCategory.Food,
		tags: ['drink','beverage','alcohol','martini','glass'],
	});
	mDetails.set("Code", {
		type: IconType.Wireframe,
		unicode: 'E943',
		category: IconCategory.Development,
		tags: ['programming','brackets'],
	});
	mDetails.set("CodeEdit", {
		type: IconType.Wireframe,
		unicode: 'F544',
		category: IconCategory.Development,
		tags: ['programming','html','pencil'],
	});
	mDetails.set("Coffee", {
		type: IconType.Wireframe,
		unicode: 'EAEF',
		category: IconCategory.Food,
		tags: ['beans'],
	});
	mDetails.set("CoffeeScript", {
		type: IconType.Wireframe,
		unicode: 'F2FA',
		category: IconCategory.Food,
		tags: ['drink','beverage','tea','mug'],
	});
	mDetails.set("CollapseContent", {
		type: IconType.Wireframe,
		unicode: 'F165',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("CollapseContentSingle", {
		type: IconType.Wireframe,
		unicode: 'F166',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("CollapseMenu", {
		type: IconType.Wireframe,
		unicode: 'EF66',
		category: IconCategory.Controls,
		tags: ['hamburger','responsive','mobile'],
	});
	mDetails.set("CollegeFootball", {
		type: IconType.Wireframe,
		unicode: 'EB26',
		category: IconCategory.Games,
		tags: ['sports','athletics'],
	});
	mDetails.set("CollegeHoops", {
		type: IconType.Wireframe,
		unicode: 'EB25',
		category: IconCategory.Games,
		tags: ['ball','sports','athletics','basketball'],
	});
	mDetails.set("Color", {
		type: IconType.Wireframe,
		unicode: 'E790',
		category: IconCategory.Stuff,
		tags: ['palette','paint','artist','tool'],
	});
	mDetails.set("ColorSolid", {
		type: IconType.Solid,
		unicode: 'F354',
		category: IconCategory.Stuff,
		tags: ['palette','paint','artist','tool'],
	});
	mDetails.set("Column", {
		type: IconType.Wireframe,
		unicode: 'E438',
		category: IconCategory.Layout,
		tags: ['page','design','section'],
	});
	mDetails.set("ColumnFunction", {
		type: IconType.Wireframe,
		unicode: 'E4C2',
		category: undefined,
		tags: [],
	});
	mDetails.set("ColumnLeftTwoThirds", {
		type: IconType.Wireframe,
		unicode: 'F1D6',
		category: IconCategory.Layout,
		tags: ['page','design','section'],
	});
	mDetails.set("ColumnLeftTwoThirdsEdit", {
		type: IconType.Wireframe,
		unicode: 'F324',
		category: IconCategory.Layout,
		tags: ['page','design','section','pencil'],
	});
	mDetails.set("ColumnOptions", {
		type: IconType.Wireframe,
		unicode: 'F317',
		category: IconCategory.Layout,
		tags: ['page','design','section','gear','configuration'],
	});
	mDetails.set("ColumnQuestion", {
		type: IconType.Wireframe,
		unicode: 'E4C0',
		category: undefined,
		tags: [],
	});
	mDetails.set("ColumnQuestionMirrored", {
		type: IconType.Wireframe,
		unicode: 'E4C1',
		category: undefined,
		tags: [],
	});
	mDetails.set("ColumnRightTwoThirds", {
		type: IconType.Wireframe,
		unicode: 'F1D7',
		category: IconCategory.Layout,
		tags: ['page','design','section'],
	});
	mDetails.set("ColumnRightTwoThirdsEdit", {
		type: IconType.Wireframe,
		unicode: 'F325',
		category: IconCategory.Layout,
		tags: ['page','design','section','pencil'],
	});
	mDetails.set("ColumnSigma", {
		type: IconType.Wireframe,
		unicode: 'E4BF',
		category: undefined,
		tags: [],
	});
	mDetails.set("ColumnVerticalSection", {
		type: IconType.Wireframe,
		unicode: 'F81E',
		category: IconCategory.Layout,
		tags: ['page','design','section'],
	});
	mDetails.set("ColumnVerticalSectionEdit", {
		type: IconType.Wireframe,
		unicode: 'F806',
		category: IconCategory.Layout,
		tags: ['page','design','section','pencil'],
	});
	mDetails.set("Combine", {
		type: IconType.Wireframe,
		unicode: 'EDBB',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Combobox", {
		type: IconType.Wireframe,
		unicode: 'F516',
		category: IconCategory.Controls,
		tags: ['dropdown','input'],
	});
	mDetails.set("CommandPrompt", {
		type: IconType.Wireframe,
		unicode: 'E756',
		category: IconCategory.Development,
		tags: ['cmd','dos','cli'],
	});
	mDetails.set("Comment", {
		type: IconType.Wireframe,
		unicode: 'E90A',
		category: IconCategory.Communication,
		tags: ['speech bubble','quote','chat','message','talk'],
	});
	mDetails.set("CommentActive", {
		type: IconType.Wireframe,
		unicode: 'F804',
		category: IconCategory.Communication,
		tags: ['speech bubble','quote','chat','message','talk'],
	});
	mDetails.set("CommentAdd", {
		type: IconType.Wireframe,
		unicode: 'F2B3',
		category: IconCategory.Communication,
		tags: ['speech bubble','quote','chat','message','talk','plus','new'],
	});
	mDetails.set("CommentNext", {
		type: IconType.Wireframe,
		unicode: 'F2B4',
		category: IconCategory.Communication,
		tags: ['speech bubble','chat','message','talk','arrow','right'],
	});
	mDetails.set("CommentPrevious", {
		type: IconType.Wireframe,
		unicode: 'F2B5',
		category: IconCategory.Communication,
		tags: ['speech bubble','chat','message','talk','arrow','left'],
	});
	mDetails.set("CommentSolid", {
		type: IconType.Solid,
		unicode: 'E30E',
		category: IconCategory.Communication,
		tags: ['speech bubble','quote','chat','message','talk'],
	});
	mDetails.set("CommentUrgent", {
		type: IconType.Wireframe,
		unicode: 'F307',
		category: IconCategory.Communication,
		tags: ['speech bubble','chat','message','talk','exclamation mark','warning'],
	});
	mDetails.set("Commitments", {
		type: IconType.Wireframe,
		unicode: 'EC4D',
		category: IconCategory.Stuff,
		tags: ['handshake','agreement','greeting'],
	});
	mDetails.set("CommonDataServiceCDS", {
		type: IconType.Wireframe,
		unicode: 'E377',
		category: IconCategory.Logos,
		tags: ['microsoft','m365','o365','brand','dataflex'],
	});
	mDetails.set("CommunicationDetails", {
		type: IconType.Wireframe,
		unicode: 'E4CF',
		category: undefined,
		tags: [],
	});
	mDetails.set("CommunicationDetailsMirrored", {
		type: IconType.Wireframe,
		unicode: 'E4D0',
		category: undefined,
		tags: [],
	});
	mDetails.set("Communications", {
		type: IconType.Wireframe,
		unicode: 'E95A',
		category: IconCategory.Communication,
		tags: ['signal','network','wifi'],
	});
	mDetails.set("CompanyDirectory", {
		type: IconType.Wireframe,
		unicode: 'EF0D',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("CompanyDirectoryMirrored", {
		type: IconType.Wireframe,
		unicode: 'EF2B',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("Compare", {
		type: IconType.Wireframe,
		unicode: 'F057',
		category: IconCategory.Stuff,
		tags: ['scales','justice','weight','tool'],
	});
	mDetails.set("CompareUneven", {
		type: IconType.Wireframe,
		unicode: 'E42E',
		category: IconCategory.Stuff,
		tags: ['scales','justice','weight','tool'],
	});
	mDetails.set("CompassNW", {
		type: IconType.Wireframe,
		unicode: 'E942',
		category: IconCategory.Stuff,
		tags: ['direction','guide','navigation','tool'],
	});
	mDetails.set("Completed", {
		type: IconType.Wireframe,
		unicode: 'E930',
		category: IconCategory.Stars,
		tags: ['checkmark','approved','done'],
	});
	mDetails.set("CompletedSolid", {
		type: IconType.Solid,
		unicode: 'EC61',
		category: IconCategory.Stars,
		tags: ['checkmark','approved','done'],
	});
	mDetails.set("ComplianceAudit", {
		type: IconType.Wireframe,
		unicode: 'E369',
		category: IconCategory.Documents,
		tags: ['search','file','page'],
	});
	mDetails.set("ConfigurationSolid", {
		type: IconType.Solid,
		unicode: 'F334',
		category: IconCategory.Documents,
		tags: ['settings','gear','file','page'],
	});
	mDetails.set("ConnectContacts", {
		type: IconType.Wireframe,
		unicode: 'EFD4',
		category: IconCategory.User,
		tags: ['person','people'],
	});
	mDetails.set("ConnectVirtualMachine", {
		type: IconType.Wireframe,
		unicode: 'EE9D',
		category: IconCategory.Devices,
		tags: ['computer','monitor','network'],
	});
	mDetails.set("ConstructionCone", {
		type: IconType.Wireframe,
		unicode: 'E98F',
		category: IconCategory.Stuff,
		tags: ['tool'],
	});
	mDetails.set("ConstructionConeSolid", {
		type: IconType.Solid,
		unicode: 'F339',
		category: IconCategory.Stuff,
		tags: ['tool'],
	});
	mDetails.set("Contact", {
		type: IconType.Wireframe,
		unicode: 'E77B',
		category: IconCategory.User,
		tags: ['person'],
	});
	mDetails.set("ContactCard", {
		type: IconType.Wireframe,
		unicode: 'EEBD',
		category: IconCategory.User,
		tags: ['person','business card','profile'],
	});
	mDetails.set("ContactCardSettings", {
		type: IconType.Wireframe,
		unicode: 'F556',
		category: IconCategory.User,
		tags: ['person','business card','profile','gear','configuration'],
	});
	mDetails.set("ContactCardSettingsMirrored", {
		type: IconType.Wireframe,
		unicode: 'F557',
		category: IconCategory.User,
		tags: ['person','business card','profile','gear','configuration'],
	});
	mDetails.set("ContactHeart", {
		type: IconType.Wireframe,
		unicode: 'F862',
		category: IconCategory.User,
		tags: ['person','health','favorite','love'],
	});
	mDetails.set("ContactInfo", {
		type: IconType.Wireframe,
		unicode: 'E779',
		category: IconCategory.User,
		tags: ['person','profile'],
	});
	mDetails.set("ContactLink", {
		type: IconType.Wireframe,
		unicode: 'F25F',
		category: IconCategory.User,
		tags: ['person','business card','profile','chain'],
	});
	mDetails.set("ContactList", {
		type: IconType.Wireframe,
		unicode: 'F7E5',
		category: IconCategory.User,
		tags: ['person','people'],
	});
	mDetails.set("ContactLock", {
		type: IconType.Wireframe,
		unicode: 'F400',
		category: IconCategory.User,
		tags: ['person','locked','secure'],
	});
	mDetails.set("ContentFeed", {
		type: IconType.Wireframe,
		unicode: 'E428',
		category: IconCategory.Communication,
		tags: [],
	});
	mDetails.set("ContentSettings", {
		type: IconType.Wireframe,
		unicode: 'F647',
		category: IconCategory.Layout,
		tags: ['webpage','configuration','gear'],
	});
	mDetails.set("ContextMenu", {
		type: IconType.Wireframe,
		unicode: 'F37C',
		category: IconCategory.Controls,
		tags: ['hamburger'],
	});
	mDetails.set("Contrast", {
		type: IconType.Wireframe,
		unicode: 'E7A1',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("Copy", {
		type: IconType.Wireframe,
		unicode: 'E8C8',
		category: IconCategory.Formatting,
		tags: ['duplicate'],
	});
	mDetails.set("CopyEdit", {
		type: IconType.Wireframe,
		unicode: 'E464',
		category: IconCategory.Formatting,
		tags: ['duplicate','pencil'],
	});
	mDetails.set("CortanaLogoBeckonInner", {
		type: IconType.Wireframe,
		unicode: 'F4C6',
		category: IconCategory.Logos,
		tags: ['microsoft','circle','ring','m365'],
	});
	mDetails.set("CortanaLogoBeckonOuter", {
		type: IconType.Wireframe,
		unicode: 'F4C7',
		category: IconCategory.Logos,
		tags: ['microsoft','circle','ring','m365'],
	});
	mDetails.set("CortanaLogoInner", {
		type: IconType.Wireframe,
		unicode: 'E832',
		category: IconCategory.Logos,
		tags: ['microsoft','circle','ring','m365'],
	});
	mDetails.set("CortanaLogoOuter", {
		type: IconType.Wireframe,
		unicode: 'E831',
		category: IconCategory.Logos,
		tags: ['microsoft','circle','ring','m365'],
	});
	mDetails.set("CortanaLogoReadyInner", {
		type: IconType.Colorfill,
		unicode: 'F4C8',
		category: IconCategory.Logos,
		tags: ['microsoft','circle','ring','m365'],
	});
	mDetails.set("CortanaLogoReadyOuter", {
		type: IconType.Wireframe,
		unicode: 'F4C9',
		category: IconCategory.Logos,
		tags: ['microsoft','circle','ring','m365'],
	});
	mDetails.set("CostContralLedgerAdmin", {
		type: IconType.Wireframe,
		unicode: 'F208',
		category: undefined,
		tags: [],
	});
	mDetails.set("Cotton", {
		type: IconType.Wireframe,
		unicode: 'EAF3',
		category: IconCategory.Stuff,
		tags: ['tree','plant'],
	});
	mDetails.set("Coupon", {
		type: IconType.Wireframe,
		unicode: 'F7BC',
		category: IconCategory.Stuff,
		tags: ['percentage'],
	});
	mDetails.set("CPlusPlus", {
		type: IconType.Wireframe,
		unicode: 'F2F4',
		category: IconCategory.Development,
		tags: ['add','language','programming'],
	});
	mDetails.set("CPlusPlusLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2F3',
		category: IconCategory.Development,
		tags: ['add','programming'],
	});
	mDetails.set("CreateMailRule", {
		type: IconType.Wireframe,
		unicode: 'F67A',
		category: IconCategory.Communication,
		tags: ['email','envelope','folder'],
	});
	mDetails.set("Cricket", {
		type: IconType.Wireframe,
		unicode: 'EB1E',
		category: IconCategory.Games,
		tags: ['sports','athletics','ball','bat'],
	});
	mDetails.set("CriticalErrorSolid", {
		type: IconType.Solid,
		unicode: 'F5C9',
		category: undefined,
		tags: [],
	});
	mDetails.set("CRMProcesses", {
		type: IconType.Wireframe,
		unicode: 'EFB1',
		category: IconCategory.Logos,
		tags: ['customer relationship management'],
	});
	mDetails.set("CRMReport", {
		type: IconType.Wireframe,
		unicode: 'EFFE',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("CRMServices", {
		type: IconType.Wireframe,
		unicode: 'EFD2',
		category: IconCategory.User,
		tags: ['person','wrench','tools','configuration','settings'],
	});
	mDetails.set("Crop", {
		type: IconType.Wireframe,
		unicode: 'E7A8',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("Crown", {
		type: IconType.Wireframe,
		unicode: 'ED01',
		category: IconCategory.Stuff,
		tags: ['king','queen','royal'],
	});
	mDetails.set("CrownSolid", {
		type: IconType.Solid,
		unicode: 'F336',
		category: IconCategory.Stuff,
		tags: ['king','queen','royal'],
	});
	mDetails.set("CSharp", {
		type: IconType.Wireframe,
		unicode: 'F2F0',
		category: IconCategory.Development,
		tags: ['hashtag','pound sign','programming','language'],
	});
	mDetails.set("CSharpLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2EF',
		category: IconCategory.Development,
		tags: ['hashtag','pound sign','programming'],
	});
	mDetails.set("CSS", {
		type: IconType.Wireframe,
		unicode: 'EBEF',
		category: IconCategory.Development,
		tags: ['paintbrush','language'],
	});
	mDetails.set("CtrlButton", {
		type: IconType.Wireframe,
		unicode: 'E4B8',
		category: undefined,
		tags: [],
	});
	mDetails.set("CubeShape", {
		type: IconType.Wireframe,
		unicode: 'F1AA',
		category: IconCategory.Shapes,
		tags: ['box','package'],
	});
	mDetails.set("CubeShapeSolid", {
		type: IconType.Solid,
		unicode: 'E421',
		category: IconCategory.Shapes,
		tags: ['box','package'],
	});
	mDetails.set("Currency", {
		type: IconType.Wireframe,
		unicode: 'EAF5',
		category: IconCategory.Math,
		tags: ['symbol','dollar sign','euro'],
	});
	mDetails.set("CustomerAssets", {
		type: IconType.Wireframe,
		unicode: 'F426',
		category: undefined,
		tags: [],
	});
	mDetails.set("CustomizeToolbar", {
		type: IconType.Wireframe,
		unicode: 'F828',
		category: IconCategory.Layout,
		tags: ['gear','settings','configuration'],
	});
	mDetails.set("CustomList", {
		type: IconType.Wireframe,
		unicode: 'EEBE',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("CustomListMirrored", {
		type: IconType.Wireframe,
		unicode: 'EEBF',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("Cut", {
		type: IconType.Wireframe,
		unicode: 'E8C6',
		category: IconCategory.Formatting,
		tags: ['scissors'],
	});
	mDetails.set("Cycling", {
		type: IconType.Wireframe,
		unicode: 'EAC7',
		category: IconCategory.Games,
		tags: ['sports','athletics','bicycle','ride'],
	});
	mDetails.set("D365CoreHR", {
		type: IconType.Wireframe,
		unicode: 'F6BD',
		category: IconCategory.Logos,
		tags: ['dynamics'],
	});
	mDetails.set("D365CustomerInsights", {
		type: IconType.Wireframe,
		unicode: 'F3C8',
		category: IconCategory.Logos,
		tags: ['dynamics'],
	});
	mDetails.set("D365ProjectOperations", {
		type: IconType.Wireframe,
		unicode: 'E432',
		category: IconCategory.Logos,
		tags: ['dynamics'],
	});
	mDetails.set("D365TalentInsight", {
		type: IconType.Wireframe,
		unicode: 'F6BC',
		category: IconCategory.Logos,
		tags: ['person','contact','lightbulb','idea','dynamics'],
	});
	mDetails.set("D365TalentLearn", {
		type: IconType.Wireframe,
		unicode: 'F6BB',
		category: IconCategory.User,
		tags: ['person','contact','cap','graduation','dynamics','education','training'],
	});
	mDetails.set("DashboardAdd", {
		type: IconType.Wireframe,
		unicode: 'F52D',
		category: IconCategory.Charts,
		tags: ['gauge','dashboard','plus'],
	});
	mDetails.set("Database", {
		type: IconType.Wireframe,
		unicode: 'EFC7',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("DatabaseSync", {
		type: IconType.Wireframe,
		unicode: 'F842',
		category: IconCategory.Tables,
		tags: ['refresh','reset'],
	});
	mDetails.set("DatabaseView", {
		type: IconType.Wireframe,
		unicode: 'E437',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("DataConnectionLibrary", {
		type: IconType.Wireframe,
		unicode: 'EEB7',
		category: IconCategory.Folders,
		tags: ['report','chart'],
	});
	mDetails.set("Dataflows", {
		type: IconType.Wireframe,
		unicode: 'F7DD',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("DataflowsLink", {
		type: IconType.Wireframe,
		unicode: 'E366',
		category: IconCategory.Charts,
		tags: ['chain'],
	});
	mDetails.set("DataManagementSettings", {
		type: IconType.Wireframe,
		unicode: 'EFC8',
		category: IconCategory.Tables,
		tags: ['database','configuration','gear'],
	});
	mDetails.set("DateTime", {
		type: IconType.Wireframe,
		unicode: 'EC92',
		category: IconCategory.Events,
		tags: ['calendar','month'],
	});
	mDetails.set("DateTime12", {
		type: IconType.Wireframe,
		unicode: 'F38F',
		category: IconCategory.Events,
		tags: ['calendar','month'],
	});
	mDetails.set("DateTime2", {
		type: IconType.Wireframe,
		unicode: 'EA17',
		category: IconCategory.Events,
		tags: ['calendar','month'],
	});
	mDetails.set("DateTimeMirrored", {
		type: IconType.Wireframe,
		unicode: 'EE93',
		category: IconCategory.Events,
		tags: ['calendar','month'],
	});
	mDetails.set("DeactivateOrders", {
		type: IconType.Wireframe,
		unicode: 'EFE1',
		category: IconCategory.Documents,
		tags: ['close','page','delete','remove','x'],
	});
	mDetails.set("Decimals", {
		type: IconType.Wireframe,
		unicode: 'F218',
		category: IconCategory.Math,
		tags: ['0.0'],
	});
	mDetails.set("DecisionSolid", {
		type: IconType.Solid,
		unicode: 'F350',
		category: IconCategory.Stuff,
		tags: ['gavel','judge'],
	});
	mDetails.set("DeclineCall", {
		type: IconType.Wireframe,
		unicode: 'F405',
		category: IconCategory.Communication,
		tags: ['telephone','hang up'],
	});
	mDetails.set("DecreaseIndent", {
		type: IconType.Wireframe,
		unicode: 'E39B',
		category: IconCategory.Formatting,
		tags: ['text','paragraph','arrow','left'],
	});
	mDetails.set("DecreaseIndentArrow", {
		type: IconType.Wireframe,
		unicode: 'F7A3',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("DecreaseIndentArrowMirrored", {
		type: IconType.Wireframe,
		unicode: 'F7A7',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("DecreaseIndentLegacy", {
		type: IconType.Wireframe,
		unicode: 'E290',
		category: IconCategory.Formatting,
		tags: ['arrow','text','paragraph','tab'],
	});
	mDetails.set("DecreaseIndentMirrored", {
		type: IconType.Wireframe,
		unicode: 'E39C',
		category: IconCategory.Formatting,
		tags: ['tab','text','paragraph','right','arrow'],
	});
	mDetails.set("DecreaseIndentText", {
		type: IconType.Wireframe,
		unicode: 'F7A2',
		category: IconCategory.Formatting,
		tags: ['paragraph','tab'],
	});
	mDetails.set("DecreaseIndentTextMirrored", {
		type: IconType.Wireframe,
		unicode: 'F7A6',
		category: IconCategory.Formatting,
		tags: ['tab','paragraph'],
	});
	mDetails.set("DefaultRatio", {
		type: IconType.Wireframe,
		unicode: 'F529',
		category: IconCategory.Photography,
		tags: ['1'],
	});
	mDetails.set("DefectSolid", {
		type: IconType.Solid,
		unicode: 'F449',
		category: IconCategory.Stuff,
		tags: ['lightbulb','broken','idea'],
	});
	mDetails.set("DefenderApp", {
		type: IconType.Wireframe,
		unicode: 'E83D',
		category: undefined,
		tags: [],
	});
	mDetails.set("DefenderBadge12", {
		type: IconType.Wireframe,
		unicode: 'F0FB',
		category: undefined,
		tags: [],
	});
	mDetails.set("DefenderTVM", {
		type: IconType.Wireframe,
		unicode: 'F6B3',
		category: IconCategory.Logos,
		tags: ['castle','flag'],
	});
	mDetails.set("Delete", {
		type: IconType.Wireframe,
		unicode: 'E74D',
		category: IconCategory.Interface,
		tags: ['trashcan','garbage','remove'],
	});
	mDetails.set("DeleteColumns", {
		type: IconType.Wireframe,
		unicode: 'F64E',
		category: IconCategory.Tables,
		tags: ['remove','x'],
	});
	mDetails.set("DeleteRows", {
		type: IconType.Wireframe,
		unicode: 'F64F',
		category: IconCategory.Tables,
		tags: ['remove','x'],
	});
	mDetails.set("DeleteRowsMirrored", {
		type: IconType.Wireframe,
		unicode: 'F650',
		category: IconCategory.Tables,
		tags: ['remove','x'],
	});
	mDetails.set("DeleteTable", {
		type: IconType.Wireframe,
		unicode: 'F651',
		category: IconCategory.Tables,
		tags: ['remove','x'],
	});
	mDetails.set("DeliveryTruck", {
		type: IconType.Wireframe,
		unicode: 'EBF4',
		category: IconCategory.Vehicles,
		tags: ['automobile','shipping'],
	});
	mDetails.set("DelveAnalytics", {
		type: IconType.Wireframe,
		unicode: 'EEEE',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','o365','m365'],
	});
	mDetails.set("DelveAnalyticsLogo", {
		type: IconType.Wireframe,
		unicode: 'EDCA',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','o365','m365'],
	});
	mDetails.set("DelveLogo", {
		type: IconType.Wireframe,
		unicode: 'F280',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','o365','m365'],
	});
	mDetails.set("DelveLogoFill", {
		type: IconType.Colorfill,
		unicode: 'F281',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365'],
	});
	mDetails.set("DelveLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'ED76',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','o365','m365'],
	});
	mDetails.set("DensityComfy", {
		type: IconType.Wireframe,
		unicode: 'E4BA',
		category: undefined,
		tags: [],
	});
	mDetails.set("DensityDefault", {
		type: IconType.Wireframe,
		unicode: 'E4B9',
		category: undefined,
		tags: [],
	});
	mDetails.set("DependencyAdd", {
		type: IconType.Wireframe,
		unicode: 'E344',
		category: IconCategory.Tables,
		tags: ['arrow'],
	});
	mDetails.set("DependencyRemove", {
		type: IconType.Wireframe,
		unicode: 'E345',
		category: IconCategory.Tables,
		tags: ['clear','close','cancel','delete'],
	});
	mDetails.set("Deploy", {
		type: IconType.Wireframe,
		unicode: 'F29D',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Descending", {
		type: IconType.Wireframe,
		unicode: 'EDC1',
		category: IconCategory.Tables,
		tags: ['sort','arrow','up'],
	});
	mDetails.set("Design", {
		type: IconType.Wireframe,
		unicode: 'EB3C',
		category: IconCategory.Stuff,
		tags: ['ruler','protractor','layout','tool'],
	});
	mDetails.set("DesktopScreenshot", {
		type: IconType.Wireframe,
		unicode: 'F5D9',
		category: IconCategory.Photography,
		tags: ['computer','monitor','screen','camera','picture','capture'],
	});
	mDetails.set("DeveloperTools", {
		type: IconType.Wireframe,
		unicode: 'EC7A',
		category: IconCategory.Stuff,
		tags: ['wrench','screwdriver','settings','configuration','tool'],
	});
	mDetails.set("DeviceBug", {
		type: IconType.Wireframe,
		unicode: 'E424',
		category: IconCategory.Devices,
		tags: ['insect','beetle','tick'],
	});
	mDetails.set("DeviceOff", {
		type: IconType.Wireframe,
		unicode: 'E402',
		category: IconCategory.Devices,
		tags: ['screen','monitor','blocked','banned','computer'],
	});
	mDetails.set("DeviceRun", {
		type: IconType.Wireframe,
		unicode: 'E401',
		category: IconCategory.Devices,
		tags: ['screen','monitor','play','computer'],
	});
	mDetails.set("Devices2", {
		type: IconType.Wireframe,
		unicode: 'E975',
		category: IconCategory.Devices,
		tags: ['screen','monitor','tablet','computer'],
	});
	mDetails.set("Devices3", {
		type: IconType.Wireframe,
		unicode: 'EA6C',
		category: IconCategory.Devices,
		tags: ['screen','monitor','phone','computer'],
	});
	mDetails.set("Devices4", {
		type: IconType.Wireframe,
		unicode: 'EB66',
		category: IconCategory.Devices,
		tags: ['screen','monitor','printer','computer'],
	});
	mDetails.set("Diagnostic", {
		type: IconType.Wireframe,
		unicode: 'E9D9',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("DiagnosticDataBarTooltip", {
		type: IconType.Wireframe,
		unicode: 'F7DF',
		category: IconCategory.Charts,
		tags: ['column','speech bubble'],
	});
	mDetails.set("Dialpad", {
		type: IconType.Wireframe,
		unicode: 'E75F',
		category: IconCategory.Communication,
		tags: ['telephone','numbers'],
	});
	mDetails.set("Diamond", {
		type: IconType.Wireframe,
		unicode: 'ED02',
		category: IconCategory.Stuff,
		tags: ['gem','jewel'],
	});
	mDetails.set("DiamondSolid", {
		type: IconType.Solid,
		unicode: 'F34C',
		category: IconCategory.Stuff,
		tags: ['gem','jewel'],
	});
	mDetails.set("Dictionary", {
		type: IconType.Wireframe,
		unicode: 'E82D',
		category: IconCategory.Stuff,
		tags: ['book'],
	});
	mDetails.set("DictionaryRemove", {
		type: IconType.Wireframe,
		unicode: 'F69A',
		category: IconCategory.Stuff,
		tags: ['book','delete','x','cancel','close'],
	});
	mDetails.set("DietPlanNotebook", {
		type: IconType.Wireframe,
		unicode: 'EAC8',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("DiffInline", {
		type: IconType.Wireframe,
		unicode: 'F309',
		category: IconCategory.Development,
		tags: ['differential','merge','highlight'],
	});
	mDetails.set("DiffSideBySide", {
		type: IconType.Wireframe,
		unicode: 'F30A',
		category: IconCategory.Development,
		tags: ['differential','merge','highlight'],
	});
	mDetails.set("DisableUpdates", {
		type: IconType.Wireframe,
		unicode: 'E8D8',
		category: IconCategory.Arrows,
		tags: ['loop','banned','blocked'],
	});
	mDetails.set("DisconnectVirtualMachine", {
		type: IconType.Wireframe,
		unicode: 'F873',
		category: IconCategory.Devices,
		tags: ['network','computer'],
	});
	mDetails.set("Dislike", {
		type: IconType.Wireframe,
		unicode: 'E8E0',
		category: IconCategory.Interface,
		tags: ['thumbs down','social'],
	});
	mDetails.set("DislikeSolid", {
		type: IconType.Solid,
		unicode: 'F3C0',
		category: IconCategory.Interface,
		tags: ['thumbs down','social'],
	});
	mDetails.set("DistributeDown", {
		type: IconType.Wireframe,
		unicode: 'F76A',
		category: IconCategory.Arrows,
		tags: ['hierarchy','tree'],
	});
	mDetails.set("DockLeft", {
		type: IconType.Wireframe,
		unicode: 'E90C',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("DockLeftMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA4C',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("DockRight", {
		type: IconType.Wireframe,
		unicode: 'E90D',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("DocLibrary", {
		type: IconType.Wireframe,
		unicode: 'EEB8',
		category: IconCategory.Folders,
		tags: ['page'],
	});
	mDetails.set("DocsLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EDCB',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("Document", {
		type: IconType.Wireframe,
		unicode: 'E8A5',
		category: IconCategory.Documents,
		tags: ['file','page'],
	});
	mDetails.set("DocumentApproval", {
		type: IconType.Wireframe,
		unicode: 'F28B',
		category: IconCategory.Documents,
		tags: ['file','checkmark','page'],
	});
	mDetails.set("Documentation", {
		type: IconType.Wireframe,
		unicode: 'EC17',
		category: IconCategory.Documents,
		tags: ['files','pages'],
	});
	mDetails.set("DocumentManagement", {
		type: IconType.Wireframe,
		unicode: 'EFFC',
		category: IconCategory.Documents,
		tags: ['file','settings','gear','configuration','page'],
	});
	mDetails.set("DocumentReply", {
		type: IconType.Wireframe,
		unicode: 'EF57',
		category: IconCategory.Documents,
		tags: ['file','email','arrow','page'],
	});
	mDetails.set("DocumentSearch", {
		type: IconType.Wireframe,
		unicode: 'EF6C',
		category: IconCategory.Documents,
		tags: ['file','page'],
	});
	mDetails.set("DocumentSet", {
		type: IconType.Wireframe,
		unicode: 'EED6',
		category: IconCategory.Documents,
		tags: ['files','folder','pages'],
	});
	mDetails.set("DOM", {
		type: IconType.Wireframe,
		unicode: 'EC8D',
		category: IconCategory.Charts,
		tags: ['hierarchy','tree','nodes'],
	});
	mDetails.set("DonutChart", {
		type: IconType.Wireframe,
		unicode: 'F368',
		category: IconCategory.Charts,
		tags: ['pie'],
	});
	mDetails.set("Door", {
		type: IconType.Wireframe,
		unicode: 'EB75',
		category: IconCategory.Stuff,
		tags: ['exit','entrance'],
	});
	mDetails.set("DoubleBookmark", {
		type: IconType.Wireframe,
		unicode: 'EB8F',
		category: IconCategory.Interface,
		tags: ['ribbon'],
	});
	mDetails.set("DoubleChevronDown", {
		type: IconType.Wireframe,
		unicode: 'EE04',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronDown12", {
		type: IconType.Wireframe,
		unicode: 'EE97',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronDown8", {
		type: IconType.Solid,
		unicode: 'F36B',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronLeft", {
		type: IconType.Wireframe,
		unicode: 'EDBE',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronLeft12", {
		type: IconType.Wireframe,
		unicode: 'EE98',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronLeft8", {
		type: IconType.Solid,
		unicode: 'F36D',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronLeftMed", {
		type: IconType.Wireframe,
		unicode: 'E991',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronLeftMedMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA4D',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronRight", {
		type: IconType.Wireframe,
		unicode: 'EDBF',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronRight12", {
		type: IconType.Wireframe,
		unicode: 'EE99',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronRight8", {
		type: IconType.Solid,
		unicode: 'F36E',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronUp", {
		type: IconType.Wireframe,
		unicode: 'EDBD',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronUp12", {
		type: IconType.Wireframe,
		unicode: 'EE96',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleChevronUp8", {
		type: IconType.Solid,
		unicode: 'F36C',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DoubleColumn", {
		type: IconType.Wireframe,
		unicode: 'F1D4',
		category: IconCategory.Layout,
		tags: ['page','section'],
	});
	mDetails.set("DoubleColumnEdit", {
		type: IconType.Wireframe,
		unicode: 'F322',
		category: IconCategory.Layout,
		tags: ['page','section','pencil'],
	});
	mDetails.set("DoubleDownArrow", {
		type: IconType.Wireframe,
		unicode: 'F769',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Down", {
		type: IconType.Wireframe,
		unicode: 'E74B',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Download", {
		type: IconType.Wireframe,
		unicode: 'E896',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DownloadDocument", {
		type: IconType.Wireframe,
		unicode: 'F549',
		category: IconCategory.Documents,
		tags: ['page','arrow','down','file'],
	});
	mDetails.set("DragObject", {
		type: IconType.Wireframe,
		unicode: 'F553',
		category: IconCategory.Controls,
		tags: ['arrows','move','reposition'],
	});
	mDetails.set("DrillDown", {
		type: IconType.Wireframe,
		unicode: 'F532',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DrillDownSolid", {
		type: IconType.Solid,
		unicode: 'F533',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("DrillExpand", {
		type: IconType.Wireframe,
		unicode: 'F534',
		category: IconCategory.Arrows,
		tags: ['hierarchy','tree','down','circle'],
	});
	mDetails.set("DrillShow", {
		type: IconType.Wireframe,
		unicode: 'F535',
		category: IconCategory.Arrows,
		tags: ['down','circle'],
	});
	mDetails.set("DrillThrough", {
		type: IconType.Wireframe,
		unicode: 'F5B9',
		category: IconCategory.Arrows,
		tags: ['right','circle'],
	});
	mDetails.set("DriverOff", {
		type: IconType.Wireframe,
		unicode: 'E3FB',
		category: IconCategory.Games,
		tags: ['puzzle','piece','jigsaw'],
	});
	mDetails.set("DRM", {
		type: IconType.Wireframe,
		unicode: 'ECA8',
		category: IconCategory.Stars,
		tags: ['remove','collapse','minus'],
	});
	mDetails.set("Drop", {
		type: IconType.Wireframe,
		unicode: 'EB42',
		category: IconCategory.Stuff,
		tags: ['liquid','water','drip'],
	});
	mDetails.set("Dropdown", {
		type: IconType.Wireframe,
		unicode: 'EDC5',
		category: IconCategory.Controls,
		tags: ['combobox','input'],
	});
	mDetails.set("DropShape", {
		type: IconType.Wireframe,
		unicode: 'F1A8',
		category: IconCategory.Shapes,
		tags: ['liquid','water','drip'],
	});
	mDetails.set("DropShapeSolid", {
		type: IconType.Solid,
		unicode: 'F63F',
		category: IconCategory.Shapes,
		tags: ['liquid','water','drip'],
	});
	mDetails.set("DuplicateRow", {
		type: IconType.Wireframe,
		unicode: 'F82A',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("Duststorm", {
		type: IconType.Wireframe,
		unicode: 'E9CD',
		category: IconCategory.Weather,
		tags: ['blow','windy','dusty','gust'],
	});
	mDetails.set("DynamicList", {
		type: IconType.Wireframe,
		unicode: 'E491',
		category: undefined,
		tags: [],
	});
	mDetails.set("Dynamics365Logo", {
		type: IconType.Wireframe,
		unicode: 'EDCC',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','m365','o365','crm','erp','customer relationship management','enterprise resource planning'],
	});
	mDetails.set("DynamicSMBLogo", {
		type: IconType.Wireframe,
		unicode: 'EDCD',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','m365','o365','crm','erp','customer relationship management','enterprise resource planning','small mid-sized business'],
	});
	mDetails.set("EaseOfAccess", {
		type: IconType.Wireframe,
		unicode: 'E776',
		category: IconCategory.Interface,
		tags: ['arrows'],
	});
	mDetails.set("EatDrink", {
		type: IconType.Wireframe,
		unicode: 'E807',
		category: IconCategory.Food,
		tags: ['silverware','utensils','knife','fork'],
	});
	mDetails.set("EdgeOldLogo", {
		type: IconType.Wireframe,
		unicode: 'EC60',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','browser'],
	});
	mDetails.set("eDiscovery", {
		type: IconType.Wireframe,
		unicode: 'E370',
		category: IconCategory.Maps,
		tags: ['government','search','courthouse','building'],
	});
	mDetails.set("Edit", {
		type: IconType.Wireframe,
		unicode: 'E70F',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("EditContact", {
		type: IconType.Wireframe,
		unicode: 'EFD3',
		category: IconCategory.User,
		tags: ['person','pencil'],
	});
	mDetails.set("EditCreate", {
		type: IconType.Wireframe,
		unicode: 'F3C9',
		category: IconCategory.Interface,
		tags: ['pencil','paint brush'],
	});
	mDetails.set("EditMail", {
		type: IconType.Wireframe,
		unicode: 'EF61',
		category: IconCategory.Communication,
		tags: ['email','envelope','pencil'],
	});
	mDetails.set("EditMirrored", {
		type: IconType.Wireframe,
		unicode: 'EB7E',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("EditNote", {
		type: IconType.Wireframe,
		unicode: 'ED9D',
		category: IconCategory.Tasks,
		tags: ['pencil'],
	});
	mDetails.set("EditPhoto", {
		type: IconType.Wireframe,
		unicode: 'EF77',
		category: IconCategory.Photography,
		tags: ['pencil'],
	});
	mDetails.set("EditSolid12", {
		type: IconType.Wireframe,
		unicode: 'F4B5',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("EditSolidMirrored12", {
		type: IconType.Wireframe,
		unicode: 'F4B6',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("EditStyle", {
		type: IconType.Wireframe,
		unicode: 'EF60',
		category: IconCategory.Formatting,
		tags: ['font','pencil'],
	});
	mDetails.set("EditTable", {
		type: IconType.Wireframe,
		unicode: 'E4C4',
		category: undefined,
		tags: [],
	});
	mDetails.set("Education", {
		type: IconType.Wireframe,
		unicode: 'E7BE',
		category: IconCategory.Stuff,
		tags: ['hat','cap','graduation'],
	});
	mDetails.set("Ellipse", {
		type: IconType.Wireframe,
		unicode: 'F4FB',
		category: IconCategory.Shapes,
		tags: ['oval'],
	});
	mDetails.set("Embed", {
		type: IconType.Wireframe,
		unicode: 'ECCE',
		category: IconCategory.Development,
		tags: ['code'],
	});
	mDetails.set("EMI", {
		type: IconType.Wireframe,
		unicode: 'E731',
		category: IconCategory.Maps,
		tags: ['building'],
	});
	mDetails.set("Emoji", {
		type: IconType.Wireframe,
		unicode: 'E899',
		category: IconCategory.Emoji,
		tags: ['face','happy','smile','grin'],
	});
	mDetails.set("Emoji2", {
		type: IconType.Wireframe,
		unicode: 'E76E',
		category: IconCategory.Emoji,
		tags: ["face", "happy", "smile"],
	});
	mDetails.set("EmojiDisappointed", {
		type: IconType.Wireframe,
		unicode: 'EA88',
		category: IconCategory.Emoji,
		tags: ["face", "sad", "frown"],
	});
	mDetails.set("EmojiNeutral", {
		type: IconType.Wireframe,
		unicode: 'EA87',
		category: IconCategory.Emoji,
		tags: ['face','meh','whatever'],
	});
	mDetails.set("EmojiTabSymbols", {
		type: IconType.Wireframe,
		unicode: 'ED58',
		category: IconCategory.Emoji,
		tags: ['heart','plus','add','refresh','blocked','banned'],
	});
	mDetails.set("EmptyRecycleBin", {
		type: IconType.Wireframe,
		unicode: 'EF88',
		category: IconCategory.Interface,
		tags: ['arrow','trash'],
	});
	mDetails.set("Encryption", {
		type: IconType.Wireframe,
		unicode: 'F69D',
		category: IconCategory.Security,
		tags: ['shield','secure','locked'],
	});
	mDetails.set("EndPointSolid", {
		type: IconType.Solid,
		unicode: 'EB4B',
		category: IconCategory.Stuff,
		tags: ['flag'],
	});
	mDetails.set("EngineeringGroup", {
		type: IconType.Wireframe,
		unicode: 'F362',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("EntitlementPolicy", {
		type: IconType.Wireframe,
		unicode: 'E346',
		category: IconCategory.Documents,
		tags: ['list','page','file'],
	});
	mDetails.set("EntitlementRedemption", {
		type: IconType.Wireframe,
		unicode: 'E347',
		category: IconCategory.Documents,
		tags: ['page','file','checkmark'],
	});
	mDetails.set("EntityExtraction", {
		type: IconType.Wireframe,
		unicode: 'E467',
		category: undefined,
		tags: [],
	});
	mDetails.set("EntryDecline", {
		type: IconType.Wireframe,
		unicode: 'F555',
		category: IconCategory.Tables,
		tags: ['x','cancel','clear','remove','delete'],
	});
	mDetails.set("EntryView", {
		type: IconType.Wireframe,
		unicode: 'F554',
		category: IconCategory.Tables,
		tags: ['eye'],
	});
	mDetails.set("Equalizer", {
		type: IconType.Wireframe,
		unicode: 'E9E9',
		category: IconCategory.Stuff,
		tags: ['sound','mixer'],
	});
	mDetails.set("EraseTool", {
		type: IconType.Wireframe,
		unicode: 'E75C',
		category: IconCategory.Stuff,
		tags: ['eraser','drawing'],
	});
	mDetails.set("Error", {
		type: IconType.Wireframe,
		unicode: 'E783',
		category: IconCategory.Stars,
		tags: ['exclamation mark','warning'],
	});
	mDetails.set("ErrorBadge", {
		type: IconType.Wireframe,
		unicode: 'EA39',
		category: IconCategory.Stars,
		tags: ['x','warning','close','remove','delete','cancel'],
	});
	mDetails.set("Event", {
		type: IconType.Wireframe,
		unicode: 'ECA3',
		category: IconCategory.Events,
		tags: ['calendar','day'],
	});
	mDetails.set("Event12", {
		type: IconType.Wireframe,
		unicode: 'F763',
		category: IconCategory.Events,
		tags: ['calendar','day'],
	});
	mDetails.set("EventAccepted", {
		type: IconType.Wireframe,
		unicode: 'F422',
		category: IconCategory.Events,
		tags: ['calendar','day','checkmark'],
	});
	mDetails.set("EventDate", {
		type: IconType.Wireframe,
		unicode: 'F059',
		category: IconCategory.Events,
		tags: ['calendar','day','21'],
	});
	mDetails.set("EventDateMissed12", {
		type: IconType.Wireframe,
		unicode: 'F764',
		category: IconCategory.Events,
		tags: ['calendar','day','exclamation mark','warning'],
	});
	mDetails.set("EventDeclined", {
		type: IconType.Wireframe,
		unicode: 'F425',
		category: IconCategory.Events,
		tags: ['calendar','day','x','close','remove','delete','cancel'],
	});
	mDetails.set("EventInfo", {
		type: IconType.Wireframe,
		unicode: 'ED8B',
		category: IconCategory.Events,
		tags: ['calendar','day','exclamation mark'],
	});
	mDetails.set("EventTentative", {
		type: IconType.Wireframe,
		unicode: 'F423',
		category: IconCategory.Events,
		tags: ['calendar','day','question mark'],
	});
	mDetails.set("EventTentativeMirrored", {
		type: IconType.Wireframe,
		unicode: 'F424',
		category: IconCategory.Events,
		tags: ['calendar','day','question mark'],
	});
	mDetails.set("EventToDoLogo", {
		type: IconType.Wireframe,
		unicode: 'F869',
		category: IconCategory.Logos,
		tags: ['calendar','checkmark','brand','microsoft'],
	});
	mDetails.set("ExcelDocument", {
		type: IconType.Wireframe,
		unicode: 'EF73',
		category: IconCategory.Documents,
		tags: ['office','xlsx','spreadsheet','microsoft'],
	});
	mDetails.set("ExcelLogo", {
		type: IconType.Wireframe,
		unicode: 'F1E5',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','m365','o365','spreadsheet','xls'],
	});
	mDetails.set("ExcelLogo16", {
		type: IconType.Wireframe,
		unicode: 'F397',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','m365','o365','spreadsheet','xls'],
	});
	mDetails.set("ExcelLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EC28',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','m365','o365','spreadsheet','xls'],
	});
	mDetails.set("ExcelLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F396',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','m365','o365','spreadsheet','xls'],
	});
	mDetails.set("ExchangeLogo", {
		type: IconType.Wireframe,
		unicode: 'F284',
		category: IconCategory.Logos,
		tags: ['outlook','email','brand','microsoft','m365','o365'],
	});
	mDetails.set("ExchangeLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'ED78',
		category: IconCategory.Logos,
		tags: ['outlook','email','brand','microsoft','m365','o365'],
	});
	mDetails.set("ExerciseTracker", {
		type: IconType.Wireframe,
		unicode: 'EACC',
		category: IconCategory.Games,
		tags: ['shoe','sneaker','trainer','sports','athletics','foot'],
	});
	mDetails.set("ExpandMenu", {
		type: IconType.Wireframe,
		unicode: 'EF67',
		category: IconCategory.Layout,
		tags: ['equal','parallel'],
	});
	mDetails.set("ExploreContent", {
		type: IconType.Wireframe,
		unicode: 'ECCD',
		category: IconCategory.Layout,
		tags: ['expand'],
	});
	mDetails.set("ExploreContentSingle", {
		type: IconType.Wireframe,
		unicode: 'F164',
		category: IconCategory.Layout,
		tags: ['expand'],
	});
	mDetails.set("ExploreData", {
		type: IconType.Wireframe,
		unicode: 'F5B6',
		category: IconCategory.Charts,
		tags: ['search','report'],
	});
	mDetails.set("Export", {
		type: IconType.Wireframe,
		unicode: 'EDE1',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("ExportMirrored", {
		type: IconType.Wireframe,
		unicode: 'EDE2',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("ExternalBuild", {
		type: IconType.Wireframe,
		unicode: 'F445',
		category: IconCategory.Development,
		tags: ['crane','construction'],
	});
	mDetails.set("ExternalTFVC", {
		type: IconType.Wireframe,
		unicode: 'F446',
		category: IconCategory.Development,
		tags: ['team foundation version control','source control','git'],
	});
	mDetails.set("ExternalUser", {
		type: IconType.Wireframe,
		unicode: 'E4CA',
		category: undefined,
		tags: [],
	});
	mDetails.set("ExternalXAML", {
		type: IconType.Wireframe,
		unicode: 'F447',
		category: IconCategory.Development,
		tags: ['language'],
	});
	mDetails.set("Eyedropper", {
		type: IconType.Wireframe,
		unicode: 'EF3C',
		category: IconCategory.Health,
		tags: ['medicine','color picker'],
	});
	mDetails.set("EyeShadow", {
		type: IconType.Wireframe,
		unicode: 'F7EB',
		category: IconCategory.Stuff,
		tags: ['makeup','cosmetics'],
	});
	mDetails.set("F12DevTools", {
		type: IconType.Wireframe,
		unicode: 'EBEE',
		category: IconCategory.Development,
		tags: ['arrow','cursor'],
	});
	mDetails.set("FabricAssetLibrary", {
		type: IconType.Wireframe,
		unicode: 'F09C',
		category: IconCategory.Folders,
		tags: ['music','media'],
	});
	mDetails.set("FabricDataConnectionLibrary", {
		type: IconType.Wireframe,
		unicode: 'F09D',
		category: IconCategory.Folders,
		tags: ['reports','charts'],
	});
	mDetails.set("FabricDocLibrary", {
		type: IconType.Wireframe,
		unicode: 'F09E',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricFolder", {
		type: IconType.Wireframe,
		unicode: 'F0A9',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricFolderConfirm", {
		type: IconType.Wireframe,
		unicode: 'F7FF',
		category: IconCategory.Folders,
		tags: ['checkmark'],
	});
	mDetails.set("FabricFolderFill", {
		type: IconType.Solid,
		unicode: 'F0AA',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricFolderLink", {
		type: IconType.Wireframe,
		unicode: 'E45C',
		category: IconCategory.Folders,
		tags: ['chain'],
	});
	mDetails.set("FabricFolderSearch", {
		type: IconType.Wireframe,
		unicode: 'F0A4',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricFolderUpload", {
		type: IconType.Wireframe,
		unicode: 'E3AC',
		category: IconCategory.Folders,
		tags: ['arrow','up'],
	});
	mDetails.set("FabricFormLibrary", {
		type: IconType.Wireframe,
		unicode: 'F09F',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricFormLibraryMirrored", {
		type: IconType.Wireframe,
		unicode: 'F0A0',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricMovetoFolder", {
		type: IconType.Wireframe,
		unicode: 'F0A5',
		category: IconCategory.Folders,
		tags: ['arrow','right'],
	});
	mDetails.set("FabricNetworkFolder", {
		type: IconType.Wireframe,
		unicode: 'F5E6',
		category: IconCategory.Folders,
		tags: ['globe'],
	});
	mDetails.set("FabricNewFolder", {
		type: IconType.Wireframe,
		unicode: 'F0AB',
		category: IconCategory.Folders,
		tags: ['plus','add'],
	});
	mDetails.set("FabricOpenFolderHorizontal", {
		type: IconType.Wireframe,
		unicode: 'F0A8',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricPictureLibrary", {
		type: IconType.Wireframe,
		unicode: 'F0AC',
		category: IconCategory.Folders,
		tags: ['photos','album'],
	});
	mDetails.set("FabricPublicFolder", {
		type: IconType.Wireframe,
		unicode: 'F0A3',
		category: IconCategory.Folders,
		tags: ['arrows'],
	});
	mDetails.set("FabricReportLibrary", {
		type: IconType.Wireframe,
		unicode: 'F0A1',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricReportLibraryMirrored", {
		type: IconType.Wireframe,
		unicode: 'F0A2',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricSyncFolder", {
		type: IconType.Wireframe,
		unicode: 'F0A7',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricTextHighlight", {
		type: IconType.Wireframe,
		unicode: 'F79C',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FabricTextHighlightComposite", {
		type: IconType.Wireframe,
		unicode: 'F7DA',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FabricUnsyncFolder", {
		type: IconType.Wireframe,
		unicode: 'F0A6',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FabricUserFolder", {
		type: IconType.Wireframe,
		unicode: 'F5E5',
		category: IconCategory.Folders,
		tags: ['contact','person'],
	});
	mDetails.set("Family", {
		type: IconType.Wireframe,
		unicode: 'EBDA',
		category: IconCategory.User,
		tags: ['person','people','child','marriage','parents'],
	});
	mDetails.set("FangBody", {
		type: IconType.Wireframe,
		unicode: 'ECEB',
		category: IconCategory.Animals,
		tags: ["doggy", "puppy", "pet"],
	});
	mDetails.set("FastForward", {
		type: IconType.Wireframe,
		unicode: 'EB9D',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right'],
	});
	mDetails.set("FastForwardEightX", {
		type: IconType.Wireframe,
		unicode: 'E443',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right','8'],
	});
	mDetails.set("FastForwardFourX", {
		type: IconType.Wireframe,
		unicode: 'E442',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right','4'],
	});
	mDetails.set("FastForwardOneFiveX", {
		type: IconType.Wireframe,
		unicode: 'E440',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right','1.5'],
	});
	mDetails.set("FastForwardOneX", {
		type: IconType.Wireframe,
		unicode: 'E43F',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right','1'],
	});
	mDetails.set("FastForwardPointFiveX", {
		type: IconType.Wireframe,
		unicode: 'E43E',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right','0.5'],
	});
	mDetails.set("FastForwardTwoX", {
		type: IconType.Wireframe,
		unicode: 'E441',
		category: IconCategory.Media,
		tags: ['control','skip','arrows','right','2'],
	});
	mDetails.set("FastMode", {
		type: IconType.Wireframe,
		unicode: 'F19A',
		category: IconCategory.Animals,
		tags: ['bunny','rabbit','patrick','speed'],
	});
	mDetails.set("Favicon", {
		type: IconType.Wireframe,
		unicode: 'E737',
		category: IconCategory.Layout,
		tags: ['window','site'],
	});
	mDetails.set("FavoriteList", {
		type: IconType.Wireframe,
		unicode: 'E728',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("FavoriteStar", {
		type: IconType.Wireframe,
		unicode: 'E734',
		category: IconCategory.Stars,
		tags: ['rating'],
	});
	mDetails.set("FavoriteStarFill", {
		type: IconType.Solid,
		unicode: 'E735',
		category: IconCategory.Stars,
		tags: ['rating'],
	});
	mDetails.set("Fax", {
		type: IconType.Wireframe,
		unicode: 'EF5C',
		category: IconCategory.Devices,
		tags: ['machine'],
	});
	mDetails.set("Feedback", {
		type: IconType.Wireframe,
		unicode: 'ED15',
		category: IconCategory.Communication,
		tags: ['question mark','speech bubble','talk','message','chat','user','person'],
	});
	mDetails.set("FeedbackRequestMirroredSolid", {
		type: IconType.Solid,
		unicode: 'F35A',
		category: IconCategory.Communication,
		tags: ['question mark','speech bubble','talk','message','chat'],
	});
	mDetails.set("FeedbackRequestSolid", {
		type: IconType.Solid,
		unicode: 'F359',
		category: IconCategory.Communication,
		tags: ['question mark','speech bubble','talk','message','chat'],
	});
	mDetails.set("FeedbackResponseSolid", {
		type: IconType.Solid,
		unicode: 'F35B',
		category: IconCategory.Communication,
		tags: ['question mark','speech bubble','talk','message','chat'],
	});
	mDetails.set("Ferry", {
		type: IconType.Wireframe,
		unicode: 'E7E3',
		category: IconCategory.Vehicles,
		tags: ['boat','ship','cruise'],
	});
	mDetails.set("FerrySolid", {
		type: IconType.Solid,
		unicode: 'EB48',
		category: IconCategory.Vehicles,
		tags: ['boat','ship','cruise'],
	});
	mDetails.set("FieldChanged", {
		type: IconType.Wireframe,
		unicode: 'F2C3',
		category: IconCategory.Controls,
		tags: ['textbox','input','pencil','edit'],
	});
	mDetails.set("FieldEmpty", {
		type: IconType.Wireframe,
		unicode: 'F2C1',
		category: IconCategory.Controls,
		tags: ['textbox','input','rectangle'],
	});
	mDetails.set("FieldFilled", {
		type: IconType.Wireframe,
		unicode: 'F2C2',
		category: IconCategory.Controls,
		tags: ['textbox','input'],
	});
	mDetails.set("FieldNotChanged", {
		type: IconType.Wireframe,
		unicode: 'F2C4',
		category: IconCategory.Controls,
		tags: ['textbox','input','pencil','edit'],
	});
	mDetails.set("FieldReadOnly", {
		type: IconType.Wireframe,
		unicode: 'F442',
		category: IconCategory.Controls,
		tags: ['textbox','input','locked','secure'],
	});
	mDetails.set("FieldRequired", {
		type: IconType.Wireframe,
		unicode: 'F443',
		category: IconCategory.Controls,
		tags: ['textbox','input','warning','alert','exclamation mark'],
	});
	mDetails.set("FileASPX", {
		type: IconType.Wireframe,
		unicode: 'F2E9',
		category: IconCategory.Documents,
		tags: ['page','globe','world'],
	});
	mDetails.set("FileBug", {
		type: IconType.Wireframe,
		unicode: 'F30D',
		category: IconCategory.Documents,
		tags: ['page','exclamation mark'],
	});
	mDetails.set("FileCode", {
		type: IconType.Wireframe,
		unicode: 'F30E',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileComment", {
		type: IconType.Wireframe,
		unicode: 'F30F',
		category: IconCategory.Documents,
		tags: ['page','speech bubble','message'],
	});
	mDetails.set("FileCSS", {
		type: IconType.Wireframe,
		unicode: 'F2EA',
		category: IconCategory.Documents,
		tags: ['page','style'],
	});
	mDetails.set("FileHTML", {
		type: IconType.Wireframe,
		unicode: 'F2ED',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileImage", {
		type: IconType.Wireframe,
		unicode: 'F311',
		category: IconCategory.Documents,
		tags: ['page','img','picture','jpeg','png','bmp','jpg','tiff','gif'],
	});
	mDetails.set("FileJAVA", {
		type: IconType.Wireframe,
		unicode: 'F2E8',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileLess", {
		type: IconType.Wireframe,
		unicode: 'F2EC',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileOff", {
		type: IconType.Wireframe,
		unicode: 'E3FC',
		category: IconCategory.Documents,
		tags: ['page','banned','blocked'],
	});
	mDetails.set("FilePDB", {
		type: IconType.Wireframe,
		unicode: 'F2E5',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileRequest", {
		type: IconType.Wireframe,
		unicode: 'F789',
		category: IconCategory.Folders,
		tags: ['arrow','left'],
	});
	mDetails.set("FileSass", {
		type: IconType.Wireframe,
		unicode: 'F2EB',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileSQL", {
		type: IconType.Wireframe,
		unicode: 'F2E7',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileSymlink", {
		type: IconType.Wireframe,
		unicode: 'F312',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileSystem", {
		type: IconType.Wireframe,
		unicode: 'E433',
		category: IconCategory.Devices,
		tags: ['filing','drawer','server'],
	});
	mDetails.set("FileTemplate", {
		type: IconType.Wireframe,
		unicode: 'F2E6',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("FileTypeSolution", {
		type: IconType.Wireframe,
		unicode: 'F387',
		category: IconCategory.Development,
		tags: ['visual studio'],
	});
	mDetails.set("FileYML", {
		type: IconType.Wireframe,
		unicode: 'F5DA',
		category: IconCategory.Documents,
		tags: ['page'],
	});
	mDetails.set("Filter", {
		type: IconType.Wireframe,
		unicode: 'E71C',
		category: IconCategory.Tables,
		tags: ['funnel'],
	});
	mDetails.set("FilterAscending", {
		type: IconType.Wireframe,
		unicode: 'F21A',
		category: IconCategory.Tables,
		tags: ['funnel','arrow','up'],
	});
	mDetails.set("FilterDescending", {
		type: IconType.Wireframe,
		unicode: 'F21B',
		category: IconCategory.Tables,
		tags: ['funnel','arrow','down'],
	});
	mDetails.set("Filters", {
		type: IconType.Wireframe,
		unicode: 'E795',
		category: IconCategory.Stuff,
		tags: ['paintbrush','color','tool'],
	});
	mDetails.set("FilterSettings", {
		type: IconType.Wireframe,
		unicode: 'F76C',
		category: IconCategory.Tables,
		tags: ['funnel','configuration','gear'],
	});
	mDetails.set("FilterSolid", {
		type: IconType.Solid,
		unicode: 'F412',
		category: IconCategory.Tables,
		tags: ['funnel'],
	});
	mDetails.set("FiltersSolid", {
		type: IconType.Solid,
		unicode: 'F353',
		category: IconCategory.Stuff,
		tags: ['paintbrush','color','tool'],
	});
	mDetails.set("Financial", {
		type: IconType.Wireframe,
		unicode: 'E7BB',
		category: IconCategory.Charts,
		tags: ['trending'],
	});
	mDetails.set("FinancialMirroredSolid", {
		type: IconType.Solid,
		unicode: 'F347',
		category: IconCategory.Charts,
		tags: ['trending'],
	});
	mDetails.set("FinancialSolid", {
		type: IconType.Solid,
		unicode: 'F346',
		category: IconCategory.Charts,
		tags: ['trending'],
	});
	mDetails.set("Fingerprint", {
		type: IconType.Wireframe,
		unicode: 'E928',
		category: IconCategory.Security,
		tags: [],
	});
	mDetails.set("FitPage", {
		type: IconType.Wireframe,
		unicode: 'E9A6',
		category: IconCategory.Layout,
		tags: ['stretch'],
	});
	mDetails.set("FitWidth", {
		type: IconType.Wireframe,
		unicode: 'E9A7',
		category: IconCategory.Layout,
		tags: ['stretch'],
	});
	mDetails.set("FiveTileGrid", {
		type: IconType.Wireframe,
		unicode: 'F274',
		category: IconCategory.Layout,
		tags: ['table','hero'],
	});
	mDetails.set("FixedAssetManagement", {
		type: IconType.Wireframe,
		unicode: 'EF93',
		category: IconCategory.Devices,
		tags: ['suitcase','briefcase','baggage','luggage','desktop','computer','screen','monitor'],
	});
	mDetails.set("FixedColumnWidth", {
		type: IconType.Wireframe,
		unicode: 'E3EA',
		category: IconCategory.Tables,
		tags: ['ruler'],
	});
	mDetails.set("Flag", {
		type: IconType.Wireframe,
		unicode: 'E7C1',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("FlameSolid", {
		type: IconType.Solid,
		unicode: 'F1F3',
		category: IconCategory.Stuff,
		tags: ['fire','burning','torch'],
	});
	mDetails.set("FlashAuto", {
		type: IconType.Wireframe,
		unicode: 'E95C',
		category: IconCategory.Photography,
		tags: ['camera','lightning bolt'],
	});
	mDetails.set("Flashlight", {
		type: IconType.Wireframe,
		unicode: 'E754',
		category: IconCategory.Stuff,
		tags: ['torch','explore','tool'],
	});
	mDetails.set("FlashOff", {
		type: IconType.Wireframe,
		unicode: 'EA6E',
		category: IconCategory.Photography,
		tags: ['camera','lightning bolt','banned','blocked'],
	});
	mDetails.set("FlickDown", {
		type: IconType.Solid,
		unicode: 'E935',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("FlickLeft", {
		type: IconType.Solid,
		unicode: 'E937',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("FlickRight", {
		type: IconType.Solid,
		unicode: 'E938',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("FlickUp", {
		type: IconType.Solid,
		unicode: 'E936',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Flow", {
		type: IconType.Wireframe,
		unicode: 'EF90',
		category: IconCategory.Logos,
		tags: ['power automate'],
	});
	mDetails.set("FlowChart", {
		type: IconType.Wireframe,
		unicode: 'E9D4',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("Flower", {
		type: IconType.Wireframe,
		unicode: 'F54E',
		category: IconCategory.Stuff,
		tags: ['plant','spring','garden'],
	});
	mDetails.set("FlowTemplate", {
		type: IconType.Wireframe,
		unicode: 'E49C',
		category: undefined,
		tags: [],
	});
	mDetails.set("FluidLogo", {
		type: IconType.Wireframe,
		unicode: 'E48A',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("FocalPoint", {
		type: IconType.Wireframe,
		unicode: 'F277',
		category: IconCategory.Photography,
		tags: ['target','mountains','camera'],
	});
	mDetails.set("Focus", {
		type: IconType.Wireframe,
		unicode: 'EA6F',
		category: IconCategory.Photography,
		tags: ['camera'],
	});
	mDetails.set("FocusView", {
		type: IconType.Wireframe,
		unicode: 'F1A3',
		category: IconCategory.Layout,
		tags: ['zoom','collapse'],
	});
	mDetails.set("Fog", {
		type: IconType.Wireframe,
		unicode: 'E9CB',
		category: IconCategory.Weather,
		tags: ['cloud','smog','visibility'],
	});
	mDetails.set("Folder", {
		type: IconType.Wireframe,
		unicode: 'E8B7',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderFill", {
		type: IconType.Solid,
		unicode: 'E8D5',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderHorizontal", {
		type: IconType.Wireframe,
		unicode: 'F12B',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderList", {
		type: IconType.Wireframe,
		unicode: 'F2CE',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderListMirrored", {
		type: IconType.Wireframe,
		unicode: 'F2CF',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderOpen", {
		type: IconType.Wireframe,
		unicode: 'E838',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderQuery", {
		type: IconType.Wireframe,
		unicode: 'F2CD',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FolderSearch", {
		type: IconType.Wireframe,
		unicode: 'EF65',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FollowUser", {
		type: IconType.Wireframe,
		unicode: 'EE05',
		category: IconCategory.User,
		tags: ['person','arrow','right'],
	});
	mDetails.set("Font", {
		type: IconType.Wireframe,
		unicode: 'E8D2',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FontColor", {
		type: IconType.Wireframe,
		unicode: 'E8D3',
		category: IconCategory.Formatting,
		tags: ['letter'],
	});
	mDetails.set("FontColorA", {
		type: IconType.Wireframe,
		unicode: 'F4EC',
		category: IconCategory.Formatting,
		tags: ['letter'],
	});
	mDetails.set("FontColorSwatch", {
		type: IconType.Wireframe,
		unicode: 'F4ED',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FontDecrease", {
		type: IconType.Wireframe,
		unicode: 'E8E7',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FontIncrease", {
		type: IconType.Wireframe,
		unicode: 'E8E8',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FontSize", {
		type: IconType.Wireframe,
		unicode: 'E8E9',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("FontSize2", {
		type: IconType.Wireframe,
		unicode: 'E3C0',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("Footer", {
		type: IconType.Wireframe,
		unicode: 'F82E',
		category: IconCategory.Layout,
		tags: ['page','section'],
	});
	mDetails.set("FormatPainter", {
		type: IconType.Wireframe,
		unicode: 'E3DC',
		category: IconCategory.Stuff,
		tags: ['paintbrush','color','tool'],
	});
	mDetails.set("FormLibrary", {
		type: IconType.Wireframe,
		unicode: 'EEB9',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FormLibraryMirrored", {
		type: IconType.Wireframe,
		unicode: 'EEBA',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("FormProcessing", {
		type: IconType.Wireframe,
		unicode: 'E48B',
		category: IconCategory.Documents,
		tags: [],
	});
	mDetails.set("Forum", {
		type: IconType.Wireframe,
		unicode: 'E378',
		category: IconCategory.Communication,
		tags: ['speech bubble','talk','chat'],
	});
	mDetails.set("Forward", {
		type: IconType.Wireframe,
		unicode: 'E72A',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("ForwardEvent", {
		type: IconType.Wireframe,
		unicode: 'ED8C',
		category: IconCategory.Events,
		tags: ['calendar','day','send','arrow','right'],
	});
	mDetails.set("Freezing", {
		type: IconType.Wireframe,
		unicode: 'E9EF',
		category: IconCategory.Weather,
		tags: ['cold','thermometer','temperature'],
	});
	mDetails.set("FreezingRain", {
		type: IconType.Wireframe,
		unicode: 'E475',
		category: undefined,
		tags: [],
	});
	mDetails.set("Frigid", {
		type: IconType.Wireframe,
		unicode: 'E9CA',
		category: IconCategory.Stuff,
		tags: ['cold','thermometer','temperature'],
	});
	mDetails.set("FrontCamera", {
		type: IconType.Wireframe,
		unicode: 'E96B',
		category: IconCategory.User,
		tags: ['person'],
	});
	mDetails.set("FSharp", {
		type: IconType.Wireframe,
		unicode: 'F2F6',
		category: IconCategory.Development,
		tags: ['language','programming','hashtag'],
	});
	mDetails.set("FSharpLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2F5',
		category: IconCategory.Development,
		tags: ['programming','hashtag'],
	});
	mDetails.set("FullCircleMask", {
		type: IconType.Colorfill,
		unicode: 'E91F',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("FullHistory", {
		type: IconType.Wireframe,
		unicode: 'F31C',
		category: IconCategory.Interface,
		tags: ['clock','loop','arrow'],
	});
	mDetails.set("FullScreen", {
		type: IconType.Wireframe,
		unicode: 'E740',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("FullView", {
		type: IconType.Wireframe,
		unicode: 'F1A2',
		category: IconCategory.Layout,
		tags: ['expand','zoom'],
	});
	mDetails.set("FullWidth", {
		type: IconType.Wireframe,
		unicode: 'F2FE',
		category: IconCategory.Layout,
		tags: ['page','section'],
	});
	mDetails.set("FullWidthEdit", {
		type: IconType.Wireframe,
		unicode: 'F2FF',
		category: IconCategory.Layout,
		tags: ['page','section','pencil'],
	});
	mDetails.set("FunctionalManagerDashboard", {
		type: IconType.Wireframe,
		unicode: 'F542',
		category: IconCategory.Charts,
		tags: ['hierarchy','organizational','tree','clock'],
	});
	mDetails.set("FunnelChart", {
		type: IconType.Wireframe,
		unicode: 'E9F1',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("GallatinLogo", {
		type: IconType.Wireframe,
		unicode: 'F496',
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("Game", {
		type: IconType.Wireframe,
		unicode: 'E7FC',
		category: IconCategory.Games,
		tags: ['controller','console','videogames'],
	});
	mDetails.set("Gather", {
		type: IconType.Wireframe,
		unicode: 'E460',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("Generate", {
		type: IconType.Wireframe,
		unicode: 'E9DA',
		category: IconCategory.Documents,
		tags: ['arrow','right'],
	});
	mDetails.set("GenericScan", {
		type: IconType.Wireframe,
		unicode: 'EE6F',
		category: IconCategory.Controls,
		tags: ['barcode'],
	});
	mDetails.set("GenericScanFilled", {
		type: IconType.Solid,
		unicode: 'F7E3',
		category: IconCategory.Controls,
		tags: ['barcode'],
	});
	mDetails.set("GIF", {
		type: IconType.Wireframe,
		unicode: 'F4A9',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("Giftbox", {
		type: IconType.Wireframe,
		unicode: 'EC1F',
		category: IconCategory.Stuff,
		tags: ['bow','package','birthday','celebration','present'],
	});
	mDetails.set("GiftboxOpen", {
		type: IconType.Wireframe,
		unicode: 'F133',
		category: IconCategory.Stuff,
		tags: ['package','birthday','celebration','present'],
	});
	mDetails.set("GiftBoxSolid", {
		type: IconType.Solid,
		unicode: 'F341',
		category: IconCategory.Stuff,
		tags: ['bow','package','birthday','celebration','present'],
	});
	mDetails.set("GiftCard", {
		type: IconType.Wireframe,
		unicode: 'EB8E',
		category: IconCategory.Stuff,
		tags: ['bow','package','birthday','celebration','present'],
	});
	mDetails.set("GitGraph", {
		type: IconType.Wireframe,
		unicode: 'F2CA',
		category: IconCategory.Development,
		tags: ['source control'],
	});
	mDetails.set("Glasses", {
		type: IconType.Wireframe,
		unicode: 'EA16',
		category: IconCategory.Health,
		tags: ['eye','vision','spectacles','bifocals','reading'],
	});
	mDetails.set("Glimmer", {
		type: IconType.Wireframe,
		unicode: 'ECF4',
		category: IconCategory.Interface,
		tags: ['new'],
	});
	mDetails.set("GlobalNavButton", {
		type: IconType.Wireframe,
		unicode: 'E700',
		category: IconCategory.Controls,
		tags: ['menu','hamburger'],
	});
	mDetails.set("GlobalNavButtonActive", {
		type: IconType.Wireframe,
		unicode: 'F89F',
		category: IconCategory.Controls,
		tags: ['menu','hamburger'],
	});
	mDetails.set("Globe", {
		type: IconType.Wireframe,
		unicode: 'E774',
		category: IconCategory.Maps,
		tags: ['earth','planet'],
	});
	mDetails.set("Globe2", {
		type: IconType.Wireframe,
		unicode: 'F49A',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("GlobeFavorite", {
		type: IconType.Wireframe,
		unicode: 'EF53',
		category: IconCategory.Maps,
		tags: ['star','earth','planet'],
	});
	mDetails.set("Go", {
		type: IconType.Wireframe,
		unicode: 'E8AD',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Golf", {
		type: IconType.Wireframe,
		unicode: 'EB1F',
		category: IconCategory.Games,
		tags: ['ball','sports','athletics','tee'],
	});
	mDetails.set("GoMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA4F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("GoToDashboard", {
		type: IconType.Wireframe,
		unicode: 'EEED',
		category: IconCategory.Charts,
		tags: ['gauge','dashboard','arrow','right'],
	});
	mDetails.set("GotoToday", {
		type: IconType.Wireframe,
		unicode: 'E8D1',
		category: IconCategory.Events,
		tags: ['calendar','arrow','up'],
	});
	mDetails.set("GreetingCard", {
		type: IconType.Wireframe,
		unicode: 'F54B',
		category: IconCategory.Stuff,
		tags: ['heart','love','celebration','birthday'],
	});
	mDetails.set("GridViewLarge", {
		type: IconType.Wireframe,
		unicode: 'F234',
		category: IconCategory.Tables,
		tags: ['box','square'],
	});
	mDetails.set("GridViewMedium", {
		type: IconType.Wireframe,
		unicode: 'F233',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("GridViewSmall", {
		type: IconType.Wireframe,
		unicode: 'F232',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("GripperBarHorizontal", {
		type: IconType.Wireframe,
		unicode: 'E76F',
		category: IconCategory.Controls,
		tags: ['parallel'],
	});
	mDetails.set("GripperBarVertical", {
		type: IconType.Wireframe,
		unicode: 'E784',
		category: IconCategory.Controls,
		tags: ['parallel'],
	});
	mDetails.set("GripperDotsVertical", {
		type: IconType.Wireframe,
		unicode: 'F772',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("GripperTool", {
		type: IconType.Wireframe,
		unicode: 'E75E',
		category: IconCategory.Controls,
		tags: ['swaddled baby'],
	});
	mDetails.set("Group", {
		type: IconType.Wireframe,
		unicode: 'E902',
		category: IconCategory.User,
		tags: ['person','people','crowd','meeting','gathering'],
	});
	mDetails.set("GroupedAscending", {
		type: IconType.Wireframe,
		unicode: 'EE67',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("GroupedDescending", {
		type: IconType.Wireframe,
		unicode: 'EE66',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("GroupedList", {
		type: IconType.Wireframe,
		unicode: 'EF74',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("GroupList", {
		type: IconType.Wireframe,
		unicode: 'F168',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("GroupObject", {
		type: IconType.Wireframe,
		unicode: 'F4F1',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("GroupRemove", {
		type: IconType.Wireframe,
		unicode: 'E495',
		category: undefined,
		tags: [],
	});
	mDetails.set("GUID", {
		type: IconType.Wireframe,
		unicode: 'F52B',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Guitar", {
		type: IconType.Wireframe,
		unicode: 'F49B',
		category: IconCategory.Stuff,
		tags: ['music','rock','strum','band','concert'],
	});
	mDetails.set("HailDay", {
		type: IconType.Wireframe,
		unicode: 'EA00',
		category: IconCategory.Weather,
		tags: ['cloud','sun','rain'],
	});
	mDetails.set("HailNight", {
		type: IconType.Wireframe,
		unicode: 'EA13',
		category: IconCategory.Weather,
		tags: ['cloud','moon','rain'],
	});
	mDetails.set("HalfAlpha", {
		type: IconType.Wireframe,
		unicode: 'E97E',
		category: IconCategory.Formatting,
		tags: ['letter'],
	});
	mDetails.set("HalfCircle", {
		type: IconType.Wireframe,
		unicode: 'F501',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("HandsFree", {
		type: IconType.Wireframe,
		unicode: 'EAD0',
		category: IconCategory.Stuff,
		tags: ['wave','hello','highfive','stop','smack','raise'],
	});
	mDetails.set("Handwriting", {
		type: IconType.Wireframe,
		unicode: 'E929',
		category: IconCategory.Devices,
		tags: ['tablet','pencil'],
	});
	mDetails.set("HardDrive", {
		type: IconType.Wireframe,
		unicode: 'EDA2',
		category: IconCategory.Devices,
		tags: ['disk','server','box','computer'],
	});
	mDetails.set("HardDriveGroup", {
		type: IconType.Wireframe,
		unicode: 'F18F',
		category: IconCategory.Devices,
		tags: ['disk','server','box','computer'],
	});
	mDetails.set("HardDriveLock", {
		type: IconType.Wireframe,
		unicode: 'F55A',
		category: IconCategory.Devices,
		tags: ['disk','server','box','computer','locked','secure'],
	});
	mDetails.set("HardDriveUnlock", {
		type: IconType.Wireframe,
		unicode: 'F55B',
		category: IconCategory.Devices,
		tags: ['disk','server','box','computer','secure'],
	});
	mDetails.set("HazyDay", {
		type: IconType.Wireframe,
		unicode: 'E46B',
		category: undefined,
		tags: [],
	});
	mDetails.set("HazyNight", {
		type: IconType.Wireframe,
		unicode: 'E479',
		category: undefined,
		tags: [],
	});
	mDetails.set("Header", {
		type: IconType.Wireframe,
		unicode: 'F82F',
		category: IconCategory.Layout,
		tags: ['page','section'],
	});
	mDetails.set("Header1", {
		type: IconType.Wireframe,
		unicode: 'EA19',
		category: IconCategory.Formatting,
		tags: ['title'],
	});
	mDetails.set("Header2", {
		type: IconType.Wireframe,
		unicode: 'EF36',
		category: IconCategory.Formatting,
		tags: ['title'],
	});
	mDetails.set("Header3", {
		type: IconType.Wireframe,
		unicode: 'EF37',
		category: IconCategory.Formatting,
		tags: ['title'],
	});
	mDetails.set("Header4", {
		type: IconType.Wireframe,
		unicode: 'EF38',
		category: IconCategory.Formatting,
		tags: ['title'],
	});
	mDetails.set("Headset", {
		type: IconType.Wireframe,
		unicode: 'E95B',
		category: IconCategory.Devices,
		tags: ['headphones','microphone'],
	});
	mDetails.set("HeadsetSolid", {
		type: IconType.Solid,
		unicode: 'F348',
		category: IconCategory.Devices,
		tags: ['headphones','microphone'],
	});
	mDetails.set("Health", {
		type: IconType.Wireframe,
		unicode: 'E95E',
		category: IconCategory.Health,
		tags: ['heart'],
	});
	mDetails.set("HealthRefresh", {
		type: IconType.Wireframe,
		unicode: 'E3BD',
		category: IconCategory.Health,
		tags: ['heart','arrow'],
	});
	mDetails.set("HealthSolid", {
		type: IconType.Solid,
		unicode: 'F33F',
		category: IconCategory.Health,
		tags: ['heart'],
	});
	mDetails.set("Heart", {
		type: IconType.Wireframe,
		unicode: 'EB51',
		category: IconCategory.Health,
		tags: ['love','valentines'],
	});
	mDetails.set("HeartBroken", {
		type: IconType.Wireframe,
		unicode: 'EA92',
		category: IconCategory.Health,
		tags: ['sad'],
	});
	mDetails.set("HeartFill", {
		type: IconType.Solid,
		unicode: 'EB52',
		category: IconCategory.Health,
		tags: ['love','valentines'],
	});
	mDetails.set("Help", {
		type: IconType.Wireframe,
		unicode: 'E897',
		category: IconCategory.Math,
		tags: ['symbol','question mark'],
	});
	mDetails.set("HelpMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA51',
		category: IconCategory.Math,
		tags: ['symbol','question mark'],
	});
	mDetails.set("HexaditeInvestigation", {
		type: IconType.Wireframe,
		unicode: 'E3F8',
		category: IconCategory.Logos,
		tags: ['search','microsoft','azure threat protection','atp','defender'],
	});
	mDetails.set("HexaditeInvestigationCancel", {
		type: IconType.Wireframe,
		unicode: 'E3F9',
		category: IconCategory.Logos,
		tags: ['x','close','delete','remove','microsoft','azure threat protection','atp','defender'],
	});
	mDetails.set("HexaditeInvestigationSemiAuto", {
		type: IconType.Wireframe,
		unicode: 'E3FA',
		category: IconCategory.Logos,
		tags: ['user','person','contact','microsoft','azure threat protection','atp','defender'],
	});
	mDetails.set("Hexagon", {
		type: IconType.Wireframe,
		unicode: 'F4FE',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("Hide", {
		type: IconType.Wireframe,
		unicode: 'ED1A',
		category: IconCategory.Interface,
		tags: ['eye','visible','banned','blocked'],
	});
	mDetails.set("Hide2", {
		type: IconType.Wireframe,
		unicode: 'EF89',
		category: IconCategory.Interface,
		tags: ['eye','visible','banned','blocked'],
	});
	mDetails.set("Hide3", {
		type: IconType.Wireframe,
		unicode: 'F6AC',
		category: IconCategory.Interface,
		tags: ['eye','visible'],
	});
	mDetails.set("HideVisualFilter", {
		type: IconType.Wireframe,
		unicode: 'F403',
		category: undefined,
		tags: [],
	});
	mDetails.set("Highlight", {
		type: IconType.Wireframe,
		unicode: 'E7E6',
		category: IconCategory.Formatting,
		tags: ['pen','marker'],
	});
	mDetails.set("HighlightMappedShapes", {
		type: IconType.Wireframe,
		unicode: 'F2A1',
		category: IconCategory.Charts,
		tags: ['flowchart'],
	});
	mDetails.set("HintText", {
		type: IconType.Wireframe,
		unicode: 'F50F',
		category: IconCategory.Controls,
		tags: ['lightbulb','idea'],
	});
	mDetails.set("HistoricalWeather", {
		type: IconType.Wireframe,
		unicode: 'EB43',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("History", {
		type: IconType.Wireframe,
		unicode: 'E81C',
		category: IconCategory.Interface,
		tags: ['clock','loop','arrow'],
	});
	mDetails.set("Home", {
		type: IconType.Wireframe,
		unicode: 'E80F',
		category: IconCategory.Maps,
		tags: ['house','building'],
	});
	mDetails.set("HomeDropdown", {
		type: IconType.Wireframe,
		unicode: 'E427',
		category: IconCategory.Maps,
		tags: ['house','building','caret','arrow'],
	});
	mDetails.set("HomeGroup", {
		type: IconType.Wireframe,
		unicode: 'EC26',
		category: IconCategory.Stuff,
		tags: ['balls','marbles'],
	});
	mDetails.set("HomeSolid", {
		type: IconType.Solid,
		unicode: 'EA8A',
		category: IconCategory.Maps,
		tags: ['house','building'],
	});
	mDetails.set("HomeVerify", {
		type: IconType.Wireframe,
		unicode: 'F00E',
		category: IconCategory.Maps,
		tags: ['house','checkmark','approved','completed','done','building'],
	});
	mDetails.set("HorizontalDistributeCenter", {
		type: IconType.Wireframe,
		unicode: 'F4F9',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("HorizontalTabKey", {
		type: IconType.Wireframe,
		unicode: 'E7FD',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Hospital", {
		type: IconType.Wireframe,
		unicode: 'E91D',
		category: IconCategory.Health,
		tags: ['cross','bag','suitcase','luggage'],
	});
	mDetails.set("Hot", {
		type: IconType.Wireframe,
		unicode: 'ECE2',
		category: undefined,
		tags: [],
	});
	mDetails.set("Hotel", {
		type: IconType.Wireframe,
		unicode: 'E824',
		category: IconCategory.Stuff,
		tags: ['bed','pillow','night','sleep','furniture'],
	});
	mDetails.set("HourGlass", {
		type: IconType.Wireframe,
		unicode: 'EA03',
		category: IconCategory.Stuff,
		tags: ['sand','timer'],
	});
	mDetails.set("Ice", {
		type: IconType.Wireframe,
		unicode: 'E473',
		category: undefined,
		tags: [],
	});
	mDetails.set("IconSetsFlag", {
		type: IconType.Wireframe,
		unicode: 'F2A4',
		category: IconCategory.Games,
		tags: ['golf'],
	});
	mDetails.set("IDBadge", {
		type: IconType.Wireframe,
		unicode: 'F427',
		category: IconCategory.User,
		tags: ['person','contact','lanyard'],
	});
	mDetails.set("IgnoreConversation", {
		type: IconType.Wireframe,
		unicode: 'E372',
		category: IconCategory.Communication,
		tags: ['email','banned','blocked','envelope'],
	});
	mDetails.set("ImageCrosshair", {
		type: IconType.Wireframe,
		unicode: 'F2C9',
		category: IconCategory.Photography,
		tags: ['picture','target'],
	});
	mDetails.set("ImageDiff", {
		type: IconType.Wireframe,
		unicode: 'F30B',
		category: IconCategory.Photography,
		tags: ['picture','differential','merge'],
	});
	mDetails.set("ImageInAR", {
		type: IconType.Wireframe,
		unicode: 'E420',
		category: IconCategory.Photography,
		tags: ['picture','augmented reality'],
	});
	mDetails.set("ImagePixel", {
		type: IconType.Wireframe,
		unicode: 'F30C',
		category: IconCategory.Photography,
		tags: ['8-bit','picture'],
	});
	mDetails.set("ImageSearch", {
		type: IconType.Wireframe,
		unicode: 'F4E8',
		category: IconCategory.Photography,
		tags: ['picture'],
	});
	mDetails.set("Import", {
		type: IconType.Wireframe,
		unicode: 'E8B5',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("ImportAllMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA53',
		category: IconCategory.Tables,
		tags: ['arrow','right'],
	});
	mDetails.set("Important", {
		type: IconType.Wireframe,
		unicode: 'E8C9',
		category: IconCategory.Math,
		tags: ['symbol','exclamation mark','alert','warning','emphasis','not'],
	});
	mDetails.set("ImportMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA52',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("Inbox", {
		type: IconType.Wireframe,
		unicode: 'F41C',
		category: IconCategory.Communication,
		tags: ['filing'],
	});
	mDetails.set("InboxActive", {
		type: IconType.Wireframe,
		unicode: 'E497',
		category: IconCategory.Communication,
		tags: ['filing','notification'],
	});
	mDetails.set("InboxCheck", {
		type: IconType.Wireframe,
		unicode: 'EF64',
		category: IconCategory.Communication,
		tags: ['filing','checkmark','approved','complete','done'],
	});
	mDetails.set("IncidentTriangle", {
		type: IconType.Wireframe,
		unicode: 'E814',
		category: IconCategory.Stars,
		tags: ['badge','alert','warning','exclamation mark','error'],
	});
	mDetails.set("IncomingCall", {
		type: IconType.Wireframe,
		unicode: 'E77E',
		category: IconCategory.Communication,
		tags: ['arrow','telephone'],
	});
	mDetails.set("IncreaseIndent", {
		type: IconType.Wireframe,
		unicode: 'E399',
		category: IconCategory.Formatting,
		tags: ['arrow','right','tab','paragraph','text'],
	});
	mDetails.set("IncreaseIndentArrow", {
		type: IconType.Wireframe,
		unicode: 'F7A1',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("IncreaseIndentArrowMirrored", {
		type: IconType.Wireframe,
		unicode: 'F7A5',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("IncreaseIndentHanging", {
		type: IconType.Wireframe,
		unicode: 'E39D',
		category: IconCategory.Formatting,
		tags: ['arrow','right','tab','paragraph','text'],
	});
	mDetails.set("IncreaseIndentHangingMirrored", {
		type: IconType.Wireframe,
		unicode: 'E39E',
		category: IconCategory.Formatting,
		tags: ['arrow','left','tab','paragraph','text'],
	});
	mDetails.set("IncreaseIndentLegacy", {
		type: IconType.Wireframe,
		unicode: 'E291',
		category: IconCategory.Formatting,
		tags: ['arrow','right','tab','paragraph','text'],
	});
	mDetails.set("IncreaseIndentMirrored", {
		type: IconType.Wireframe,
		unicode: 'E39A',
		category: IconCategory.Formatting,
		tags: ['tab','text','paragraph','arrow','left'],
	});
	mDetails.set("IncreaseIndentText", {
		type: IconType.Wireframe,
		unicode: 'F7A0',
		category: IconCategory.Formatting,
		tags: ['paragraph','tab'],
	});
	mDetails.set("IncreaseIndentTextMirrored", {
		type: IconType.Wireframe,
		unicode: 'F7A4',
		category: IconCategory.Formatting,
		tags: ['paragraph','tab'],
	});
	mDetails.set("IndentFirstLine", {
		type: IconType.Wireframe,
		unicode: 'E3DD',
		category: IconCategory.Formatting,
		tags: ['text','paragraph','tab'],
	});
	mDetails.set("Info", {
		type: IconType.Wireframe,
		unicode: 'E946',
		category: IconCategory.Stars,
		tags: ['badge','information','letter','help'],
	});
	mDetails.set("Info2", {
		type: IconType.Wireframe,
		unicode: 'EA1F',
		category: IconCategory.Math,
		tags: ['symbol','information','letter'],
	});
	mDetails.set("InformationBarriers", {
		type: IconType.Wireframe,
		unicode: 'F803',
		category: IconCategory.Communication,
		tags: ['speech bubble','message','talk','chat','blocked','banned','mute'],
	});
	mDetails.set("InfoSolid", {
		type: IconType.Solid,
		unicode: 'F167',
		category: IconCategory.Stars,
		tags: ['badge','information','letter','help'],
	});
	mDetails.set("InkingTool", {
		type: IconType.Wireframe,
		unicode: 'E76D',
		category: IconCategory.Formatting,
		tags: ['pen','draw'],
	});
	mDetails.set("InputAddress", {
		type: IconType.Wireframe,
		unicode: 'E41E',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Insert", {
		type: IconType.Wireframe,
		unicode: 'F278',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("InsertColumnsLeft", {
		type: IconType.Wireframe,
		unicode: 'F64A',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("InsertColumnsRight", {
		type: IconType.Wireframe,
		unicode: 'F64B',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("InsertRowsAbove", {
		type: IconType.Wireframe,
		unicode: 'F64C',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("InsertRowsBelow", {
		type: IconType.Wireframe,
		unicode: 'F64D',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("InsertSignatureLine", {
		type: IconType.Wireframe,
		unicode: 'F677',
		category: IconCategory.Documents,
		tags: ['pencil','file','page'],
	});
	mDetails.set("InsertTextBox", {
		type: IconType.Wireframe,
		unicode: 'EC7D',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Insights", {
		type: IconType.Wireframe,
		unicode: 'E3AF',
		category: IconCategory.Stuff,
		tags: ['lightbulb','idea','shine'],
	});
	mDetails.set("Installation", {
		type: IconType.Wireframe,
		unicode: 'E311',
		category: IconCategory.Arrows,
		tags: ['down'],
	});
	mDetails.set("InstallToDrive", {
		type: IconType.Wireframe,
		unicode: 'F28D',
		category: IconCategory.Devices,
		tags: ['disk','server','box','computer','arrow','down'],
	});
	mDetails.set("IntermittentCloudsDay", {
		type: IconType.Wireframe,
		unicode: 'E46A',
		category: undefined,
		tags: [],
	});
	mDetails.set("IntermittentCloudsNight", {
		type: IconType.Wireframe,
		unicode: 'E478',
		category: undefined,
		tags: [],
	});
	mDetails.set("InternalInvestigation", {
		type: IconType.Wireframe,
		unicode: 'F854',
		category: IconCategory.User,
		tags: ['person','contact','lanyard','badge','search'],
	});
	mDetails.set("InternetSharing", {
		type: IconType.Wireframe,
		unicode: 'E704',
		category: IconCategory.Communication,
		tags: ['broadcast','network'],
	});
	mDetails.set("IOT", {
		type: IconType.Wireframe,
		unicode: 'F22C',
		category: IconCategory.Charts,
		tags: ['mindmap','node'],
	});
	mDetails.set("IoTSecure", {
		type: IconType.Wireframe,
		unicode: 'E4D6',
		category: undefined,
		tags: [],
	});
	mDetails.set("IRMForward", {
		type: IconType.Wireframe,
		unicode: 'F41F',
		category: IconCategory.Stars,
		tags: ['remove','collapse','arrow','right'],
	});
	mDetails.set("IRMForwardMirrored", {
		type: IconType.Wireframe,
		unicode: 'F420',
		category: IconCategory.Stars,
		tags: ['remove','collapse','arrow','left'],
	});
	mDetails.set("IRMReply", {
		type: IconType.Wireframe,
		unicode: 'F41D',
		category: IconCategory.Stars,
		tags: ['remove','collapse','arrow','left'],
	});
	mDetails.set("IRMReplyMirrored", {
		type: IconType.Wireframe,
		unicode: 'F41E',
		category: IconCategory.Stars,
		tags: ['remove','collapse','arrow','right'],
	});
	mDetails.set("IssueSolid", {
		type: IconType.Solid,
		unicode: 'F448',
		category: IconCategory.Tasks,
		tags: ['clipboard','alert','warning','exclamation mark'],
	});
	mDetails.set("IssueTracking", {
		type: IconType.Wireframe,
		unicode: 'EEC0',
		category: IconCategory.Tasks,
		tags: ['checkmark','list'],
	});
	mDetails.set("IssueTrackingMirrored", {
		type: IconType.Wireframe,
		unicode: 'EEC1',
		category: IconCategory.Tasks,
		tags: ['checkmark','list'],
	});
	mDetails.set("Italic", {
		type: IconType.Wireframe,
		unicode: 'E8DB',
		category: IconCategory.Formatting,
		tags: ['letter'],
	});
	mDetails.set("JavaScriptLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2EE',
		category: IconCategory.Development,
		tags: ['programming'],
	});
	mDetails.set("JoinOnlineMeeting", {
		type: IconType.Wireframe,
		unicode: 'ED8F',
		category: IconCategory.User,
		tags: ['globe','internet','network'],
	});
	mDetails.set("JS", {
		type: IconType.Wireframe,
		unicode: 'EBF0',
		category: IconCategory.Development,
		tags: ['programming','language','javascript'],
	});
	mDetails.set("KaizalaLogo", {
		type: IconType.Wireframe,
		unicode: 'F492',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','o365','m365','social'],
	});
	mDetails.set("KeyboardClassic", {
		type: IconType.Wireframe,
		unicode: 'E765',
		category: IconCategory.Devices,
		tags: ['type','typing','input'],
	});
	mDetails.set("KeyPhraseExtraction", {
		type: IconType.Wireframe,
		unicode: 'E395',
		category: IconCategory.Documents,
		tags: ['page','file'],
	});
	mDetails.set("KnowledgeArticle", {
		type: IconType.Wireframe,
		unicode: 'F000',
		category: IconCategory.Documents,
		tags: ['page','file','text'],
	});
	mDetails.set("Label", {
		type: IconType.Wireframe,
		unicode: 'E932',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("LadybugSolid", {
		type: IconType.Solid,
		unicode: 'F44A',
		category: IconCategory.Animals,
		tags: ['insect'],
	});
	mDetails.set("Lamp", {
		type: IconType.Wireframe,
		unicode: 'EB19',
		category: IconCategory.Stuff,
		tags: ['light','furniture'],
	});
	mDetails.set("LandscapeOrientation", {
		type: IconType.Wireframe,
		unicode: 'EF6B',
		category: IconCategory.Layout,
		tags: ['page'],
	});
	mDetails.set("LaptopSecure", {
		type: IconType.Wireframe,
		unicode: 'F552',
		category: IconCategory.Devices,
		tags: ['computer','shield'],
	});
	mDetails.set("LaptopSelected", {
		type: IconType.Wireframe,
		unicode: 'EC76',
		category: IconCategory.Devices,
		tags: ['computer','checkmark','approved'],
	});
	mDetails.set("LargeGrid", {
		type: IconType.Wireframe,
		unicode: 'EECB',
		category: IconCategory.Layout,
		tags: ['section'],
	});
	mDetails.set("LearningTools", {
		type: IconType.Wireframe,
		unicode: 'F7DB',
		category: IconCategory.Stuff,
		tags: ['book','reading','education','graduation','cap'],
	});
	mDetails.set("Leave", {
		type: IconType.Wireframe,
		unicode: 'F627',
		category: IconCategory.Interface,
		tags: ['logout','exit'],
	});
	mDetails.set("LeaveUser", {
		type: IconType.Wireframe,
		unicode: 'E3A8',
		category: IconCategory.User,
		tags: ['person','contact','arrow','left'],
	});
	mDetails.set("Library", {
		type: IconType.Wireframe,
		unicode: 'E8F1',
		category: IconCategory.Stuff,
		tags: ['books','binders'],
	});
	mDetails.set("Lifesaver", {
		type: IconType.Wireframe,
		unicode: 'EF62',
		category: IconCategory.Health,
		tags: ['preserver'],
	});
	mDetails.set("LifesaverLock", {
		type: IconType.Wireframe,
		unicode: 'EF63',
		category: IconCategory.Health,
		tags: ['preserver','secure','locked'],
	});
	mDetails.set("Light", {
		type: IconType.Wireframe,
		unicode: 'E793',
		category: IconCategory.Photography,
		tags: ['brightness','contrast'],
	});
	mDetails.set("Lightbulb", {
		type: IconType.Wireframe,
		unicode: 'EA80',
		category: IconCategory.Stuff,
		tags: ['idea'],
	});
	mDetails.set("LightningBolt", {
		type: IconType.Wireframe,
		unicode: 'E945',
		category: IconCategory.Weather,
		tags: ['zap','azure functions','electricity','zeus'],
	});
	mDetails.set("LightningBoltSolid", {
		type: IconType.Solid,
		unicode: 'E45F',
		category: IconCategory.Weather,
		tags: ['zap','azure functions','electricity','zeus'],
	});
	mDetails.set("LightningSecure", {
		type: IconType.Wireframe,
		unicode: 'E4D3',
		category: undefined,
		tags: [],
	});
	mDetails.set("LightSnow", {
		type: IconType.Wireframe,
		unicode: 'EA02',
		category: undefined,
		tags: [],
	});
	mDetails.set("LightWeight", {
		type: IconType.Wireframe,
		unicode: 'F4EE',
		category: IconCategory.Formatting,
		tags: ['bold'],
	});
	mDetails.set("Like", {
		type: IconType.Wireframe,
		unicode: 'E8E1',
		category: IconCategory.Interface,
		tags: ['thumbs up','social'],
	});
	mDetails.set("LikeSolid", {
		type: IconType.Solid,
		unicode: 'F3BF',
		category: IconCategory.Interface,
		tags: ['thumbs up','social'],
	});
	mDetails.set("Line", {
		type: IconType.Wireframe,
		unicode: 'F4FC',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("LineChart", {
		type: IconType.Wireframe,
		unicode: 'E9E6',
		category: IconCategory.Charts,
		tags: ['trending'],
	});
	mDetails.set("LineSpacing", {
		type: IconType.Wireframe,
		unicode: 'F517',
		category: IconCategory.Formatting,
		tags: ['arrow','up','down','text'],
	});
	mDetails.set("LineStyle", {
		type: IconType.Wireframe,
		unicode: 'F50C',
		category: IconCategory.Formatting,
		tags: ['dotted','solid','weight'],
	});
	mDetails.set("LineThickness", {
		type: IconType.Wireframe,
		unicode: 'F50D',
		category: IconCategory.Formatting,
		tags: ['weight'],
	});
	mDetails.set("Link", {
		type: IconType.Wireframe,
		unicode: 'E71B',
		category: IconCategory.Formatting,
		tags: ['chain','hyperlink','anchor'],
	});
	mDetails.set("Link12", {
		type: IconType.Wireframe,
		unicode: 'F6E3',
		category: IconCategory.Formatting,
		tags: ['chain','hyperlink','anchor'],
	});
	mDetails.set("LinkedDatabase", {
		type: IconType.Wireframe,
		unicode: 'F779',
		category: IconCategory.Tables,
		tags: ['chain'],
	});
	mDetails.set("LinkedInLogo", {
		type: IconType.Wireframe,
		unicode: 'F20A',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','social'],
	});
	mDetails.set("List", {
		type: IconType.Wireframe,
		unicode: 'EA37',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("ListMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA55',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("LiveSite", {
		type: IconType.Wireframe,
		unicode: 'F6A6',
		category: IconCategory.Documents,
		tags: ['page','file','globe'],
	});
	mDetails.set("LocalAdmin", {
		type: IconType.Wireframe,
		unicode: 'F1FB',
		category: undefined,
		tags: [],
	});
	mDetails.set("LocaleLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2B7',
		category: IconCategory.Math,
		tags: ['symbol','globe','translation'],
	});
	mDetails.set("Location", {
		type: IconType.Wireframe,
		unicode: 'E81D',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("LocationCircle", {
		type: IconType.Wireframe,
		unicode: 'E80E',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("LocationDot", {
		type: IconType.Wireframe,
		unicode: 'E827',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("LocationFill", {
		type: IconType.Colorfill,
		unicode: 'E920',
		category: IconCategory.Maps,
		tags: ['circle'],
	});
	mDetails.set("LocationOutline", {
		type: IconType.Wireframe,
		unicode: 'F2D0',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("Lock", {
		type: IconType.Wireframe,
		unicode: 'E72E',
		category: IconCategory.Security,
		tags: ['locked'],
	});
	mDetails.set("Lock12", {
		type: IconType.Wireframe,
		unicode: 'F6E6',
		category: IconCategory.Security,
		tags: ['locked'],
	});
	mDetails.set("LockShare", {
		type: IconType.Wireframe,
		unicode: 'E455',
		category: IconCategory.Security,
		tags: ['locked','arrow','right'],
	});
	mDetails.set("LockSolid", {
		type: IconType.Solid,
		unicode: 'E9A2',
		category: IconCategory.Security,
		tags: ['locked'],
	});
	mDetails.set("LogRemove", {
		type: IconType.Wireframe,
		unicode: 'F316',
		category: IconCategory.Charts,
		tags: ['x','clear','delete','cancel'],
	});
	mDetails.set("LookupEntities", {
		type: IconType.Wireframe,
		unicode: 'F5B5',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("LowerBrightness", {
		type: IconType.Wireframe,
		unicode: 'EC8A',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("LyncLogo", {
		type: IconType.Wireframe,
		unicode: 'ED79',
		category: IconCategory.Logos,
		tags: ['brand','microsoft'],
	});
	mDetails.set("M365InvoicingLogo", {
		type: IconType.Wireframe,
		unicode: 'F7C1',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','o365','billing','receipt'],
	});
	mDetails.set("MachineLearning", {
		type: IconType.Wireframe,
		unicode: 'E3B8',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Mail", {
		type: IconType.Wireframe,
		unicode: 'E715',
		category: IconCategory.Communication,
		tags: ['email','envelope'],
	});
	mDetails.set("MailAlert", {
		type: IconType.Wireframe,
		unicode: 'ED80',
		category: IconCategory.Communication,
		tags: ['email','envelope','warning','exclamation'],
	});
	mDetails.set("MailAttached", {
		type: IconType.Wireframe,
		unicode: 'F774',
		category: IconCategory.Communication,
		tags: ['email','envelope','attachment','paperclip'],
	});
	mDetails.set("MailCheck", {
		type: IconType.Wireframe,
		unicode: 'ED81',
		category: IconCategory.Communication,
		tags: ['email','envelope','checkmark','sent'],
	});
	mDetails.set("MailFill", {
		type: IconType.Colorfill,
		unicode: 'E8A8',
		category: IconCategory.Communication,
		tags: ['email','envelope','rectangle'],
	});
	mDetails.set("MailForward", {
		type: IconType.Wireframe,
		unicode: 'E89C',
		category: IconCategory.Communication,
		tags: ['email','envelope','send','right','arrow'],
	});
	mDetails.set("MailForwardMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA56',
		category: IconCategory.Communication,
		tags: ['email','envelope','send','left','arrow'],
	});
	mDetails.set("MailLink", {
		type: IconType.Wireframe,
		unicode: 'EFAC',
		category: undefined,
		tags: [],
	});
	mDetails.set("MailLowImportance", {
		type: IconType.Wireframe,
		unicode: 'ED82',
		category: IconCategory.Communication,
		tags: ['email','envelope','arrow','down'],
	});
	mDetails.set("MailOptions", {
		type: IconType.Wireframe,
		unicode: 'F82C',
		category: IconCategory.Communication,
		tags: ['email','envelope','gear','settings','configure'],
	});
	mDetails.set("MailPause", {
		type: IconType.Wireframe,
		unicode: 'ED83',
		category: IconCategory.Communication,
		tags: ['email','envelope'],
	});
	mDetails.set("MailReminder", {
		type: IconType.Wireframe,
		unicode: 'F418',
		category: IconCategory.Communication,
		tags: ['email','envelope','alert','notification','bell'],
	});
	mDetails.set("MailRepeat", {
		type: IconType.Wireframe,
		unicode: 'ED84',
		category: IconCategory.Communication,
		tags: ['email','envelope','refresh','synchronize'],
	});
	mDetails.set("MailReply", {
		type: IconType.Wireframe,
		unicode: 'E8CA',
		category: IconCategory.Communication,
		tags: ['email','envelope','arrow','left'],
	});
	mDetails.set("MailReplyAll", {
		type: IconType.Wireframe,
		unicode: 'E8C2',
		category: IconCategory.Communication,
		tags: ['email','envelope','arrow','left'],
	});
	mDetails.set("MailReplyAllMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA58',
		category: IconCategory.Communication,
		tags: ['email','envelope','arrow','right'],
	});
	mDetails.set("MailReplyMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA57',
		category: IconCategory.Communication,
		tags: ['email','envelope','arrow','right'],
	});
	mDetails.set("MailSchedule", {
		type: IconType.Wireframe,
		unicode: 'F72E',
		category: IconCategory.Communication,
		tags: ['email','envelope','clock','time'],
	});
	mDetails.set("MailSecure", {
		type: IconType.Wireframe,
		unicode: 'E4D4',
		category: IconCategory.Communication,
		tags: ['email','envelope'],
	});
	mDetails.set("MailSolid", {
		type: IconType.Solid,
		unicode: 'F343',
		category: IconCategory.Communication,
		tags: ['email','envelope'],
	});
	mDetails.set("MailTentative", {
		type: IconType.Wireframe,
		unicode: 'F416',
		category: IconCategory.Communication,
		tags: ['email','envelope','question mark'],
	});
	mDetails.set("MailTentativeMirrored", {
		type: IconType.Wireframe,
		unicode: 'F417',
		category: IconCategory.Communication,
		tags: ['email','envelope', 'question mark'],
	});
	mDetails.set("MailUndelivered", {
		type: IconType.Wireframe,
		unicode: 'F415',
		category: IconCategory.Communication,
		tags: ['email','envelope','cancel','delete','close'],
	});
	mDetails.set("ManagerSelfService", {
		type: IconType.Wireframe,
		unicode: 'EE23',
		category: IconCategory.User,
		tags: ['person','contact','people','folder'],
	});
	mDetails.set("Manufacturing", {
		type: IconType.Wireframe,
		unicode: 'E99C',
		category: IconCategory.Stuff,
		tags: ['crane','digger','construction','tool'],
	});
	mDetails.set("MapDirections", {
		type: IconType.Wireframe,
		unicode: 'E816',
		category: IconCategory.Maps,
		tags: ['sign','arrow','right'],
	});
	mDetails.set("MapLayers", {
		type: IconType.Wireframe,
		unicode: 'E81E',
		category: IconCategory.Maps,
		tags: ['stack'],
	});
	mDetails.set("MapPin", {
		type: IconType.Wireframe,
		unicode: 'E707',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("MapPin12", {
		type: IconType.Wireframe,
		unicode: 'E3AE',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("MapPinSolid", {
		type: IconType.Solid,
		unicode: 'F52E',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("MarkAsProtected", {
		type: IconType.Wireframe,
		unicode: 'F6AE',
		category: IconCategory.Security,
		tags: ['page','stamp'],
	});
	mDetails.set("MarkDownLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2FB',
		category: IconCategory.Development,
		tags: ['arrow','down','letter'],
	});
	mDetails.set("Market", {
		type: IconType.Wireframe,
		unicode: 'EAFC',
		category: IconCategory.Arrows,
		tags: ['trending','stock','up'],
	});
	mDetails.set("MarketDown", {
		type: IconType.Wireframe,
		unicode: 'EF42',
		category: IconCategory.Arrows,
		tags: ['trending','stock'],
	});
	mDetails.set("MasterDatabase", {
		type: IconType.Wireframe,
		unicode: 'F5BA',
		category: IconCategory.Tables,
		tags: ['crown','king','queen'],
	});
	mDetails.set("MaximumValue", {
		type: IconType.Wireframe,
		unicode: 'F5BC',
		category: IconCategory.Arrows,
		tags: ['expand','parallel'],
	});
	mDetails.set("Medal", {
		type: IconType.Wireframe,
		unicode: 'EE38',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("MedalSolid", {
		type: IconType.Solid,
		unicode: 'F6B9',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("Media", {
		type: IconType.Wireframe,
		unicode: 'EA69',
		category: IconCategory.Stuff,
		tags: ['music','video','film'],
	});
	mDetails.set("MediaAdd", {
		type: IconType.Wireframe,
		unicode: 'F510',
		category: IconCategory.Photography,
		tags: ['plus','new','picture','film','video'],
	});
	mDetails.set("Medical", {
		type: IconType.Wireframe,
		unicode: 'EAD4',
		category: IconCategory.Health,
		tags: ['stethoscope','doctor','checkup'],
	});
	mDetails.set("Megaphone", {
		type: IconType.Wireframe,
		unicode: 'E789',
		category: IconCategory.Communication,
		tags: ['shout','announcement','cheerleader','notification'],
	});
	mDetails.set("MegaphoneSolid", {
		type: IconType.Solid,
		unicode: 'F332',
		category: IconCategory.Communication,
		tags: ['shout','announcement','cheerleader','notification'],
	});
	mDetails.set("Memo", {
		type: IconType.Wireframe,
		unicode: 'E77C',
		category: IconCategory.Communication,
		tags: ['voice','recording','tape'],
	});
	mDetails.set("Merge", {
		type: IconType.Wireframe,
		unicode: 'E7D5',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("MergeDuplicate", {
		type: IconType.Wireframe,
		unicode: 'F29A',
		category: IconCategory.Tables,
		tags: ['arrow','up'],
	});
	mDetails.set("Message", {
		type: IconType.Wireframe,
		unicode: 'E8BD',
		category: IconCategory.Communication,
		tags: ['talk','speech bubble','chat'],
	});
	mDetails.set("MessageFill", {
		type: IconType.Solid,
		unicode: 'EC70',
		category: IconCategory.Communication,
		tags: ['talk','speech bubble','chat'],
	});
	mDetails.set("MessageFriendRequest", {
		type: IconType.Wireframe,
		unicode: 'F055',
		category: IconCategory.User,
		tags: ['email','envelope','person','contact'],
	});
	mDetails.set("MetricsFailure", {
		type: IconType.Wireframe,
		unicode: 'E4CE',
		category: undefined,
		tags: [],
	});
	mDetails.set("MetricsInstall", {
		type: IconType.Wireframe,
		unicode: 'E4CD',
		category: undefined,
		tags: [],
	});
	mDetails.set("MetricsUsage", {
		type: IconType.Wireframe,
		unicode: 'E4CC',
		category: undefined,
		tags: [],
	});
	mDetails.set("MicOff", {
		type: IconType.Wireframe,
		unicode: 'EC54',
		category: IconCategory.Devices,
		tags: ['record','radio','podcast','banned','blocked'],
	});
	mDetails.set("MicOff2", {
		type: IconType.Wireframe,
		unicode: 'F781',
		category: IconCategory.Devices,
		tags: ['record','radio','podcast'],
	});
	mDetails.set("Microphone", {
		type: IconType.Wireframe,
		unicode: 'E720',
		category: IconCategory.Devices,
		tags: ['record','radio','podcast'],
	});
	mDetails.set("MicrosoftFlowLogo", {
		type: IconType.Wireframe,
		unicode: 'F4B1',
		category: IconCategory.Logos,
		tags: ['brand','power automate','m365','o365'],
	});
	mDetails.set("MicrosoftStaffhubLogo", {
		type: IconType.Wireframe,
		unicode: 'F130',
		category: IconCategory.Logos,
		tags: ['brand','o365','m365'],
	});
	mDetails.set("MicrosoftTranslatorLogo", {
		type: IconType.Wireframe,
		unicode: 'F782',
		category: IconCategory.Logos,
		tags: ['brand','translate'],
	});
	mDetails.set("MicrosoftTranslatorLogoBlue", {
		type: IconType.Colorfill,
		unicode: 'F853',
		category: IconCategory.Logos,
		tags: ['brand','translate'],
	});
	mDetails.set("MicrosoftTranslatorLogoGreen", {
		type: IconType.Colorfill,
		unicode: 'F852',
		category: IconCategory.Logos,
		tags: ['brand','translate'],
	});
	mDetails.set("MiniContract", {
		type: IconType.Wireframe,
		unicode: 'E93B',
		category: IconCategory.Layout,
		tags: ['arrow'],
	});
	mDetails.set("MiniContractMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA59',
		category: IconCategory.Layout,
		tags: ['arrow'],
	});
	mDetails.set("MiniExpand", {
		type: IconType.Wireframe,
		unicode: 'E93A',
		category: IconCategory.Layout,
		tags: ['arrow'],
	});
	mDetails.set("MiniExpandMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA5A',
		category: IconCategory.Layout,
		tags: ['arrow'],
	});
	mDetails.set("MiniLink", {
		type: IconType.Wireframe,
		unicode: 'E732',
		category: IconCategory.Formatting,
		tags: ['chain','hyperlink','anchor'],
	});
	mDetails.set("MinimumValue", {
		type: IconType.Wireframe,
		unicode: 'F5BD',
		category: IconCategory.Arrows,
		tags: ['parallel','collapse'],
	});
	mDetails.set("MobileAngled", {
		type: IconType.Wireframe,
		unicode: 'E463',
		category: IconCategory.Devices,
		tags: ['smart phone','hand'],
	});
	mDetails.set("MobileReport", {
		type: IconType.Wireframe,
		unicode: 'F18A',
		category: IconCategory.Charts,
		tags: ['phone','cell','bar'],
	});
	mDetails.set("MobileSelected", {
		type: IconType.Wireframe,
		unicode: 'EC75',
		category: IconCategory.Devices,
		tags: ['smart phone','checkmark'],
	});
	mDetails.set("ModelingView", {
		type: IconType.Wireframe,
		unicode: 'F871',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("Money", {
		type: IconType.Wireframe,
		unicode: 'EAFD',
		category: IconCategory.Stuff,
		tags: ['bills','dollars','cash','euros'],
	});
	mDetails.set("More", {
		type: IconType.Wireframe,
		unicode: 'E712',
		category: IconCategory.Controls,
		tags: ['dots'],
	});
	mDetails.set("MoreSports", {
		type: IconType.Wireframe,
		unicode: 'EB22',
		category: IconCategory.Games,
		tags: ['athletics','football','basketball'],
	});
	mDetails.set("MoreVertical", {
		type: IconType.Wireframe,
		unicode: 'F2BC',
		category: IconCategory.Controls,
		tags: ['dots'],
	});
	mDetails.set("MostyClearNight", {
		type: IconType.Wireframe,
		unicode: 'E476',
		category: undefined,
		tags: [],
	});
	mDetails.set("MostyCloudyFlurriesDay", {
		type: IconType.Wireframe,
		unicode: 'E471',
		category: undefined,
		tags: [],
	});
	mDetails.set("MostyCloudyFlurriesNight", {
		type: IconType.Wireframe,
		unicode: 'E47D',
		category: undefined,
		tags: [],
	});
	mDetails.set("MostyCloudyShowersDay", {
		type: IconType.Wireframe,
		unicode: 'E46D',
		category: undefined,
		tags: [],
	});
	mDetails.set("MostyCloudyTStormsDay", {
		type: IconType.Wireframe,
		unicode: 'E46F',
		category: undefined,
		tags: [],
	});
	mDetails.set("MostyCloudyTStormsNight", {
		type: IconType.Wireframe,
		unicode: 'E47B',
		category: undefined,
		tags: [],
	});
	mDetails.set("MostySunnyDay", {
		type: IconType.Wireframe,
		unicode: 'E468',
		category: undefined,
		tags: [],
	});
	mDetails.set("MountainClimbing", {
		type: IconType.Wireframe,
		unicode: 'F6DB',
		category: IconCategory.Games,
		tags: ['sports','athletics','flag','peak','snow','hills'],
	});
	mDetails.set("Move", {
		type: IconType.Wireframe,
		unicode: 'E7C2',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Movers", {
		type: IconType.Wireframe,
		unicode: 'EBCD',
		category: IconCategory.Arrows,
		tags: ['up','circle'],
	});
	mDetails.set("MoveToFolder", {
		type: IconType.Wireframe,
		unicode: 'E8DE',
		category: IconCategory.Folders,
		tags: ['arrow','left'],
	});
	mDetails.set("MSNLogo", {
		type: IconType.Wireframe,
		unicode: 'EB6C',
		category: IconCategory.Logos,
		tags: ['microsoft','brand'],
	});
	mDetails.set("MSNVideos", {
		type: IconType.Wireframe,
		unicode: 'EB1C',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','news'],
	});
	mDetails.set("MSNVideosSolid", {
		type: IconType.Solid,
		unicode: 'F2DA',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','news'],
	});
	mDetails.set("MSNVolume", {
		type: IconType.Wireframe,
		unicode: 'EB15',
		category: undefined,
		tags: [],
	});
	mDetails.set("MultiSelect", {
		type: IconType.Wireframe,
		unicode: 'E762',
		category: IconCategory.Controls,
		tags: ['checkmark'],
	});
	mDetails.set("MultiSelectMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA98',
		category: IconCategory.Controls,
		tags: ['checkmark'],
	});
	mDetails.set("MusicInCollection", {
		type: IconType.Wireframe,
		unicode: 'E940',
		category: IconCategory.Stuff,
		tags: ['notes','play'],
	});
	mDetails.set("MusicInCollectionFill", {
		type: IconType.Solid,
		unicode: 'EA36',
		category: IconCategory.Stuff,
		tags: ['notes','play'],
	});
	mDetails.set("MusicNote", {
		type: IconType.Wireframe,
		unicode: 'EC4F',
		category: IconCategory.Stuff,
		tags: ['play'],
	});
	mDetails.set("MuteChat", {
		type: IconType.Wireframe,
		unicode: 'F17A',
		category: IconCategory.Communication,
		tags: ['speech bubble','banned','blocked','message','talk'],
	});
	mDetails.set("MyMoviesTV", {
		type: IconType.Wireframe,
		unicode: 'EE6C',
		category: IconCategory.Stuff,
		tags: ['action','clap','board'],
	});
	mDetails.set("MyNetwork", {
		type: IconType.Wireframe,
		unicode: 'EC27',
		category: IconCategory.Devices,
		tags: ['computer','monitor','screen','globe','internet','lan','wan'],
	});
	mDetails.set("Nav2DMapView", {
		type: IconType.Wireframe,
		unicode: 'E800',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("NavigateBack", {
		type: IconType.Wireframe,
		unicode: 'F2DD',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("NavigateBackMirrored", {
		type: IconType.Wireframe,
		unicode: 'F2DE',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("NavigateExternalInline", {
		type: IconType.Wireframe,
		unicode: 'F35F',
		category: IconCategory.Interface,
		tags: ['link','arrow'],
	});
	mDetails.set("NavigateForward", {
		type: IconType.Wireframe,
		unicode: 'F2DF',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("NavigateForwardMirrored", {
		type: IconType.Wireframe,
		unicode: 'F2E0',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("NavigationFlipper", {
		type: IconType.Wireframe,
		unicode: 'F51D',
		category: IconCategory.Controls,
		tags: ['caret','right','arrow'],
	});
	mDetails.set("NetworkTower", {
		type: IconType.Wireframe,
		unicode: 'EC05',
		category: IconCategory.Devices,
		tags: ['phones','signal','wifi','broadcast'],
	});
	mDetails.set("NewAnalyticsQuery", {
		type: IconType.Wireframe,
		unicode: 'F1E0',
		category: IconCategory.Tables,
		tags: ['plus','add'],
	});
	mDetails.set("NewFolder", {
		type: IconType.Wireframe,
		unicode: 'E8F4',
		category: IconCategory.Folders,
		tags: ['plus','add'],
	});
	mDetails.set("NewMail", {
		type: IconType.Wireframe,
		unicode: 'F7EA',
		category: IconCategory.Communication,
		tags: ['email','envelope','plus','add'],
	});
	mDetails.set("News", {
		type: IconType.Wireframe,
		unicode: 'E900',
		category: IconCategory.Stuff,
		tags: ['newspaper','articles','announcement','blog','publication'],
	});
	mDetails.set("NewsSearch", {
		type: IconType.Wireframe,
		unicode: 'F4E9',
		category: IconCategory.Stuff,
		tags: ['newspaper','articles','announcement','blog','publication'],
	});
	mDetails.set("NewTeamProject", {
		type: IconType.Wireframe,
		unicode: 'F2B2',
		category: IconCategory.Folders,
		tags: ['plus','add'],
	});
	mDetails.set("Next", {
		type: IconType.Wireframe,
		unicode: 'E893',
		category: IconCategory.Media,
		tags: [],
	});
	mDetails.set("NormalWeight", {
		type: IconType.Wireframe,
		unicode: 'F4EF',
		category: IconCategory.Formatting,
		tags: ['bold','letter'],
	});
	mDetails.set("NoteForward", {
		type: IconType.Wireframe,
		unicode: 'ED99',
		category: IconCategory.Tasks,
		tags: ['arrow','right'],
	});
	mDetails.set("NotePinned", {
		type: IconType.Wireframe,
		unicode: 'ED9A',
		category: IconCategory.Tasks,
		tags: ['thumbtack'],
	});
	mDetails.set("NoteReply", {
		type: IconType.Wireframe,
		unicode: 'ED98',
		category: IconCategory.Tasks,
		tags: ['arrow','left'],
	});
	mDetails.set("NotExecuted", {
		type: IconType.Wireframe,
		unicode: 'F440',
		category: IconCategory.Development,
		tags: ['banned','blocked'],
	});
	mDetails.set("NotImpactedSolid", {
		type: IconType.Solid,
		unicode: 'F441',
		category: IconCategory.Stuff,
		tags: ['gavel','banned','blocked'],
	});
	mDetails.set("NugetLogo", {
		type: IconType.Wireframe,
		unicode: 'F44C',
		category: IconCategory.Logos,
		tags: ['microsoft','brand'],
	});
	mDetails.set("NUIFace", {
		type: IconType.Wireframe,
		unicode: 'EB68',
		category: IconCategory.Emoji,
		tags: ['smile'],
	});
	mDetails.set("Number", {
		type: IconType.Wireframe,
		unicode: 'F691',
		category: IconCategory.Math,
		tags: ['1','circle'],
	});
	mDetails.set("NumberedList", {
		type: IconType.Wireframe,
		unicode: 'EA1C',
		category: IconCategory.Formatting,
		tags: ['ordered','text','paragraph'],
	});
	mDetails.set("NumberedListMirrored", {
		type: IconType.Wireframe,
		unicode: 'E398',
		category: IconCategory.Formatting,
		tags: ['ordered','text','paragraph'],
	});
	mDetails.set("NumberedListNumber", {
		type: IconType.Colorfill,
		unicode: 'F797',
		category: IconCategory.Formatting,
		tags: ['ordered','text','paragraph'],
	});
	mDetails.set("NumberedListNumberMirrored", {
		type: IconType.Colorfill,
		unicode: 'F799',
		category: IconCategory.Formatting,
		tags: ['ordered','text','paragraph'],
	});
	mDetails.set("NumberedListText", {
		type: IconType.Colorfill,
		unicode: 'F796',
		category: IconCategory.Formatting,
		tags: ['ordered','text','paragraph'],
	});
	mDetails.set("NumberedListTextMirrored", {
		type: IconType.Colorfill,
		unicode: 'F798',
		category: IconCategory.Formatting,
		tags: ['ordered','text','paragraph'],
	});
	mDetails.set("NumberField", {
		type: IconType.Wireframe,
		unicode: 'EDC4',
		category: IconCategory.Controls,
		tags: ['input'],
	});
	mDetails.set("NumberSequence", {
		type: IconType.Wireframe,
		unicode: 'F52A',
		category: IconCategory.Math,
		tags: [],
	});
	mDetails.set("NumberSymbol", {
		type: IconType.Wireframe,
		unicode: 'F7AC',
		category: IconCategory.Math,
		tags: ['hashtag','octothorpe','pound'],
	});
	mDetails.set("Octagon", {
		type: IconType.Wireframe,
		unicode: 'F4FD',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("ODLink", {
		type: IconType.Wireframe,
		unicode: 'E4BB',
		category: undefined,
		tags: [],
	});
	mDetails.set("ODLink12", {
		type: IconType.Wireframe,
		unicode: 'E4BC',
		category: undefined,
		tags: [],
	});
	mDetails.set("OEM", {
		type: IconType.Wireframe,
		unicode: 'E74C',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("OfficeAddinsLogo", {
		type: IconType.Wireframe,
		unicode: 'EEC7',
		category: IconCategory.Logos,
		tags: ['microsoft','o365','m365'],
	});
	mDetails.set("OfficeAssistantLogo", {
		type: IconType.Wireframe,
		unicode: 'EDCE',
		category: IconCategory.Logos,
		tags: ['microsoft','o365','m365'],
	});
	mDetails.set("OfficeCatchUp", {
		type: IconType.Wireframe,
		unicode: 'E490',
		category: undefined,
		tags: [],
	});
	mDetails.set("OfficeChat", {
		type: IconType.Wireframe,
		unicode: 'F70F',
		category: IconCategory.Communication,
		tags: ['message','speech bubble','talk'],
	});
	mDetails.set("OfficeChatSolid", {
		type: IconType.Solid,
		unicode: 'F710',
		category: IconCategory.Communication,
		tags: ['message','speech bubble','talk'],
	});
	mDetails.set("OfficeFormsLogo", {
		type: IconType.Wireframe,
		unicode: 'F434',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeFormsLogo16", {
		type: IconType.Wireframe,
		unicode: 'F436',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeFormsLogo24", {
		type: IconType.Wireframe,
		unicode: 'F43B',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeFormsLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EF86',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeFormsLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F433',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeFormsLogoInverse24", {
		type: IconType.Wireframe,
		unicode: 'F43A',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeLogo", {
		type: IconType.Wireframe,
		unicode: 'EB6E',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeStoreLogo", {
		type: IconType.Wireframe,
		unicode: 'EDCF',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365','bag'],
	});
	mDetails.set("OfficeVideoLogo", {
		type: IconType.Wireframe,
		unicode: 'F282',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeVideoLogoFill", {
		type: IconType.Colorfill,
		unicode: 'F283',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfficeVideoLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'ED7A',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365'],
	});
	mDetails.set("OfflineOneDriveParachute", {
		type: IconType.Wireframe,
		unicode: 'EEC8',
		category: IconCategory.Stuff,
		tags: ['skydive','delivery','drop'],
	});
	mDetails.set("OfflineOneDriveParachuteDisabled", {
		type: IconType.Wireframe,
		unicode: 'EEC9',
		category: IconCategory.Stuff,
		tags: ['skydive','delivery','drop'],
	});
	mDetails.set("OfflineStorage", {
		type: IconType.Wireframe,
		unicode: 'EC8C',
		category: IconCategory.Tables,
		tags: ['database'],
	});
	mDetails.set("OfflineStorageSolid", {
		type: IconType.Solid,
		unicode: 'F34E',
		category: IconCategory.Tables,
		tags: ['database'],
	});
	mDetails.set("Onboarding", {
		type: IconType.Wireframe,
		unicode: 'F3BA',
		category: IconCategory.User,
		tags: ['arrow','right','person','contact'],
	});
	mDetails.set("OneDriveAdd", {
		type: IconType.Wireframe,
		unicode: 'EF32',
		category: IconCategory.Stuff,
		tags: ['microsoft','plus','new','clouds'],
	});
	mDetails.set("OneDriveFolder16", {
		type: IconType.Wireframe,
		unicode: 'F53B',
		category: IconCategory.Folders,
		tags: ['logo','cloud'],
	});
	mDetails.set("OneDriveLogo", {
		type: IconType.Wireframe,
		unicode: 'E941',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','o365','cloud'],
	});
	mDetails.set("OneNoteEduLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EDD0',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','education','o365','m365'],
	});
	mDetails.set("OneNoteLogo", {
		type: IconType.Wireframe,
		unicode: 'F1E7',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365'],
	});
	mDetails.set("OneNoteLogo16", {
		type: IconType.Wireframe,
		unicode: 'F39A',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365'],
	});
	mDetails.set("OneNoteLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EC0D',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365'],
	});
	mDetails.set("OneNoteLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F399',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365'],
	});
	mDetails.set("OpenEnrollment", {
		type: IconType.Wireframe,
		unicode: 'EF1C',
		category: IconCategory.Tables,
		tags: ['pencil','edit'],
	});
	mDetails.set("OpenFile", {
		type: IconType.Wireframe,
		unicode: 'E8E5',
		category: IconCategory.Documents,
		tags: ['arrow','up'],
	});
	mDetails.set("OpenFolderHorizontal", {
		type: IconType.Wireframe,
		unicode: 'ED25',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("OpenInNewTab", {
		type: IconType.Wireframe,
		unicode: 'F6AB',
		category: IconCategory.Interface,
		tags: ['link','arrow'],
	});
	mDetails.set("OpenInNewWindow", {
		type: IconType.Wireframe,
		unicode: 'E8A7',
		category: IconCategory.Interface,
		tags: ['link','arrow'],
	});
	mDetails.set("OpenPane", {
		type: IconType.Wireframe,
		unicode: 'E8A0',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("OpenPaneMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA5B',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("OpenSource", {
		type: IconType.Wireframe,
		unicode: 'EBC2',
		category: IconCategory.Development,
		tags: ['nodes'],
	});
	mDetails.set("OpenWith", {
		type: IconType.Wireframe,
		unicode: 'E7AC',
		category: IconCategory.Development,
		tags: ['arrow','up'],
	});
	mDetails.set("OpenWithMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA5C',
		category: IconCategory.Development,
		tags: ['arrow','up'],
	});
	mDetails.set("OrderLock", {
		type: IconType.Wireframe,
		unicode: 'E4CB',
		category: IconCategory.Documents,
		tags: ['locked','secure'],
	});
	mDetails.set("Org", {
		type: IconType.Wireframe,
		unicode: 'ECA6',
		category: IconCategory.Charts,
		tags: ['organization','hierarchy','employees'],
	});
	mDetails.set("Orientation", {
		type: IconType.Wireframe,
		unicode: 'E8B4',
		category: IconCategory.Layout,
		tags: ['rotate','horizontal','arrow','turn'],
	});
	mDetails.set("Orientation2", {
		type: IconType.Wireframe,
		unicode: 'F7E1',
		category: IconCategory.Layout,
		tags: ['rotate','vertical','arrow','turn'],
	});
	mDetails.set("OutlookLogo", {
		type: IconType.Wireframe,
		unicode: 'F1E9',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365','email'],
	});
	mDetails.set("OutlookLogo16", {
		type: IconType.Wireframe,
		unicode: 'F39D',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365','email'],
	});
	mDetails.set("OutlookLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EB6D',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365','email'],
	});
	mDetails.set("OutlookLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F39C',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365','email'],
	});
	mDetails.set("OutlookSpacesBucket", {
		type: IconType.Wireframe,
		unicode: 'E481',
		category: undefined,
		tags: [],
	});
	mDetails.set("OutOfOffice", {
		type: IconType.Wireframe,
		unicode: 'ED34',
		category: IconCategory.Communication,
		tags: ['door','arrow','left','ooto','exit'],
	});
	mDetails.set("Package", {
		type: IconType.Wireframe,
		unicode: 'E7B8',
		category: IconCategory.Stuff,
		tags: ['box','storage','delivery','files','folder'],
	});
	mDetails.set("Packages", {
		type: IconType.Wireframe,
		unicode: 'F318',
		category: IconCategory.Stuff,
		tags: ['box','storage','delivery','files','folder'],
	});
	mDetails.set("Padding", {
		type: IconType.Wireframe,
		unicode: 'F518',
		category: IconCategory.Formatting,
		tags: ['arrow','up','down'],
	});
	mDetails.set("PaddingBottom", {
		type: IconType.Wireframe,
		unicode: 'F51A',
		category: IconCategory.Formatting,
		tags: ['arrow','down'],
	});
	mDetails.set("PaddingLeft", {
		type: IconType.Wireframe,
		unicode: 'F51B',
		category: IconCategory.Formatting,
		tags: ['arrow'],
	});
	mDetails.set("PaddingRight", {
		type: IconType.Wireframe,
		unicode: 'F51C',
		category: IconCategory.Formatting,
		tags: ['arrow'],
	});
	mDetails.set("PaddingTop", {
		type: IconType.Wireframe,
		unicode: 'F519',
		category: IconCategory.Formatting,
		tags: ['arrow','top'],
	});
	mDetails.set("Page", {
		type: IconType.Wireframe,
		unicode: 'E7C3',
		category: IconCategory.Documents,
		tags: ['file'],
	});
	mDetails.set("PageAdd", {
		type: IconType.Wireframe,
		unicode: 'EA1A',
		category: IconCategory.Documents,
		tags: ['file','plus','new'],
	});
	mDetails.set("PageArrowRight", {
		type: IconType.Wireframe,
		unicode: 'EFB8',
		category: IconCategory.Documents,
		tags: ['file'],
	});
	mDetails.set("PageCheckedin", {
		type: IconType.Wireframe,
		unicode: 'F104',
		category: IconCategory.Documents,
		tags: ['file','arrow'],
	});
	mDetails.set("PageCheckedOut", {
		type: IconType.Wireframe,
		unicode: 'F02C',
		category: IconCategory.Documents,
		tags: ['file','arrow'],
	});
	mDetails.set("PageData", {
		type: IconType.Wireframe,
		unicode: 'E31C',
		category: IconCategory.Documents,
		tags: ['file','database'],
	});
	mDetails.set("PageEdit", {
		type: IconType.Wireframe,
		unicode: 'EFB6',
		category: IconCategory.Documents,
		tags: ['file','pencil'],
	});
	mDetails.set("PageHeader", {
		type: IconType.Wireframe,
		unicode: 'ECEE',
		category: IconCategory.Layout,
		tags: ['section'],
	});
	mDetails.set("PageHeaderEdit", {
		type: IconType.Wireframe,
		unicode: 'E31D',
		category: IconCategory.Layout,
		tags: ['section'],
	});
	mDetails.set("PageLeft", {
		type: IconType.Wireframe,
		unicode: 'E760',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("PageLink", {
		type: IconType.Wireframe,
		unicode: 'E302',
		category: IconCategory.Documents,
		tags: ['file','chain'],
	});
	mDetails.set("PageList", {
		type: IconType.Wireframe,
		unicode: 'F106',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("PageListFilter", {
		type: IconType.Wireframe,
		unicode: 'F813',
		category: IconCategory.Tables,
		tags: ['funnel'],
	});
	mDetails.set("PageListMirroredSolid", {
		type: IconType.Solid,
		unicode: 'F33B',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("PageListSolid", {
		type: IconType.Solid,
		unicode: 'F33A',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("PageLock", {
		type: IconType.Wireframe,
		unicode: 'F43F',
		category: IconCategory.Documents,
		tags: ['file','secure','locked'],
	});
	mDetails.set("PageRemove", {
		type: IconType.Wireframe,
		unicode: 'EFBA',
		category: IconCategory.Documents,
		tags: ['file','x','delete','clear'],
	});
	mDetails.set("PageRight", {
		type: IconType.Wireframe,
		unicode: 'E761',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("PageShared", {
		type: IconType.Wireframe,
		unicode: 'F02D',
		category: IconCategory.Documents,
		tags: ['file','user','person'],
	});
	mDetails.set("PageSolid", {
		type: IconType.Solid,
		unicode: 'E729',
		category: IconCategory.Documents,
		tags: ['file'],
	});
	mDetails.set("PanoIndicator", {
		type: IconType.Wireframe,
		unicode: 'E7B0',
		category: IconCategory.Photography,
		tags: ['picture','panoramic'],
	});
	mDetails.set("Parachute", {
		type: IconType.Wireframe,
		unicode: 'F351',
		category: IconCategory.Stuff,
		tags: ['skydive','delivery','drop'],
	});
	mDetails.set("ParachuteSolid", {
		type: IconType.Solid,
		unicode: 'F352',
		category: IconCategory.Stuff,
		tags: ['skydive','delivery','drop'],
	});
	mDetails.set("Parameter", {
		type: IconType.Wireframe,
		unicode: 'F306',
		category: IconCategory.Tables,
		tags: ['database','sql'],
	});
	mDetails.set("ParkingLocation", {
		type: IconType.Wireframe,
		unicode: 'E811',
		category: IconCategory.Vehicles,
		tags: ['car','automobile'],
	});
	mDetails.set("ParkingLocationMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA5E',
		category: IconCategory.Vehicles,
		tags: ['car','automobile'],
	});
	mDetails.set("ParkingMirroredSolid", {
		type: IconType.Solid,
		unicode: 'F34B',
		category: IconCategory.Vehicles,
		tags: ['car','automobile'],
	});
	mDetails.set("ParkingSolid", {
		type: IconType.Solid,
		unicode: 'F34A',
		category: IconCategory.Vehicles,
		tags: ['car','automobile'],
	});
	mDetails.set("PartlyClearNight", {
		type: IconType.Wireframe,
		unicode: 'E477',
		category: IconCategory.Weather,
		tags: ['moon','cloud'],
	});
	mDetails.set("PartlyCloudyDay", {
		type: IconType.Wireframe,
		unicode: 'E9C0',
		category: IconCategory.Weather,
		tags: ['sun'],
	});
	mDetails.set("PartlyCloudyNight", {
		type: IconType.Wireframe,
		unicode: 'E9C1',
		category: IconCategory.Weather,
		tags: ['moon'],
	});
	mDetails.set("PartlySunnyDay", {
		type: IconType.Wireframe,
		unicode: 'E469',
		category: IconCategory.Weather,
		tags: ['cloud'],
	});
	mDetails.set("PartlySunnyFlurriesDay", {
		type: IconType.Wireframe,
		unicode: 'E472',
		category: IconCategory.Weather,
		tags: ['snow','cloud'],
	});
	mDetails.set("PartlySunnyShowersDay", {
		type: IconType.Wireframe,
		unicode: 'E46E',
		category: IconCategory.Weather,
		tags: ['cloud','rain','drop'],
	});
	mDetails.set("PartlySunnyShowersNight", {
		type: IconType.Wireframe,
		unicode: 'E47A',
		category: IconCategory.Weather,
		tags: ['cloud','rain','moon'],
	});
	mDetails.set("PartlySunnyTStormsDay", {
		type: IconType.Wireframe,
		unicode: 'E470',
		category: IconCategory.Weather,
		tags: ['cloud','lightning','thunderstorm'],
	});
	mDetails.set("PartlySunnyTStormsNight", {
		type: IconType.Wireframe,
		unicode: 'E47C',
		category: IconCategory.Weather,
		tags: ['cloud','moon','lightning','thunderstorm'],
	});
	mDetails.set("PartyLeader", {
		type: IconType.Wireframe,
		unicode: 'ECA7',
		category: IconCategory.User,
		tags: ['person','contact','award','medal','ribbon'],
	});
	mDetails.set("PassiveAuthentication", {
		type: IconType.Wireframe,
		unicode: 'F32A',
		category: IconCategory.Security,
		tags: ['checkmark','checkbox'],
	});
	mDetails.set("PasswordField", {
		type: IconType.Wireframe,
		unicode: 'F6AA',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Paste", {
		type: IconType.Wireframe,
		unicode: 'E77F',
		category: IconCategory.Formatting,
		tags: ['clipboard'],
	});
	mDetails.set("PasteAsCode", {
		type: IconType.Wireframe,
		unicode: 'F5D6',
		category: IconCategory.Formatting,
		tags: ['clipboard'],
	});
	mDetails.set("PasteAsText", {
		type: IconType.Wireframe,
		unicode: 'F5D5',
		category: IconCategory.Formatting,
		tags: ['clipboard'],
	});
	mDetails.set("Pause", {
		type: IconType.Wireframe,
		unicode: 'E769',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("PaymentCard", {
		type: IconType.Wireframe,
		unicode: 'E8C7',
		category: IconCategory.Stuff,
		tags: ['credit','debit','money'],
	});
	mDetails.set("PC1", {
		type: IconType.Wireframe,
		unicode: 'E977',
		category: IconCategory.Devices,
		tags: ['desktop','computer','monitor'],
	});
	mDetails.set("PDF", {
		type: IconType.Wireframe,
		unicode: 'EA90',
		category: IconCategory.Documents,
		tags: ['file','page'],
	});
	mDetails.set("PencilReply", {
		type: IconType.Wireframe,
		unicode: 'EF7B',
		category: IconCategory.Interface,
		tags: ['edit','email','arrow','left'],
	});
	mDetails.set("Pentagon", {
		type: IconType.Wireframe,
		unicode: 'F4FF',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("PenWorkspace", {
		type: IconType.Wireframe,
		unicode: 'EDC6',
		category: IconCategory.Formatting,
		tags: ['writing','drawing','ink'],
	});
	mDetails.set("People", {
		type: IconType.Wireframe,
		unicode: 'E716',
		category: IconCategory.User,
		tags: ['person','contact'],
	});
	mDetails.set("PeopleAdd", {
		type: IconType.Wireframe,
		unicode: 'EA15',
		category: IconCategory.User,
		tags: ['person','contact','plus','new'],
	});
	mDetails.set("PeopleAlert", {
		type: IconType.Wireframe,
		unicode: 'ED93',
		category: IconCategory.User,
		tags: ['person','contact','warning','exclamation mark','error'],
	});
	mDetails.set("PeopleBlock", {
		type: IconType.Wireframe,
		unicode: 'ED91',
		category: IconCategory.User,
		tags: ['person','contact','x','close','cancel','delete','remove'],
	});
	mDetails.set("PeoplePause", {
		type: IconType.Wireframe,
		unicode: 'ED94',
		category: IconCategory.User,
		tags: ['person','contact','media','control'],
	});
	mDetails.set("PeopleRepeat", {
		type: IconType.Wireframe,
		unicode: 'ED92',
		category: IconCategory.User,
		tags: ['person','contact','refresh','reset'],
	});
	mDetails.set("Permissions", {
		type: IconType.Wireframe,
		unicode: 'E8D7',
		category: IconCategory.Stuff,
		tags: ['key','unlock'],
	});
	mDetails.set("PermissionsSolid", {
		type: IconType.Solid,
		unicode: 'F349',
		category: IconCategory.Stuff,
		tags: ['key','unlock'],
	});
	mDetails.set("Personalize", {
		type: IconType.Wireframe,
		unicode: 'E771',
		category: IconCategory.Devices,
		tags: ['computer','monitor','screen','paintbrush'],
	});
	mDetails.set("Phishing", {
		type: IconType.Wireframe,
		unicode: 'F679',
		category: IconCategory.Communication,
		tags: ['alert','warning','exclamation mark'],
	});
	mDetails.set("PhishingCampaign", {
		type: IconType.Wireframe,
		unicode: 'E48D',
		category: undefined,
		tags: [],
	});
	mDetails.set("PhishingHook", {
		type: IconType.Wireframe,
		unicode: 'E48E',
		category: undefined,
		tags: [],
	});
	mDetails.set("Phone", {
		type: IconType.Wireframe,
		unicode: 'E717',
		category: IconCategory.Communication,
		tags: ['telephone','call'],
	});
	mDetails.set("Photo", {
		type: IconType.Wireframe,
		unicode: 'E91B',
		category: IconCategory.Photography,
		tags: ['frame','picture'],
	});
	mDetails.set("Photo2", {
		type: IconType.Wireframe,
		unicode: 'EB9F',
		category: IconCategory.Photography,
		tags: ['picture','image'],
	});
	mDetails.set("Photo2Add", {
		type: IconType.Wireframe,
		unicode: 'ECAB',
		category: IconCategory.Photography,
		tags: ['picture','image','plus','new'],
	});
	mDetails.set("Photo2Fill", {
		type: IconType.Colorfill,
		unicode: 'F79F',
		category: IconCategory.Photography,
		tags: ['picture','image'],
	});
	mDetails.set("Photo2Remove", {
		type: IconType.Wireframe,
		unicode: 'ECAC',
		category: IconCategory.Photography,
		tags: ['picture','image','delete','cancel','x'],
	});
	mDetails.set("PhotoBlock", {
		type: IconType.Wireframe,
		unicode: 'E4C3',
		category: IconCategory.Photography,
		tags: ['picture','image','blocked','banned'],
	});
	mDetails.set("PhotoCollection", {
		type: IconType.Wireframe,
		unicode: 'E7AA',
		category: IconCategory.Photography,
		tags: ['picture','image'],
	});
	mDetails.set("PhotoError", {
		type: IconType.Wireframe,
		unicode: 'E3F7',
		category: IconCategory.Photography,
		tags: ['picture','image','warning','alert','exclamation mark'],
	});
	mDetails.set("PhotoVideoMedia", {
		type: IconType.Wireframe,
		unicode: 'F0B1',
		category: IconCategory.Stuff,
		tags: ['picture','image','film'],
	});
	mDetails.set("Picture", {
		type: IconType.Wireframe,
		unicode: 'E8B9',
		category: IconCategory.Photography,
		tags: ['image'],
	});
	mDetails.set("PictureCenter", {
		type: IconType.Wireframe,
		unicode: 'F522',
		category: IconCategory.Photography,
		tags: ['image'],
	});
	mDetails.set("PictureFill", {
		type: IconType.Wireframe,
		unicode: 'F523',
		category: IconCategory.Photography,
		tags: ['image'],
	});
	mDetails.set("PictureLibrary", {
		type: IconType.Wireframe,
		unicode: 'EEC2',
		category: IconCategory.Folders,
		tags: ['photos','album'],
	});
	mDetails.set("PicturePosition", {
		type: IconType.Wireframe,
		unicode: 'F524',
		category: IconCategory.Photography,
		tags: ['image'],
	});
	mDetails.set("PictureStretch", {
		type: IconType.Wireframe,
		unicode: 'F525',
		category: IconCategory.Photography,
		tags: ['image'],
	});
	mDetails.set("PictureTile", {
		type: IconType.Wireframe,
		unicode: 'F526',
		category: IconCategory.Photography,
		tags: ['image'],
	});
	mDetails.set("PieDouble", {
		type: IconType.Wireframe,
		unicode: 'EB04',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("PieSingle", {
		type: IconType.Wireframe,
		unicode: 'EB05',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("PieSingleSolid", {
		type: IconType.Solid,
		unicode: 'F530',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("Pill", {
		type: IconType.Wireframe,
		unicode: 'EACB',
		category: IconCategory.Health,
		tags: ['medicine','capsule','vitamin'],
	});
	mDetails.set("Pin", {
		type: IconType.Wireframe,
		unicode: 'E718',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("Pinned", {
		type: IconType.Wireframe,
		unicode: 'E840',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("PinnedFill", {
		type: IconType.Colorfill,
		unicode: 'E842',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("PinnedSolid", {
		type: IconType.Solid,
		unicode: 'F676',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("PinSolid12", {
		type: IconType.Wireframe,
		unicode: 'E352',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("PinSolidOff12", {
		type: IconType.Wireframe,
		unicode: 'E353',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("PivotChart", {
		type: IconType.Wireframe,
		unicode: 'F24C',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("PlainText", {
		type: IconType.Wireframe,
		unicode: 'F834',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("PlannerLogo", {
		type: IconType.Wireframe,
		unicode: 'EDD1',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','office','o365','m365','tasks'],
	});
	mDetails.set("PlanView", {
		type: IconType.Wireframe,
		unicode: 'F360',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("Play", {
		type: IconType.Wireframe,
		unicode: 'E768',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("PlaybackRate1x", {
		type: IconType.Wireframe,
		unicode: 'EC57',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("PlayerSettings", {
		type: IconType.Wireframe,
		unicode: 'EF58',
		category: IconCategory.User,
		tags: ['person','contact','gear','configuration'],
	});
	mDetails.set("PlayResume", {
		type: IconType.Wireframe,
		unicode: 'F2C6',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("PlayReverse", {
		type: IconType.Wireframe,
		unicode: 'F3E5',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("PlayReverseResume", {
		type: IconType.Wireframe,
		unicode: 'F3E4',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("PlaySolid", {
		type: IconType.Solid,
		unicode: 'F5B0',
		category: IconCategory.Media,
		tags: ['control','playback'],
	});
	mDetails.set("Plug", {
		type: IconType.Wireframe,
		unicode: 'F300',
		category: IconCategory.Stuff,
		tags: ['electricity','power'],
	});
	mDetails.set("PlugConnected", {
		type: IconType.Wireframe,
		unicode: 'F302',
		category: IconCategory.Stuff,
		tags: ['electricity','power'],
	});
	mDetails.set("PlugDisconnected", {
		type: IconType.Wireframe,
		unicode: 'F303',
		category: IconCategory.Stuff,
		tags: ['electricity','power'],
	});
	mDetails.set("PlugSolid", {
		type: IconType.Solid,
		unicode: 'F301',
		category: IconCategory.Stuff,
		tags: ['electricity','power'],
	});
	mDetails.set("POI", {
		type: IconType.Wireframe,
		unicode: 'ECAF',
		category: IconCategory.Maps,
		tags: ['point of interest','marker','pin'],
	});
	mDetails.set("POISolid", {
		type: IconType.Solid,
		unicode: 'F2D1',
		category: IconCategory.Maps,
		tags: ['point of interest','marker','pin'],
	});
	mDetails.set("PollResults", {
		type: IconType.Wireframe,
		unicode: 'F8A0',
		category: IconCategory.Charts,
		tags: ['bar'],
	});
	mDetails.set("PopExpand", {
		type: IconType.Wireframe,
		unicode: 'E49A',
		category: undefined,
		tags: [],
	});
	mDetails.set("PostUpdate", {
		type: IconType.Wireframe,
		unicode: 'E8F3',
		category: IconCategory.Communication,
		tags: ['newspaper','announcement'],
	});
	mDetails.set("PowerApps", {
		type: IconType.Wireframe,
		unicode: 'EDD2',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','0365','m365'],
	});
	mDetails.set("PowerApps2Logo", {
		type: IconType.Wireframe,
		unicode: 'F092',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365'],
	});
	mDetails.set("PowerAppsLogo", {
		type: IconType.Wireframe,
		unicode: 'F091',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365'],
	});
	mDetails.set("PowerAppsTemplate", {
		type: IconType.Wireframe,
		unicode: 'E4AC',
		category: undefined,
		tags: [],
	});
	mDetails.set("PowerBILogo", {
		type: IconType.Wireframe,
		unicode: 'EA1E',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','reports','business intelligence'],
	});
	mDetails.set("PowerBILogo16", {
		type: IconType.Wireframe,
		unicode: 'F790',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','reports','business intelligence'],
	});
	mDetails.set("PowerBILogoBackplate16", {
		type: IconType.Colorfill,
		unicode: 'F791',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','reports','business intelligence'],
	});
	mDetails.set("PowerButton", {
		type: IconType.Wireframe,
		unicode: 'E7E8',
		category: IconCategory.Interface,
		tags: ['on','off'],
	});
	mDetails.set("PowerPointDocument", {
		type: IconType.Wireframe,
		unicode: 'EF72',
		category: IconCategory.Documents,
		tags: ['file','page','microsoft','office','slides','presentation'],
	});
	mDetails.set("PowerPointLogo", {
		type: IconType.Wireframe,
		unicode: 'F1EB',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("PowerPointLogo16", {
		type: IconType.Wireframe,
		unicode: 'F394',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("PowerPointLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EC2A',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("PowerPointLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F393',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("PowerShell", {
		type: IconType.Wireframe,
		unicode: 'F1FD',
		category: IconCategory.Logos,
		tags: ['microsoft'],
	});
	mDetails.set("PowerShell2", {
		type: IconType.Wireframe,
		unicode: 'F730',
		category: IconCategory.Logos,
		tags: ['microsoft'],
	});
	mDetails.set("PowerVirtualAgentsLogo", {
		type: IconType.Wireframe,
		unicode: 'E484',
		category: undefined,
		tags: [],
	});
	mDetails.set("Precipitation", {
		type: IconType.Wireframe,
		unicode: 'E9CF',
		category: IconCategory.Weather,
		tags: ['water','droplet','liquid'],
	});
	mDetails.set("PresenceChickletVideo", {
		type: IconType.Wireframe,
		unicode: 'E979',
		category: IconCategory.Devices,
		tags: ['movies','camera'],
	});
	mDetails.set("Presentation", {
		type: IconType.Wireframe,
		unicode: 'F6E4',
		category: IconCategory.Devices,
		tags: ['screen','projector','powerpoint'],
	});
	mDetails.set("Presentation12", {
		type: IconType.Wireframe,
		unicode: 'F6E5',
		category: IconCategory.Devices,
		tags: ['screen','projector','powerpoint'],
	});
	mDetails.set("Preview", {
		type: IconType.Wireframe,
		unicode: 'E8FF',
		category: IconCategory.Documents,
		tags: ['file','page','dotted'],
	});
	mDetails.set("PreviewLink", {
		type: IconType.Wireframe,
		unicode: 'E8A1',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Previous", {
		type: IconType.Wireframe,
		unicode: 'E892',
		category: IconCategory.Media,
		tags: [],
	});
	mDetails.set("PrimaryCalendar", {
		type: IconType.Wireframe,
		unicode: 'F4AE',
		category: IconCategory.Events,
		tags: ['day','star','favorite'],
	});
	mDetails.set("Print", {
		type: IconType.Wireframe,
		unicode: 'E749',
		category: IconCategory.Devices,
		tags: ['printer'],
	});
	mDetails.set("PrintfaxPrinterFile", {
		type: IconType.Wireframe,
		unicode: 'E956',
		category: IconCategory.Documents,
		tags: ['file','printer'],
	});
	mDetails.set("Processing", {
		type: IconType.Wireframe,
		unicode: 'E9F5',
		category: IconCategory.Interface,
		tags: ['loading','gears','settings','configuration'],
	});
	mDetails.set("ProcessingCancel", {
		type: IconType.Wireframe,
		unicode: 'E403',
		category: IconCategory.Interface,
		tags: ['loading','gears','settings','configuration','x','delete','remove','stop'],
	});
	mDetails.set("ProcessingPause", {
		type: IconType.Wireframe,
		unicode: 'E405',
		category: IconCategory.Interface,
		tags: ['loading','gears','settings','configuration'],
	});
	mDetails.set("ProcessingRun", {
		type: IconType.Wireframe,
		unicode: 'E404',
		category: IconCategory.Interface,
		tags: ['loading','play','gears','settings','configuration'],
	});
	mDetails.set("ProcessMetaTask", {
		type: IconType.Wireframe,
		unicode: 'F290',
		category: IconCategory.Interface,
		tags: ['loading','gears','settings','configuration'],
	});
	mDetails.set("Product", {
		type: IconType.Wireframe,
		unicode: 'ECDC',
		category: IconCategory.Stuff,
		tags: ['box','package'],
	});
	mDetails.set("ProductCatalog", {
		type: IconType.Wireframe,
		unicode: 'EFE8',
		category: IconCategory.Stuff,
		tags: ['box','package','book'],
	});
	mDetails.set("ProductionFloorManagement", {
		type: IconType.Wireframe,
		unicode: 'EE29',
		category: IconCategory.Stuff,
		tags: ['checkmark'],
	});
	mDetails.set("ProductList", {
		type: IconType.Wireframe,
		unicode: 'E31E',
		category: IconCategory.Stuff,
		tags: ['box','package'],
	});
	mDetails.set("ProductRelease", {
		type: IconType.Wireframe,
		unicode: 'EE2E',
		category: IconCategory.Stuff,
		tags: ['box','package','pencil','edit'],
	});
	mDetails.set("ProductVariant", {
		type: IconType.Wireframe,
		unicode: 'EE30',
		category: IconCategory.Stuff,
		tags: ['boxes','packages'],
	});
	mDetails.set("ProfileSearch", {
		type: IconType.Wireframe,
		unicode: 'EF35',
		category: IconCategory.User,
		tags: ['person','contact'],
	});
	mDetails.set("ProFootball", {
		type: IconType.Wireframe,
		unicode: 'EB27',
		category: IconCategory.Games,
		tags: ['sports','atheletics'],
	});
	mDetails.set("ProgressLoopInner", {
		type: IconType.Wireframe,
		unicode: 'ECDE',
		category: IconCategory.Interface,
		tags: ['loading'],
	});
	mDetails.set("ProgressLoopOuter", {
		type: IconType.Wireframe,
		unicode: 'ECDF',
		category: IconCategory.Interface,
		tags: ['loading'],
	});
	mDetails.set("ProgressRing5", {
		type: IconType.Wireframe,
		unicode: 'EDF6',
		category: IconCategory.Interface,
		tags: ['loading'],
	});
	mDetails.set("ProgressRingDots", {
		type: IconType.Wireframe,
		unicode: 'F16A',
		category: IconCategory.Interface,
		tags: ['loading'],
	});
	mDetails.set("ProHockey", {
		type: IconType.Wireframe,
		unicode: 'EB28',
		category: IconCategory.Games,
		tags: ['puck','sports','athletics','canada'],
	});
	mDetails.set("ProjectCollection", {
		type: IconType.Wireframe,
		unicode: 'F363',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("ProjectDocument", {
		type: IconType.Wireframe,
		unicode: 'F759',
		category: IconCategory.Documents,
		tags: ['file','page','microsoft','office'],
	});
	mDetails.set("ProjectLogo16", {
		type: IconType.Wireframe,
		unicode: 'F480',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("ProjectLogo32", {
		type: IconType.Wireframe,
		unicode: 'F47E',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("ProjectLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EDD4',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("PromotedDatabase", {
		type: IconType.Wireframe,
		unicode: 'F77D',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("ProtectedDocument", {
		type: IconType.Wireframe,
		unicode: 'E8A6',
		category: IconCategory.Documents,
		tags: ['page','file','secure','locked'],
	});
	mDetails.set("ProtectionCenterLogo32", {
		type: IconType.Wireframe,
		unicode: 'F494',
		category: IconCategory.Logos,
		tags: ['azure','secure','shield','locked'],
	});
	mDetails.set("ProtectRestrict", {
		type: IconType.Wireframe,
		unicode: 'F22A',
		category: IconCategory.Security,
		tags: ['locked','collapse','remove'],
	});
	mDetails.set("ProvisioningPackage", {
		type: IconType.Wireframe,
		unicode: 'E835',
		category: IconCategory.Development,
		tags: ['gear','configuration','settings','arrows','loop'],
	});
	mDetails.set("PublicCalendar", {
		type: IconType.Wireframe,
		unicode: 'EF6D',
		category: IconCategory.Events,
		tags: ['day','arrows'],
	});
	mDetails.set("PublicContactCard", {
		type: IconType.Wireframe,
		unicode: 'EF6E',
		category: IconCategory.User,
		tags: ['person','business card','profile','arrows'],
	});
	mDetails.set("PublicContactCardMirrored", {
		type: IconType.Wireframe,
		unicode: 'F230',
		category: IconCategory.User,
		tags: ['person','business card','profile','arrows'],
	});
	mDetails.set("PublicEmail", {
		type: IconType.Wireframe,
		unicode: 'EF6F',
		category: IconCategory.Communication,
		tags: ['envelope','arrows'],
	});
	mDetails.set("PublicFolder", {
		type: IconType.Wireframe,
		unicode: 'EF70',
		category: IconCategory.Folders,
		tags: ['arrows'],
	});
	mDetails.set("PublishContent", {
		type: IconType.Wireframe,
		unicode: 'F0D4',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("PublishCourse", {
		type: IconType.Wireframe,
		unicode: 'F699',
		category: IconCategory.User,
		tags: ['person','contact','reading','book'],
	});
	mDetails.set("PublisherLogo", {
		type: IconType.Wireframe,
		unicode: 'F1ED',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("PublisherLogo16", {
		type: IconType.Wireframe,
		unicode: 'F3A0',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("PublisherLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F39F',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("Puzzle", {
		type: IconType.Wireframe,
		unicode: 'EA86',
		category: IconCategory.Games,
		tags: ['piece','jigsaw'],
	});
	mDetails.set("PY", {
		type: IconType.Wireframe,
		unicode: 'F2F9',
		category: IconCategory.Development,
		tags: ['programming','python','language'],
	});
	mDetails.set("PythonLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2F8',
		category: IconCategory.Development,
		tags: ['programming'],
	});
	mDetails.set("QandA", {
		type: IconType.Wireframe,
		unicode: 'F8A2',
		category: IconCategory.Communication,
		tags: ['question','answer','faq','speech bubble','question mark'],
	});
	mDetails.set("QandAMirror", {
		type: IconType.Wireframe,
		unicode: 'F8A3',
		category: IconCategory.Communication,
		tags: ['question','answer','faq','speech bubble','question mark'],
	});
	mDetails.set("QRCode", {
		type: IconType.Wireframe,
		unicode: 'ED14',
		category: IconCategory.Stuff,
		tags: ['scan'],
	});
	mDetails.set("QuadColumn", {
		type: IconType.Wireframe,
		unicode: 'F66F',
		category: IconCategory.Layout,
		tags: ['section','page'],
	});
	mDetails.set("Quantity", {
		type: IconType.Wireframe,
		unicode: 'E9F8',
		category: IconCategory.Stuff,
		tags: ['boxes','packages','cubes'],
	});
	mDetails.set("QuarterCircle", {
		type: IconType.Wireframe,
		unicode: 'F502',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("QueryList", {
		type: IconType.Wireframe,
		unicode: 'F2B8',
		category: IconCategory.Tables,
		tags: ['funnel','filter'],
	});
	mDetails.set("Questionnaire", {
		type: IconType.Wireframe,
		unicode: 'EE19',
		category: IconCategory.Documents,
		tags: ['survey','page','checklist'],
	});
	mDetails.set("QuestionnaireMirrored", {
		type: IconType.Wireframe,
		unicode: 'EE4B',
		category: IconCategory.Documents,
		tags: ['survey','page','checklist'],
	});
	mDetails.set("QuickNote", {
		type: IconType.Wireframe,
		unicode: 'E70B',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("QuickNoteSolid", {
		type: IconType.Solid,
		unicode: 'F338',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("R", {
		type: IconType.Wireframe,
		unicode: 'F4EB',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("RadioBtnOff", {
		type: IconType.Wireframe,
		unicode: 'ECCA',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("RadioBtnOn", {
		type: IconType.Wireframe,
		unicode: 'ECCB',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("RadioBullet", {
		type: IconType.Wireframe,
		unicode: 'E915',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Rain", {
		type: IconType.Wireframe,
		unicode: 'E9C4',
		category: IconCategory.Weather,
		tags: ['cloud','storm'],
	});
	mDetails.set("RainShowersDay", {
		type: IconType.Wireframe,
		unicode: 'E9C3',
		category: IconCategory.Weather,
		tags: ['cloud','storm','sun'],
	});
	mDetails.set("RainShowersNight", {
		type: IconType.Wireframe,
		unicode: 'EA0F',
		category: IconCategory.Weather,
		tags: ['cloud','storm','moon'],
	});
	mDetails.set("RainSnow", {
		type: IconType.Wireframe,
		unicode: 'E9C7',
		category: IconCategory.Weather,
		tags: ['cloud','storm'],
	});
	mDetails.set("RawSource", {
		type: IconType.Wireframe,
		unicode: 'F299',
		category: IconCategory.Formatting,
		tags: ['text','paragraph'],
	});
	mDetails.set("Read", {
		type: IconType.Wireframe,
		unicode: 'E8C3',
		category: IconCategory.Communication,
		tags: ['email','envelope'],
	});
	mDetails.set("ReadingMode", {
		type: IconType.Wireframe,
		unicode: 'E736',
		category: IconCategory.Stuff,
		tags: ['book'],
	});
	mDetails.set("ReadingModeSolid", {
		type: IconType.Solid,
		unicode: 'F33D',
		category: IconCategory.Stuff,
		tags: ['book'],
	});
	mDetails.set("ReadOutLoud", {
		type: IconType.Wireframe,
		unicode: 'F112',
		category: IconCategory.Interface,
		tags: ['book','volume','speaker','sound'],
	});
	mDetails.set("RealEstate", {
		type: IconType.Wireframe,
		unicode: 'E758',
		category: IconCategory.Maps,
		tags: ['house','home','sold','for sale','building'],
	});
	mDetails.set("ReceiptCheck", {
		type: IconType.Wireframe,
		unicode: 'EF5B',
		category: IconCategory.Communication,
		tags: [],
	});
	mDetails.set("ReceiptForward", {
		type: IconType.Wireframe,
		unicode: 'EF59',
		category: IconCategory.Communication,
		tags: ['arrow','right'],
	});
	mDetails.set("ReceiptProcessing", {
		type: IconType.Wireframe,
		unicode: 'E496',
		category: IconCategory.Documents,
		tags: ['scroll','paper','papyrus','cvs'],
	});
	mDetails.set("ReceiptReply", {
		type: IconType.Wireframe,
		unicode: 'EF5A',
		category: IconCategory.Communication,
		tags: ['arrow','left'],
	});
	mDetails.set("ReceiptTentative", {
		type: IconType.Wireframe,
		unicode: 'F41A',
		category: IconCategory.Communication,
		tags: ['question mark'],
	});
	mDetails.set("ReceiptTentativeMirrored", {
		type: IconType.Wireframe,
		unicode: 'F41B',
		category: IconCategory.Communication,
		tags: ['question mark'],
	});
	mDetails.set("ReceiptUndelivered", {
		type: IconType.Wireframe,
		unicode: 'F419',
		category: IconCategory.Communication,
		tags: ['x','delete','remove','cancel','close'],
	});
	mDetails.set("Recent", {
		type: IconType.Wireframe,
		unicode: 'E823',
		category: IconCategory.Events,
		tags: ['clock','time'],
	});
	mDetails.set("Record2", {
		type: IconType.Wireframe,
		unicode: 'EA3F',
		category: IconCategory.Devices,
		tags: ['cd','dvd','music'],
	});
	mDetails.set("RecruitmentManagement", {
		type: IconType.Wireframe,
		unicode: 'EE12',
		category: IconCategory.User,
		tags: ['person','contact','people','suitcase','briefcase','bag','luggage'],
	});
	mDetails.set("RectangleShape", {
		type: IconType.Wireframe,
		unicode: 'F1A9',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("RectangleShapeSolid", {
		type: IconType.Solid,
		unicode: 'F640',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("RectangularClipping", {
		type: IconType.Wireframe,
		unicode: 'F407',
		category: IconCategory.Formatting,
		tags: ['plus','add','new'],
	});
	mDetails.set("RecurringEvent", {
		type: IconType.Wireframe,
		unicode: 'EF5D',
		category: IconCategory.Events,
		tags: ['calendar','refresh','repeat','reset','day'],
	});
	mDetails.set("RecurringTask", {
		type: IconType.Wireframe,
		unicode: 'EDB2',
		category: IconCategory.Tasks,
		tags: ['checkbox','checkmark','reset','refresh','sync'],
	});
	mDetails.set("RecycleBin", {
		type: IconType.Wireframe,
		unicode: 'EF87',
		category: IconCategory.Interface,
		tags: ['trash','delete'],
	});
	mDetails.set("Redeploy", {
		type: IconType.Wireframe,
		unicode: 'F29E',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("RedEye", {
		type: IconType.Wireframe,
		unicode: 'E7B3',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("RedEye12", {
		type: IconType.Wireframe,
		unicode: 'E3AD',
		category: IconCategory.Photography,
		tags: [],
	});
	mDetails.set("Redo", {
		type: IconType.Wireframe,
		unicode: 'E7A6',
		category: IconCategory.Interface,
		tags: [],
	});
	mDetails.set("Refresh", {
		type: IconType.Wireframe,
		unicode: 'E72C',
		category: IconCategory.Arrows,
		tags: ['reset','sync','loop'],
	});
	mDetails.set("RegistryEditor", {
		type: IconType.Wireframe,
		unicode: 'F1FF',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Relationship", {
		type: IconType.Wireframe,
		unicode: 'F003',
		category: IconCategory.Charts,
		tags: ['flowchart'],
	});
	mDetails.set("ReleaseDefinition", {
		type: IconType.Wireframe,
		unicode: 'F6EA',
		category: IconCategory.Development,
		tags: ['rocket','ship','shuttle','space'],
	});
	mDetails.set("ReleaseGate", {
		type: IconType.Wireframe,
		unicode: 'F7BE',
		category: IconCategory.Development,
		tags: ['arrow','right'],
	});
	mDetails.set("ReleaseGateCheck", {
		type: IconType.Wireframe,
		unicode: 'F7BF',
		category: IconCategory.Development,
		tags: ['arrow','right','checkmark','approval','complete'],
	});
	mDetails.set("ReleaseGateError", {
		type: IconType.Wireframe,
		unicode: 'F7C0',
		category: IconCategory.Development,
		tags: ['arrow','right','alert','warning','x','cancel','close','complete'],
	});
	mDetails.set("ReminderGroup", {
		type: IconType.Wireframe,
		unicode: 'EBF8',
		category: IconCategory.User,
		tags: ['person','contact','accept','checkmark','approval','people'],
	});
	mDetails.set("ReminderPerson", {
		type: IconType.Wireframe,
		unicode: 'EBF7',
		category: IconCategory.User,
		tags: ['contact','accept','checkmark','approval'],
	});
	mDetails.set("ReminderTime", {
		type: IconType.Wireframe,
		unicode: 'EBF9',
		category: IconCategory.Events,
		tags: ['clock','accept','checkmark','approval'],
	});
	mDetails.set("Remote", {
		type: IconType.Wireframe,
		unicode: 'E8AF',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Remove", {
		type: IconType.Wireframe,
		unicode: 'E738',
		category: IconCategory.Interface,
		tags: ['minus','delete','collapse'],
	});
	mDetails.set("RemoveContent", {
		type: IconType.Wireframe,
		unicode: 'ECC7',
		category: IconCategory.Interface,
		tags: ['x','clear','cancel','delete','remove'],
	});
	mDetails.set("RemoveEvent", {
		type: IconType.Wireframe,
		unicode: 'ED8A',
		category: IconCategory.Events,
		tags: ['calendar','day','cancel','x','remove','delete','close'],
	});
	mDetails.set("RemoveFilter", {
		type: IconType.Wireframe,
		unicode: 'EB08',
		category: IconCategory.Interface,
		tags: ['x','clear','cancel','delete','remove'],
	});
	mDetails.set("RemoveFromShoppingList", {
		type: IconType.Wireframe,
		unicode: 'F7D5',
		category: IconCategory.Tables,
		tags: ['clear','x','delete'],
	});
	mDetails.set("RemoveFromTrash", {
		type: IconType.Wireframe,
		unicode: 'F82B',
		category: IconCategory.Interface,
		tags: ['garbage','delete','arrow','right'],
	});
	mDetails.set("RemoveLink", {
		type: IconType.Wireframe,
		unicode: 'ED90',
		category: IconCategory.Formatting,
		tags: ['chain','close','cancel','delete'],
	});
	mDetails.set("RemoveLinkChain", {
		type: IconType.Colorfill,
		unicode: 'F79A',
		category: IconCategory.Formatting,
		tags: ['chain','close','cancel','delete'],
	});
	mDetails.set("RemoveLinkX", {
		type: IconType.Colorfill,
		unicode: 'F79B',
		category: IconCategory.Formatting,
		tags: ['chain','close','cancel','delete'],
	});
	mDetails.set("RemoveOccurrence", {
		type: IconType.Wireframe,
		unicode: 'ED9B',
		category: IconCategory.Arrows,
		tags: ['refresh','reset','delete','x','clear','cancel'],
	});
	mDetails.set("Rename", {
		type: IconType.Wireframe,
		unicode: 'E8AC',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("RenewalCurrent", {
		type: IconType.Wireframe,
		unicode: 'F545',
		category: IconCategory.Events,
		tags: ['chevron'],
	});
	mDetails.set("RenewalFuture", {
		type: IconType.Wireframe,
		unicode: 'F546',
		category: IconCategory.Events,
		tags: ['chevron'],
	});
	mDetails.set("ReopenPages", {
		type: IconType.Wireframe,
		unicode: 'ED50',
		category: IconCategory.Interface,
		tags: [],
	});
	mDetails.set("Repair", {
		type: IconType.Wireframe,
		unicode: 'E90F',
		category: IconCategory.Stuff,
		tags: ['wrench','tool'],
	});
	mDetails.set("RepeatAll", {
		type: IconType.Wireframe,
		unicode: 'E8EE',
		category: IconCategory.Arrows,
		tags: ['loop'],
	});
	mDetails.set("RepeatHeaderRows", {
		type: IconType.Wireframe,
		unicode: 'E3EB',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("RepeatOne", {
		type: IconType.Wireframe,
		unicode: 'E8ED',
		category: IconCategory.Arrows,
		tags: ['loop','1','single'],
	});
	mDetails.set("Reply", {
		type: IconType.Wireframe,
		unicode: 'E97A',
		category: IconCategory.Arrows,
		tags: ['left','email'],
	});
	mDetails.set("ReplyAll", {
		type: IconType.Wireframe,
		unicode: 'EE0A',
		category: IconCategory.Arrows,
		tags: ['left','email'],
	});
	mDetails.set("ReplyAllAlt", {
		type: IconType.Wireframe,
		unicode: 'EF5F',
		category: IconCategory.Arrows,
		tags: ['left','email'],
	});
	mDetails.set("ReplyAllMirrored", {
		type: IconType.Wireframe,
		unicode: 'EE36',
		category: IconCategory.Arrows,
		tags: ['right','email'],
	});
	mDetails.set("ReplyAlt", {
		type: IconType.Wireframe,
		unicode: 'EF5E',
		category: IconCategory.Arrows,
		tags: ['left','email'],
	});
	mDetails.set("ReplyMirrored", {
		type: IconType.Wireframe,
		unicode: 'EE35',
		category: IconCategory.Arrows,
		tags: ['right','email'],
	});
	mDetails.set("Repo", {
		type: IconType.Wireframe,
		unicode: 'F2CB',
		category: IconCategory.Development,
		tags: ['git','source control'],
	});
	mDetails.set("ReportAdd", {
		type: IconType.Wireframe,
		unicode: 'F52C',
		category: IconCategory.Charts,
		tags: ['plus','column'],
	});
	mDetails.set("ReportDocument", {
		type: IconType.Wireframe,
		unicode: 'E9F9',
		category: IconCategory.Documents,
		tags: ['page','chart'],
	});
	mDetails.set("ReportHacked", {
		type: IconType.Wireframe,
		unicode: 'E730',
		category: IconCategory.Security,
		tags: ['shield','exclamation mark'],
	});
	mDetails.set("ReportLibrary", {
		type: IconType.Wireframe,
		unicode: 'EEBB',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("ReportLibraryMirrored", {
		type: IconType.Wireframe,
		unicode: 'EEBC',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("ReportLock", {
		type: IconType.Wireframe,
		unicode: 'F875',
		category: IconCategory.Documents,
		tags: ['chart','secure'],
	});
	mDetails.set("ReportWarning", {
		type: IconType.Wireframe,
		unicode: 'F569',
		category: IconCategory.Documents,
		tags: ['page','exclamation mark'],
	});
	mDetails.set("RepoSolid", {
		type: IconType.Solid,
		unicode: 'F2CC',
		category: IconCategory.Development,
		tags: ['git','source control'],
	});
	mDetails.set("Rerun", {
		type: IconType.Wireframe,
		unicode: 'F8A1',
		category: IconCategory.Development,
		tags: ['play','redo'],
	});
	mDetails.set("ReservationOrders", {
		type: IconType.Wireframe,
		unicode: 'F845',
		category: IconCategory.Documents,
		tags: ['page','event','calendar'],
	});
	mDetails.set("Reset", {
		type: IconType.Wireframe,
		unicode: 'E423',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("ResetDevice", {
		type: IconType.Wireframe,
		unicode: 'ED10',
		category: IconCategory.Devices,
		tags: ['smart phone'],
	});
	mDetails.set("ResponsesMenu", {
		type: IconType.Wireframe,
		unicode: 'F768',
		category: IconCategory.Controls,
		tags: ['arrow','left'],
	});
	mDetails.set("ReturnKey", {
		type: IconType.Wireframe,
		unicode: 'E751',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("ReturnToSession", {
		type: IconType.Wireframe,
		unicode: 'ED24',
		category: IconCategory.Arrows,
		tags: ['right'],
	});
	mDetails.set("ReviewRequestMirroredSolid", {
		type: IconType.Solid,
		unicode: 'F357',
		category: IconCategory.Interface,
		tags: ['question mark'],
	});
	mDetails.set("ReviewRequestSolid", {
		type: IconType.Solid,
		unicode: 'F356',
		category: IconCategory.Interface,
		tags: ['question mark'],
	});
	mDetails.set("ReviewResponseSolid", {
		type: IconType.Solid,
		unicode: 'F358',
		category: IconCategory.Interface,
		tags: ['checkmark','done','completed','approved'],
	});
	mDetails.set("ReviewSolid", {
		type: IconType.Solid,
		unicode: 'F355',
		category: IconCategory.Communication,
		tags: ['speech bubble','talk','chat','message'],
	});
	mDetails.set("RevToggleKey", {
		type: IconType.Wireframe,
		unicode: 'E845',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("RewardsLogo", {
		type: IconType.Wireframe,
		unicode: 'ED4E',
		category: undefined,
		tags: [],
	});
	mDetails.set("RewardsLogoArt64", {
		type: IconType.Wireframe,
		unicode: 'EEF2',
		category: undefined,
		tags: [],
	});
	mDetails.set("RewardsLogoSolid", {
		type: IconType.Solid,
		unicode: 'ED4F',
		category: undefined,
		tags: [],
	});
	mDetails.set("Rewind", {
		type: IconType.Wireframe,
		unicode: 'EB9E',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left'],
	});
	mDetails.set("RewindEightX", {
		type: IconType.Wireframe,
		unicode: 'E449',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left','8'],
	});
	mDetails.set("RewindFourX", {
		type: IconType.Wireframe,
		unicode: 'E448',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left','4'],
	});
	mDetails.set("RewindOneFiveX", {
		type: IconType.Wireframe,
		unicode: 'E446',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left','1.5'],
	});
	mDetails.set("RewindOneX", {
		type: IconType.Wireframe,
		unicode: 'E445',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left','1'],
	});
	mDetails.set("RewindPointFiveX", {
		type: IconType.Wireframe,
		unicode: 'E444',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left','0.5'],
	});
	mDetails.set("RewindTwoX", {
		type: IconType.Wireframe,
		unicode: 'E447',
		category: IconCategory.Media,
		tags: ['control','reverse','arrows','left','2'],
	});
	mDetails.set("Ribbon", {
		type: IconType.Wireframe,
		unicode: 'E9D1',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("RibbonSolid", {
		type: IconType.Solid,
		unicode: 'F345',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("RightDoubleQuote", {
		type: IconType.Wireframe,
		unicode: 'E9B1',
		category: IconCategory.Math,
		tags: ['symbol'],
	});
	mDetails.set("RightTriangle", {
		type: IconType.Wireframe,
		unicode: 'F500',
		category: IconCategory.Arrows,
		tags: ['bottom'],
	});
	mDetails.set("Ringer", {
		type: IconType.Wireframe,
		unicode: 'EA8F',
		category: IconCategory.Communication,
		tags: ['bell','alert','notification'],
	});
	mDetails.set("RingerActive", {
		type: IconType.Wireframe,
		unicode: 'E498',
		category: IconCategory.Communication,
		tags: ['bell','alert','notification'],
	});
	mDetails.set("RingerOff", {
		type: IconType.Wireframe,
		unicode: 'F2C5',
		category: IconCategory.Communication,
		tags: ['bell','alert','notification','banned','blocked','muted'],
	});
	mDetails.set("RingerRemove", {
		type: IconType.Wireframe,
		unicode: 'F279',
		category: IconCategory.Communication,
		tags: ['bell','alert','notification','delete','x','cancel','clear'],
	});
	mDetails.set("RingerSolid", {
		type: IconType.Solid,
		unicode: 'EF3A',
		category: IconCategory.Communication,
		tags: ['bell','alert','notification'],
	});
	mDetails.set("Robot", {
		type: IconType.Wireframe,
		unicode: 'E99A',
		category: IconCategory.Stuff,
		tags: ['android','beep boop'],
	});
	mDetails.set("Rocket", {
		type: IconType.Wireframe,
		unicode: 'F3B3',
		category: IconCategory.Vehicles,
		tags: ['spaceship','blast off','nasa','aliens'],
	});
	mDetails.set("Room", {
		type: IconType.Wireframe,
		unicode: 'ED9F',
		category: IconCategory.Maps,
		tags: ['door','open','wall','entrance','exit'],
	});
	mDetails.set("Rotate", {
		type: IconType.Wireframe,
		unicode: 'E7AD',
		category: IconCategory.Layout,
		tags: ['turn','arrow'],
	});
	mDetails.set("Rotate90Clockwise", {
		type: IconType.Wireframe,
		unicode: 'F80D',
		category: IconCategory.Layout,
		tags: ['turn','arrow'],
	});
	mDetails.set("Rotate90CounterClockwise", {
		type: IconType.Wireframe,
		unicode: 'F80E',
		category: IconCategory.Layout,
		tags: ['turn','arrow'],
	});
	mDetails.set("RowsChild", {
		type: IconType.Wireframe,
		unicode: 'F29C',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("RowsGroup", {
		type: IconType.Wireframe,
		unicode: 'F29B',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("Rugby", {
		type: IconType.Wireframe,
		unicode: 'EB2D',
		category: IconCategory.Games,
		tags: ['sports','athletics','ball'],
	});
	mDetails.set("Running", {
		type: IconType.Wireframe,
		unicode: 'EADA',
		category: IconCategory.Games,
		tags: ['ran','athletics','sports','cross country','fast','person'],
	});
	mDetails.set("Sad", {
		type: IconType.Wireframe,
		unicode: 'E757',
		category: IconCategory.Emoji,
		tags: ['face','frown'],
	});
	mDetails.set("SadSolid", {
		type: IconType.Solid,
		unicode: 'F33E',
		category: IconCategory.Emoji,
		tags: ["face", "sad", "frown"],
	});
	mDetails.set("Save", {
		type: IconType.Wireframe,
		unicode: 'E74E',
		category: IconCategory.Interface,
		tags: ['disk','floppy'],
	});
	mDetails.set("SaveAll", {
		type: IconType.Wireframe,
		unicode: 'F203',
		category: IconCategory.Interface,
		tags: ['disk','floppy'],
	});
	mDetails.set("SaveAndClose", {
		type: IconType.Wireframe,
		unicode: 'F038',
		category: IconCategory.Interface,
		tags: ['disk','floppy'],
	});
	mDetails.set("SaveAs", {
		type: IconType.Wireframe,
		unicode: 'E792',
		category: IconCategory.Interface,
		tags: ['disk','floppy','pencil'],
	});
	mDetails.set("SaveTemplate", {
		type: IconType.Wireframe,
		unicode: 'F6EC',
		category: IconCategory.Interface,
		tags: ['disk','floppy'],
	});
	mDetails.set("SaveToMobile", {
		type: IconType.Wireframe,
		unicode: 'F7E0',
		category: IconCategory.Interface,
		tags: ['disk','floppy','phone'],
	});
	mDetails.set("Savings", {
		type: IconType.Wireframe,
		unicode: 'EB0B',
		category: IconCategory.Animals,
		tags: ['piggy','coin','bank'],
	});
	mDetails.set("ScaleUp", {
		type: IconType.Wireframe,
		unicode: 'ED09',
		category: IconCategory.Interface,
		tags: ['zoom','expand'],
	});
	mDetails.set("ScaleVolume", {
		type: IconType.Wireframe,
		unicode: 'F18C',
		category: IconCategory.Interface,
		tags: ['zoom','expand','arrows','grow'],
	});
	mDetails.set("ScatterChart", {
		type: IconType.Wireframe,
		unicode: 'EFEB',
		category: IconCategory.Charts,
		tags: ['points','plot'],
	});
	mDetails.set("ScheduleEventAction", {
		type: IconType.Wireframe,
		unicode: 'F1EF',
		category: IconCategory.Events,
		tags: ['calendar','lightning bolt','day'],
	});
	mDetails.set("SchoolDataSyncLogo", {
		type: IconType.Wireframe,
		unicode: 'E34C',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','education'],
	});
	mDetails.set("ScopeTemplate", {
		type: IconType.Wireframe,
		unicode: 'F2B0',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Screen", {
		type: IconType.Wireframe,
		unicode: 'EF39',
		category: undefined,
		tags: [],
	});
	mDetails.set("ScreenCast", {
		type: IconType.Wireframe,
		unicode: 'F7E2',
		category: IconCategory.Devices,
		tags: ['monitor','broadcast','wifi'],
	});
	mDetails.set("ScreenPreviewOn", {
		type: IconType.Wireframe,
		unicode: 'F11E',
		category: undefined,
		tags: [],
	});
	mDetails.set("ScreenTime", {
		type: IconType.Wireframe,
		unicode: 'F182',
		category: IconCategory.Events,
		tags: ['clock'],
	});
	mDetails.set("Script", {
		type: IconType.Wireframe,
		unicode: 'F03A',
		category: IconCategory.Documents,
		tags: ['scroll','wizard','decree','law','paper','papyrus'],
	});
	mDetails.set("ScrollUpDown", {
		type: IconType.Wireframe,
		unicode: 'EC8F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("SDCard", {
		type: IconType.Wireframe,
		unicode: 'E7F1',
		category: IconCategory.Stuff,
		tags: ['storage','disk','data'],
	});
	mDetails.set("Search", {
		type: IconType.Wireframe,
		unicode: 'E721',
		category: IconCategory.Interface,
		tags: ['magnifying glass','find'],
	});
	mDetails.set("SearchAndApps", {
		type: IconType.Wireframe,
		unicode: 'E773',
		category: IconCategory.Interface,
		tags: [],
	});
	mDetails.set("SearchBookmark", {
		type: IconType.Wireframe,
		unicode: 'F5B8',
		category: IconCategory.Interface,
		tags: ['ribbon'],
	});
	mDetails.set("SearchCalendar", {
		type: IconType.Wireframe,
		unicode: 'F4AF',
		category: IconCategory.Events,
		tags: ['calendar','day'],
	});
	mDetails.set("SearchData", {
		type: IconType.Wireframe,
		unicode: 'F3F1',
		category: IconCategory.Tables,
		tags: ['database'],
	});
	mDetails.set("SearchIssue", {
		type: IconType.Wireframe,
		unicode: 'F09A',
		category: IconCategory.Interface,
		tags: ['magnifying glass','find','question mark'],
	});
	mDetails.set("SearchIssueMirrored", {
		type: IconType.Wireframe,
		unicode: 'F09B',
		category: IconCategory.Interface,
		tags: ['magnifying glass','find','question mark'],
	});
	mDetails.set("SearchNearby", {
		type: IconType.Wireframe,
		unicode: 'E820',
		category: IconCategory.Maps,
		tags: ['streetview','person'],
	});
	mDetails.set("SecondaryNav", {
		type: IconType.Wireframe,
		unicode: 'F814',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Section", {
		type: IconType.Wireframe,
		unicode: 'EC0C',
		category: IconCategory.Layout,
		tags: ['trapezoid','tab'],
	});
	mDetails.set("Sections", {
		type: IconType.Wireframe,
		unicode: 'EF76',
		category: IconCategory.Layout,
		tags: ['trapezoids','tabs'],
	});
	mDetails.set("SecurityGroup", {
		type: IconType.Wireframe,
		unicode: 'ED85',
		category: IconCategory.User,
		tags: ['person','contact','people','locked'],
	});
	mDetails.set("SecurityTest", {
		type: IconType.Wireframe,
		unicode: 'E48F',
		category: IconCategory.Tags,
		tags: ['shield'],
	});
	mDetails.set("SeeDo", {
		type: IconType.Wireframe,
		unicode: 'E808',
		category: IconCategory.Stuff,
		tags: ['binoculars','explore','zoom'],
	});
	mDetails.set("SelectAll", {
		type: IconType.Wireframe,
		unicode: 'E8B3',
		category: IconCategory.Interface,
		tags: [],
	});
	mDetails.set("Sell", {
		type: IconType.Wireframe,
		unicode: 'EB0C',
		category: IconCategory.Arrows,
		tags: ['down','circle'],
	});
	mDetails.set("SemiboldWeight", {
		type: IconType.Wireframe,
		unicode: 'F4F0',
		category: IconCategory.Formatting,
		tags: ['letter'],
	});
	mDetails.set("Send", {
		type: IconType.Wireframe,
		unicode: 'E724',
		category: IconCategory.Arrows,
		tags: ['right','email','paper airplane'],
	});
	mDetails.set("SendMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA63',
		category: IconCategory.Arrows,
		tags: ['left','email','paper airplane'],
	});
	mDetails.set("SentimentAnalysis", {
		type: IconType.Wireframe,
		unicode: 'E393',
		category: IconCategory.Emoji,
		tags: ['faces','happy','sad','frown','smile'],
	});
	mDetails.set("Separator", {
		type: IconType.Wireframe,
		unicode: 'F35E',
		category: IconCategory.Interface,
		tags: ['line','vertical'],
	});
	mDetails.set("Server", {
		type: IconType.Wireframe,
		unicode: 'F201',
		category: IconCategory.Devices,
		tags: [],
	});
	mDetails.set("ServerEnviroment", {
		type: IconType.Wireframe,
		unicode: 'F29F',
		category: IconCategory.Devices,
		tags: [],
	});
	mDetails.set("ServerProcesses", {
		type: IconType.Wireframe,
		unicode: 'F1FE',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("ServiceOff", {
		type: IconType.Wireframe,
		unicode: 'E3FD',
		category: IconCategory.Stuff,
		tags: ['wrench','blocked','banned','tool'],
	});
	mDetails.set("SetAction", {
		type: IconType.Wireframe,
		unicode: 'F071',
		category: IconCategory.Development,
		tags: ['lightning bolt'],
	});
	mDetails.set("Settings", {
		type: IconType.Wireframe,
		unicode: 'E713',
		category: IconCategory.Interface,
		tags: ['gear','configuration'],
	});
	mDetails.set("SettingsAdd", {
		type: IconType.Wireframe,
		unicode: 'E35A',
		category: undefined,
		tags: [],
	});
	mDetails.set("SettingsSecure", {
		type: IconType.Wireframe,
		unicode: 'E4D2',
		category: undefined,
		tags: [],
	});
	mDetails.set("SettingsSync", {
		type: IconType.Wireframe,
		unicode: 'E359',
		category: undefined,
		tags: [],
	});
	mDetails.set("ShakeDevice", {
		type: IconType.Wireframe,
		unicode: 'F80A',
		category: IconCategory.Devices,
		tags: ['smart phone','tablet','arrows'],
	});
	mDetails.set("Shapes", {
		type: IconType.Wireframe,
		unicode: 'EC7C',
		category: IconCategory.Shapes,
		tags: ['triangle','square','circle'],
	});
	mDetails.set("ShapeSolid", {
		type: IconType.Solid,
		unicode: 'E422',
		category: IconCategory.Shapes,
		tags: ['triangle','square','circle'],
	});
	mDetails.set("Share", {
		type: IconType.Wireframe,
		unicode: 'E72D',
		category: IconCategory.Interface,
		tags: ['social'],
	});
	mDetails.set("SharedDatabase", {
		type: IconType.Wireframe,
		unicode: 'E3D9',
		category: IconCategory.Tables,
		tags: ['person','user','contact'],
	});
	mDetails.set("ShareiOS", {
		type: IconType.Wireframe,
		unicode: 'EF79',
		category: IconCategory.Interface,
		tags: ['social'],
	});
	mDetails.set("Sharepoint2013LogoInverse", {
		type: IconType.Wireframe,
		unicode: 'E480',
		category: undefined,
		tags: [],
	});
	mDetails.set("SharepointAppIcon16", {
		type: IconType.Wireframe,
		unicode: 'E365',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','balls'],
	});
	mDetails.set("SharepointLogo", {
		type: IconType.Wireframe,
		unicode: 'F27E',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','balls'],
	});
	mDetails.set("SharepointLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'ED18',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','balls'],
	});
	mDetails.set("Shield", {
		type: IconType.Wireframe,
		unicode: 'EA18',
		category: IconCategory.Security,
		tags: ['badge','police'],
	});
	mDetails.set("ShieldAlert", {
		type: IconType.Wireframe,
		unicode: 'F7D7',
		category: IconCategory.Security,
		tags: ['badge','police','exclamation mark'],
	});
	mDetails.set("ShieldSolid", {
		type: IconType.Solid,
		unicode: 'F340',
		category: IconCategory.Security,
		tags: ['badge','police'],
	});
	mDetails.set("Shirt", {
		type: IconType.Wireframe,
		unicode: 'ED00',
		category: IconCategory.Stuff,
		tags: ['t-shirt','clothing'],
	});
	mDetails.set("Shop", {
		type: IconType.Wireframe,
		unicode: 'E719',
		category: IconCategory.Stuff,
		tags: ['bag','cart'],
	});
	mDetails.set("ShoppingCart", {
		type: IconType.Wireframe,
		unicode: 'E7BF',
		category: IconCategory.Vehicles,
		tags: ['store','groceries'],
	});
	mDetails.set("ShoppingCartSolid", {
		type: IconType.Solid,
		unicode: 'F342',
		category: IconCategory.Vehicles,
		tags: ['store','groceries'],
	});
	mDetails.set("ShopServer", {
		type: IconType.Wireframe,
		unicode: 'F2B6',
		category: IconCategory.Stuff,
		tags: ['bag','cart','computer'],
	});
	mDetails.set("Showers", {
		type: IconType.Wireframe,
		unicode: 'E46C',
		category: undefined,
		tags: [],
	});
	mDetails.set("ShowGrid", {
		type: IconType.Wireframe,
		unicode: 'F7DE',
		category: IconCategory.Tables,
		tags: ['eye'],
	});
	mDetails.set("ShowResults", {
		type: IconType.Wireframe,
		unicode: 'E8BC',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ShowResultsMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA65',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ShowTimeAs", {
		type: IconType.Wireframe,
		unicode: 'F787',
		category: IconCategory.Events,
		tags: ['day','availability','out of office','busy','free'],
	});
	mDetails.set("ShowVisualFilter", {
		type: IconType.Wireframe,
		unicode: 'F4DE',
		category: undefined,
		tags: [],
	});
	mDetails.set("SidePanel", {
		type: IconType.Wireframe,
		unicode: 'EF52',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("SidePanelMirrored", {
		type: IconType.Wireframe,
		unicode: 'F221',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Signin", {
		type: IconType.Wireframe,
		unicode: 'F286',
		category: IconCategory.User,
		tags: ['person','contact','key','permissions'],
	});
	mDetails.set("SignOut", {
		type: IconType.Wireframe,
		unicode: 'F3B1',
		category: IconCategory.Interface,
		tags: ['logout','exit','leave','arrow','right'],
	});
	mDetails.set("SingleBookmark", {
		type: IconType.Wireframe,
		unicode: 'EDFF',
		category: IconCategory.Interface,
		tags: ['ribbon'],
	});
	mDetails.set("SingleBookmarkSolid", {
		type: IconType.Solid,
		unicode: 'EE00',
		category: IconCategory.Interface,
		tags: ['ribbon'],
	});
	mDetails.set("SingleColumn", {
		type: IconType.Wireframe,
		unicode: 'F1D3',
		category: IconCategory.Layout,
		tags: ['section'],
	});
	mDetails.set("SingleColumnEdit", {
		type: IconType.Wireframe,
		unicode: 'F321',
		category: IconCategory.Layout,
		tags: ['section','edit'],
	});
	mDetails.set("SIPMove", {
		type: IconType.Wireframe,
		unicode: 'E759',
		category: IconCategory.Interface,
		tags: ['drag','reposition','arrows'],
	});
	mDetails.set("SiteScan", {
		type: IconType.Wireframe,
		unicode: 'EBEC',
		category: IconCategory.Security,
		tags: ['search'],
	});
	mDetails.set("SizeLegacy", {
		type: IconType.Wireframe,
		unicode: 'E2B2',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("SkipBack10", {
		type: IconType.Wireframe,
		unicode: 'ED3C',
		category: IconCategory.Media,
		tags: ['arrow','left'],
	});
	mDetails.set("SkipForward30", {
		type: IconType.Wireframe,
		unicode: 'ED3D',
		category: IconCategory.Media,
		tags: ['arrow','right'],
	});
	mDetails.set("SkiResorts", {
		type: IconType.Wireframe,
		unicode: 'EB45',
		category: IconCategory.Maps,
		tags: ['mountain','snow','peak'],
	});
	mDetails.set("SkypeArrow", {
		type: IconType.Wireframe,
		unicode: 'F748',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("SkypeCheck", {
		type: IconType.Wireframe,
		unicode: 'EF80',
		category: IconCategory.Tasks,
		tags: ['checkmark','approved','completed','done'],
	});
	mDetails.set("SkypeCircleArrow", {
		type: IconType.Solid,
		unicode: 'F747',
		category: IconCategory.Arrows,
		tags: ['left'],
	});
	mDetails.set("SkypeCircleCheck", {
		type: IconType.Solid,
		unicode: 'EF7D',
		category: IconCategory.Tasks,
		tags: ['checkmark','approved','completed','done'],
	});
	mDetails.set("SkypeCircleClock", {
		type: IconType.Solid,
		unicode: 'EF7E',
		category: IconCategory.Events,
		tags: ['time'],
	});
	mDetails.set("SkypeCircleMinus", {
		type: IconType.Solid,
		unicode: 'EF7F',
		category: IconCategory.Math,
		tags: ['subtraction','collapse','remove'],
	});
	mDetails.set("SkypeCircleSlash", {
		type: IconType.Solid,
		unicode: 'F825',
		category: IconCategory.Math,
		tags: ['division','divide','backslash'],
	});
	mDetails.set("SkypeClock", {
		type: IconType.Wireframe,
		unicode: 'EF81',
		category: IconCategory.Events,
		tags: ['time'],
	});
	mDetails.set("SkypeForBusinessLogo", {
		type: IconType.Wireframe,
		unicode: 'F0FC',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','chat'],
	});
	mDetails.set("SkypeForBusinessLogo16", {
		type: IconType.Wireframe,
		unicode: 'F40F',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','chat'],
	});
	mDetails.set("SkypeForBusinessLogoFill", {
		type: IconType.Colorfill,
		unicode: 'F27D',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','chat'],
	});
	mDetails.set("SkypeForBusinessLogoFill16", {
		type: IconType.Colorfill,
		unicode: 'F410',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','chat'],
	});
	mDetails.set("SkypeLogo", {
		type: IconType.Wireframe,
		unicode: 'EB6F',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','chat'],
	});
	mDetails.set("SkypeLogo16", {
		type: IconType.Wireframe,
		unicode: 'F40E',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','chat'],
	});
	mDetails.set("SkypeMessage", {
		type: IconType.Solid,
		unicode: 'EF83',
		category: IconCategory.Communication,
		tags: ['talk','chat','speech bubble'],
	});
	mDetails.set("SkypeMinus", {
		type: IconType.Wireframe,
		unicode: 'EF82',
		category: IconCategory.Math,
		tags: ['subtraction','collapse','remove'],
	});
	mDetails.set("SkypeSlash", {
		type: IconType.Wireframe,
		unicode: 'F826',
		category: IconCategory.Math,
		tags: ['division','divide','backslash'],
	});
	mDetails.set("Sleet", {
		type: IconType.Wireframe,
		unicode: 'E474',
		category: undefined,
		tags: [],
	});
	mDetails.set("Slider", {
		type: IconType.Wireframe,
		unicode: 'F527',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("SliderHandleSize", {
		type: IconType.Wireframe,
		unicode: 'F528',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("SliderThumb", {
		type: IconType.Wireframe,
		unicode: 'EC13',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Slideshow", {
		type: IconType.Wireframe,
		unicode: 'E786',
		category: IconCategory.Photography,
		tags: ['play'],
	});
	mDetails.set("SmartGlassRemote", {
		type: IconType.Wireframe,
		unicode: 'F80B',
		category: IconCategory.Devices,
		tags: [],
	});
	mDetails.set("SnapToGrid", {
		type: IconType.Wireframe,
		unicode: 'F7E4',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Snooze", {
		type: IconType.Wireframe,
		unicode: 'F4BD',
		category: IconCategory.Events,
		tags: ['clock','sleep','wake up','alarm','time'],
	});
	mDetails.set("Snow", {
		type: IconType.Wireframe,
		unicode: 'E9C8',
		category: IconCategory.Weather,
		tags: ['cloud','cold','winter'],
	});
	mDetails.set("Snowflake", {
		type: IconType.Wireframe,
		unicode: 'EB46',
		category: IconCategory.Weather,
		tags: ['cold','winter'],
	});
	mDetails.set("SnowShowerDay", {
		type: IconType.Wireframe,
		unicode: 'E9FD',
		category: IconCategory.Weather,
		tags: ['sun','cloud','cold','winter'],
	});
	mDetails.set("SnowShowerNight", {
		type: IconType.Wireframe,
		unicode: 'EA11',
		category: IconCategory.Weather,
		tags: ['moon','cloud','cold','winter'],
	});
	mDetails.set("Soccer", {
		type: IconType.Wireframe,
		unicode: 'EB21',
		category: IconCategory.Games,
		tags: ['sports','athletics','ball','football'],
	});
	mDetails.set("SocialListeningLogo", {
		type: IconType.Wireframe,
		unicode: 'ED7C',
		category: IconCategory.Logos,
		tags: ['speech bubble'],
	});
	mDetails.set("Sort", {
		type: IconType.Wireframe,
		unicode: 'E8CB',
		category: IconCategory.Arrows,
		tags: ['up','down'],
	});
	mDetails.set("SortDown", {
		type: IconType.Wireframe,
		unicode: 'EE69',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("SortLines", {
		type: IconType.Wireframe,
		unicode: 'E9D0',
		category: IconCategory.Arrows,
		tags: ['down','descending'],
	});
	mDetails.set("SortLinesAscending", {
		type: IconType.Wireframe,
		unicode: 'E43A',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("SortUp", {
		type: IconType.Wireframe,
		unicode: 'EE68',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Source", {
		type: IconType.Wireframe,
		unicode: 'EB1B',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Spacer", {
		type: IconType.Wireframe,
		unicode: 'F40D',
		category: IconCategory.Formatting,
		tags: ['arrows','up','down'],
	});
	mDetails.set("Speakers", {
		type: IconType.Wireframe,
		unicode: 'E7F5',
		category: IconCategory.Stuff,
		tags: ['sound','volume','music'],
	});
	mDetails.set("SpecialEvent", {
		type: IconType.Wireframe,
		unicode: 'F536',
		category: IconCategory.Events,
		tags: ['calendar','star','favorite','day'],
	});
	mDetails.set("SpeedHigh", {
		type: IconType.Wireframe,
		unicode: 'EC4A',
		category: IconCategory.Charts,
		tags: ['dashboard','gauge'],
	});
	mDetails.set("Spelling", {
		type: IconType.Wireframe,
		unicode: 'F87B',
		category: IconCategory.Formatting,
		tags: ['checkmark','spellcheck'],
	});
	mDetails.set("Split", {
		type: IconType.Wireframe,
		unicode: 'EDBC',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("SplitObject", {
		type: IconType.Wireframe,
		unicode: 'F547',
		category: IconCategory.Charts,
		tags: ['node','hierarchy'],
	});
	mDetails.set("Sprint", {
		type: IconType.Wireframe,
		unicode: 'F3B0',
		category: IconCategory.Arrows,
		tags: ['loop'],
	});
	mDetails.set("SQLAnalyticsPool", {
		type: IconType.Wireframe,
		unicode: 'E434',
		category: IconCategory.Tables,
		tags: ['database'],
	});
	mDetails.set("Squalls", {
		type: IconType.Wireframe,
		unicode: 'E9CC',
		category: IconCategory.Weather,
		tags: ['windy','blow','breath'],
	});
	mDetails.set("SquareShape", {
		type: IconType.Wireframe,
		unicode: 'F1A6',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("SquareShapeSolid", {
		type: IconType.Solid,
		unicode: 'F63D',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("Stack", {
		type: IconType.Wireframe,
		unicode: 'F26F',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("StackColumnChart", {
		type: IconType.Wireframe,
		unicode: 'E9FC',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("StackedBarChart", {
		type: IconType.Wireframe,
		unicode: 'F24D',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("StackedColumnChart2", {
		type: IconType.Wireframe,
		unicode: 'F666',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("StackedColumnChart2Fill", {
		type: IconType.Solid,
		unicode: 'F831',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("StackedLineChart", {
		type: IconType.Wireframe,
		unicode: 'F24E',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("StackIndicator", {
		type: IconType.Wireframe,
		unicode: 'E7FF',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("StaffNotebookLogo16", {
		type: IconType.Wireframe,
		unicode: 'F48E',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','onenote'],
	});
	mDetails.set("StaffNotebookLogo32", {
		type: IconType.Wireframe,
		unicode: 'F48C',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','onenote'],
	});
	mDetails.set("StaffNotebookLogoFill16", {
		type: IconType.Colorfill,
		unicode: 'F48F',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','onenote'],
	});
	mDetails.set("StaffNotebookLogoFill32", {
		type: IconType.Colorfill,
		unicode: 'F48D',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','onenote'],
	});
	mDetails.set("StaffNotebookLogoInverted16", {
		type: IconType.Wireframe,
		unicode: 'F491',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','onenote'],
	});
	mDetails.set("StaffNotebookLogoInverted32", {
		type: IconType.Wireframe,
		unicode: 'F490',
		category: IconCategory.Logos,
		tags: ['brand','microsoft','office','onenote'],
	});
	mDetails.set("Starburst", {
		type: IconType.Wireframe,
		unicode: 'EF78',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("StarburstSolid", {
		type: IconType.Solid,
		unicode: 'F33C',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("StatusCircleBlock", {
		type: IconType.Wireframe,
		unicode: 'F140',
		category: IconCategory.Stars,
		tags: ['banned','blocked'],
	});
	mDetails.set("StatusCircleBlock2", {
		type: IconType.Wireframe,
		unicode: 'F141',
		category: IconCategory.Stars,
		tags: ['minus','remove'],
	});
	mDetails.set("StatusCircleCheckmark", {
		type: IconType.Wireframe,
		unicode: 'F13E',
		category: IconCategory.Stars,
		tags: ['approved','done','complete'],
	});
	mDetails.set("StatusCircleErrorX", {
		type: IconType.Wireframe,
		unicode: 'F13D',
		category: IconCategory.Stars,
		tags: ['x','alert','warning','cancel','delete','remove','clear'],
	});
	mDetails.set("StatusCircleExclamation", {
		type: IconType.Wireframe,
		unicode: 'F13C',
		category: IconCategory.Stars,
		tags: ['alert','warning','exclamation mark'],
	});
	mDetails.set("StatusCircleInfo", {
		type: IconType.Wireframe,
		unicode: 'F13F',
		category: IconCategory.Stars,
		tags: ['letter','information'],
	});
	mDetails.set("StatusCircleInner", {
		type: IconType.Wireframe,
		unicode: 'F137',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("StatusCircleOuter", {
		type: IconType.Wireframe,
		unicode: 'F136',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("StatusCircleQuestionMark", {
		type: IconType.Wireframe,
		unicode: 'F142',
		category: IconCategory.Stars,
		tags: ['help'],
	});
	mDetails.set("StatusCircleRing", {
		type: IconType.Wireframe,
		unicode: 'F138',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("StatusCircleSync", {
		type: IconType.Wireframe,
		unicode: 'F143',
		category: IconCategory.Stars,
		tags: ['reset','reload','refresh'],
	});
	mDetails.set("StatusErrorFull", {
		type: IconType.Wireframe,
		unicode: 'EB90',
		category: IconCategory.Stars,
		tags: ['x','alert','warning','clear','delete','remove','cancel'],
	});
	mDetails.set("StatusTriangle", {
		type: IconType.Solid,
		unicode: 'EA82',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("StatusTriangleExclamation", {
		type: IconType.Wireframe,
		unicode: 'F13B',
		category: IconCategory.Stars,
		tags: ['exclamation mark','alert','warning'],
	});
	mDetails.set("StatusTriangleInner", {
		type: IconType.Solid,
		unicode: 'F13A',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("StatusTriangleOuter", {
		type: IconType.Solid,
		unicode: 'F139',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("Step", {
		type: IconType.Wireframe,
		unicode: 'F241',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("StepInsert", {
		type: IconType.Wireframe,
		unicode: 'F242',
		category: IconCategory.Tasks,
		tags: ['arrow','right'],
	});
	mDetails.set("StepShared", {
		type: IconType.Wireframe,
		unicode: 'F243',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("StepSharedAdd", {
		type: IconType.Wireframe,
		unicode: 'F244',
		category: IconCategory.Tasks,
		tags: ['plus','new'],
	});
	mDetails.set("StepSharedInsert", {
		type: IconType.Wireframe,
		unicode: 'F245',
		category: IconCategory.Tasks,
		tags: ['arrow','right'],
	});
	mDetails.set("StickyNotesOutlineAppIcon", {
		type: IconType.Wireframe,
		unicode: 'E36A',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("StickyNotesSolidAppIcon", {
		type: IconType.Wireframe,
		unicode: 'E36B',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("StockDown", {
		type: IconType.Wireframe,
		unicode: 'EB0F',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("StockUp", {
		type: IconType.Wireframe,
		unicode: 'EB11',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("Stop", {
		type: IconType.Wireframe,
		unicode: 'E71A',
		category: IconCategory.Media,
		tags: ['square'],
	});
	mDetails.set("StopSolid", {
		type: IconType.Solid,
		unicode: 'EE95',
		category: IconCategory.Media,
		tags: ['square'],
	});
	mDetails.set("Stopwatch", {
		type: IconType.Wireframe,
		unicode: 'E916',
		category: IconCategory.Games,
		tags: ['timer','clock','countdown'],
	});
	mDetails.set("StorageAcount", {
		type: IconType.Wireframe,
		unicode: 'E435',
		category: IconCategory.Folders,
		tags: ['filing'],
	});
	mDetails.set("StorageOptical", {
		type: IconType.Wireframe,
		unicode: 'E958',
		category: IconCategory.Stuff,
		tags: ['record','cd','dvd','bluray'],
	});
	mDetails.set("StoreLogo16", {
		type: IconType.Wireframe,
		unicode: 'EA96',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("StoreLogoMed20", {
		type: IconType.Wireframe,
		unicode: 'EA04',
		category: IconCategory.Logos,
		tags: [],
	});
	mDetails.set("Storyboard", {
		type: IconType.Wireframe,
		unicode: 'F308',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("StreamDiscover", {
		type: IconType.Wireframe,
		unicode: 'F7D9',
		category: undefined,
		tags: [],
	});
	mDetails.set("Streaming", {
		type: IconType.Wireframe,
		unicode: 'E93E',
		category: IconCategory.Media,
		tags: [],
	});
	mDetails.set("StreamingOff", {
		type: IconType.Wireframe,
		unicode: 'F2BB',
		category: IconCategory.Media,
		tags: ['banned','blocked'],
	});
	mDetails.set("StreamLogo", {
		type: IconType.Wireframe,
		unicode: 'F329',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','video'],
	});
	mDetails.set("Street", {
		type: IconType.Wireframe,
		unicode: 'E913',
		category: IconCategory.Maps,
		tags: ['house','tree','road','address','home','garage'],
	});
	mDetails.set("StreetsideSplitMinimize", {
		type: IconType.Wireframe,
		unicode: 'E802',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("Strikethrough", {
		type: IconType.Wireframe,
		unicode: 'EDE0',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("Subscribe", {
		type: IconType.Wireframe,
		unicode: 'EDA1',
		category: IconCategory.Communication,
		tags: ['inbox','arrow','down'],
	});
	mDetails.set("Subscript", {
		type: IconType.Wireframe,
		unicode: 'EDDF',
		category: IconCategory.Formatting,
		tags: ['math','formula'],
	});
	mDetails.set("SubstitutionsIn", {
		type: IconType.Wireframe,
		unicode: 'EB31',
		category: IconCategory.Arrows,
		tags: ['up'],
	});
	mDetails.set("Suitcase", {
		type: IconType.Wireframe,
		unicode: 'EDD3',
		category: IconCategory.Stuff,
		tags: ['bag','luggage','briefcase'],
	});
	mDetails.set("SunAdd", {
		type: IconType.Wireframe,
		unicode: 'EF69',
		category: IconCategory.Weather,
		tags: ['plus'],
	});
	mDetails.set("Sunny", {
		type: IconType.Wireframe,
		unicode: 'E9BD',
		category: IconCategory.Weather,
		tags: [],
	});
	mDetails.set("SunQuestionMark", {
		type: IconType.Wireframe,
		unicode: 'EF6A',
		category: IconCategory.Weather,
		tags: [],
	});
	mDetails.set("Superscript", {
		type: IconType.Wireframe,
		unicode: 'EDDE',
		category: IconCategory.Formatting,
		tags: ['math','formula','power'],
	});
	mDetails.set("SurveyQuestions", {
		type: IconType.Wireframe,
		unicode: 'F01B',
		category: IconCategory.Documents,
		tags: ['page','file','question mark'],
	});
	mDetails.set("SwayLogo16", {
		type: IconType.Wireframe,
		unicode: 'F484',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("SwayLogo32", {
		type: IconType.Wireframe,
		unicode: 'F482',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("SwayLogoFill16", {
		type: IconType.Colorfill,
		unicode: 'F485',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("SwayLogoFill32", {
		type: IconType.Colorfill,
		unicode: 'F483',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("SwayLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'ED29',
		category: IconCategory.Logos,
		tags: ['office','o365','m365','microsoft','brand'],
	});
	mDetails.set("Switch", {
		type: IconType.Wireframe,
		unicode: 'E8AB',
		category: IconCategory.Arrows,
		tags: ['left','right'],
	});
	mDetails.set("SwitcherStartEnd", {
		type: IconType.Wireframe,
		unicode: 'E810',
		category: IconCategory.Arrows,
		tags: ['up','down'],
	});
	mDetails.set("SwitchUser", {
		type: IconType.Wireframe,
		unicode: 'E748',
		category: IconCategory.User,
		tags: ['person','contact','arrows','left','right'],
	});
	mDetails.set("Sync", {
		type: IconType.Wireframe,
		unicode: 'E895',
		category: IconCategory.Arrows,
		tags: ['refresh'],
	});
	mDetails.set("SyncFolder", {
		type: IconType.Wireframe,
		unicode: 'E8F7',
		category: IconCategory.Folders,
		tags: ['reset','refresh'],
	});
	mDetails.set("SyncOccurence", {
		type: IconType.Wireframe,
		unicode: 'F4A3',
		category: IconCategory.Arrows,
		tags: ['refresh'],
	});
	mDetails.set("SyncStatus", {
		type: IconType.Wireframe,
		unicode: 'F751',
		category: IconCategory.Stars,
		tags: ['refresh'],
	});
	mDetails.set("SyncStatusSolid", {
		type: IconType.Solid,
		unicode: 'F752',
		category: IconCategory.Stars,
		tags: ['refresh'],
	});
	mDetails.set("SyncToPC", {
		type: IconType.Wireframe,
		unicode: 'EE6E',
		category: IconCategory.Devices,
		tags: ['reset','refresh'],
	});
	mDetails.set("System", {
		type: IconType.Wireframe,
		unicode: 'E770',
		category: IconCategory.Devices,
		tags: ['laptop','computer'],
	});
	mDetails.set("Tab", {
		type: IconType.Wireframe,
		unicode: 'E7E9',
		category: IconCategory.Layout,
		tags: ['stack'],
	});
	mDetails.set("TabCenter", {
		type: IconType.Wireframe,
		unicode: 'F100',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Table", {
		type: IconType.Wireframe,
		unicode: 'ED86',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableBrandedColumn", {
		type: IconType.Wireframe,
		unicode: 'E3F1',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableBrandedRow", {
		type: IconType.Wireframe,
		unicode: 'E3EE',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableColumn", {
		type: IconType.Wireframe,
		unicode: 'E4BD',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableComputed", {
		type: IconType.Wireframe,
		unicode: 'F8F5',
		category: IconCategory.Tables,
		tags: ['lightning bolt'],
	});
	mDetails.set("TableFirstColumn", {
		type: IconType.Wireframe,
		unicode: 'E3EF',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableGroup", {
		type: IconType.Wireframe,
		unicode: 'F6D9',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableHeaderRow", {
		type: IconType.Wireframe,
		unicode: 'E3EC',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableLastColumn", {
		type: IconType.Wireframe,
		unicode: 'E3F0',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TableLink", {
		type: IconType.Wireframe,
		unicode: 'F77A',
		category: IconCategory.Tables,
		tags: ['chain'],
	});
	mDetails.set("Tablet", {
		type: IconType.Wireframe,
		unicode: 'E70A',
		category: IconCategory.Devices,
		tags: [],
	});
	mDetails.set("TabletMode", {
		type: IconType.Wireframe,
		unicode: 'EBFC',
		category: IconCategory.Devices,
		tags: ['hand','touch'],
	});
	mDetails.set("TableTotalRow", {
		type: IconType.Wireframe,
		unicode: 'E3ED',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TabletSelected", {
		type: IconType.Wireframe,
		unicode: 'EC74',
		category: IconCategory.Devices,
		tags: ['approved','checkmark','complete'],
	});
	mDetails.set("TabOneColumn", {
		type: IconType.Wireframe,
		unicode: 'F849',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("TabThreeColumn", {
		type: IconType.Wireframe,
		unicode: 'F84B',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("TabTwoColumn", {
		type: IconType.Wireframe,
		unicode: 'F84A',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Tag", {
		type: IconType.Wireframe,
		unicode: 'E8EC',
		category: IconCategory.Tags,
		tags: [],
	});
	mDetails.set("TagGroup", {
		type: IconType.Wireframe,
		unicode: 'E3F6',
		category: IconCategory.Tags,
		tags: [],
	});
	mDetails.set("TagSolid", {
		type: IconType.Solid,
		unicode: 'F70E',
		category: IconCategory.Tags,
		tags: [],
	});
	mDetails.set("TagUnknown", {
		type: IconType.Wireframe,
		unicode: 'F6DF',
		category: IconCategory.Tags,
		tags: ['question mark'],
	});
	mDetails.set("TagUnknown12", {
		type: IconType.Wireframe,
		unicode: 'F6E1',
		category: IconCategory.Tags,
		tags: ['question mark'],
	});
	mDetails.set("TagUnknown12Mirror", {
		type: IconType.Wireframe,
		unicode: 'F6E2',
		category: IconCategory.Tags,
		tags: ['question mark'],
	});
	mDetails.set("TagUnknownMirror", {
		type: IconType.Wireframe,
		unicode: 'F6E0',
		category: IconCategory.Tags,
		tags: ['question mark'],
	});
	mDetails.set("Taskboard", {
		type: IconType.Wireframe,
		unicode: 'F1C2',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("TaskGroup", {
		type: IconType.Wireframe,
		unicode: 'F2AE',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("TaskGroupMirrored", {
		type: IconType.Wireframe,
		unicode: 'F2AF',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("TaskList", {
		type: IconType.Wireframe,
		unicode: 'E3B6',
		category: IconCategory.Tasks,
		tags: ['todo','checkmark'],
	});
	mDetails.set("TaskLogo", {
		type: IconType.Wireframe,
		unicode: 'F493',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','checkmark','checkbox'],
	});
	mDetails.set("TaskManager", {
		type: IconType.Wireframe,
		unicode: 'EDB7',
		category: IconCategory.Tasks,
		tags: ['dialog','choice'],
	});
	mDetails.set("TaskManagerMirrored", {
		type: IconType.Wireframe,
		unicode: 'EDB8',
		category: IconCategory.Tasks,
		tags: ['dialog','choice'],
	});
	mDetails.set("TaskSolid", {
		type: IconType.Solid,
		unicode: 'F333',
		category: IconCategory.Tasks,
		tags: ['checkmark','clipboard'],
	});
	mDetails.set("Taxi", {
		type: IconType.Wireframe,
		unicode: 'F4A1',
		category: IconCategory.Vehicles,
		tags: ['car','automobile','uber','lyft','delivery','transportation'],
	});
	mDetails.set("TeamFavorite", {
		type: IconType.Wireframe,
		unicode: 'F2AD',
		category: IconCategory.User,
		tags: ['person','contact','people','star'],
	});
	mDetails.set("TeamsLogo", {
		type: IconType.Wireframe,
		unicode: 'F27B',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("TeamsLogo16", {
		type: IconType.Wireframe,
		unicode: 'F40A',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("TeamsLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'F27A',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("Teamwork", {
		type: IconType.Wireframe,
		unicode: 'EA12',
		category: IconCategory.User,
		tags: ['person','contact','people','award','medal','ribbon'],
	});
	mDetails.set("Teeth", {
		type: IconType.Wireframe,
		unicode: 'F4A0',
		category: IconCategory.Health,
		tags: ['tooth','dental','dentist','bone'],
	});
	mDetails.set("Telemarketer", {
		type: IconType.Wireframe,
		unicode: 'E7B9',
		category: IconCategory.User,
		tags: ['person','contact','headphones','headset','phone'],
	});
	mDetails.set("TemporaryAccessPass", {
		type: IconType.Wireframe,
		unicode: 'E4AD',
		category: undefined,
		tags: [],
	});
	mDetails.set("TemporaryUser", {
		type: IconType.Wireframe,
		unicode: 'EE58',
		category: IconCategory.User,
		tags: ['person','contact','clock','time'],
	});
	mDetails.set("Tennis", {
		type: IconType.Wireframe,
		unicode: 'EB33',
		category: IconCategory.Games,
		tags: ['ball','sports','athletics'],
	});
	mDetails.set("TestAdd", {
		type: IconType.Wireframe,
		unicode: 'E4DC',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','plus','new'],
	});
	mDetails.set("TestAutoSolid", {
		type: IconType.Solid,
		unicode: 'F3A8',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','gear','configuration','settings'],
	});
	mDetails.set("TestBeaker", {
		type: IconType.Wireframe,
		unicode: 'F3A5',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science'],
	});
	mDetails.set("TestBeakerSolid", {
		type: IconType.Solid,
		unicode: 'F3A6',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science'],
	});
	mDetails.set("TestCase", {
		type: IconType.Wireframe,
		unicode: 'F3AF',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','checkbox','checkmark'],
	});
	mDetails.set("TestExploreSolid", {
		type: IconType.Solid,
		unicode: 'F3A7',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','compass'],
	});
	mDetails.set("TestImpactSolid", {
		type: IconType.Solid,
		unicode: 'F3AA',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','gavel'],
	});
	mDetails.set("TestParameter", {
		type: IconType.Wireframe,
		unicode: 'F3AD',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','@'],
	});
	mDetails.set("TestPlan", {
		type: IconType.Wireframe,
		unicode: 'F3AB',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science'],
	});
	mDetails.set("TestRemove", {
		type: IconType.Wireframe,
		unicode: 'E4E1',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','x','clear','cancel','delete','remove'],
	});
	mDetails.set("TestStep", {
		type: IconType.Wireframe,
		unicode: 'F3AC',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','stairs'],
	});
	mDetails.set("TestSuite", {
		type: IconType.Wireframe,
		unicode: 'F3AE',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','folder'],
	});
	mDetails.set("TestUserSolid", {
		type: IconType.Solid,
		unicode: 'F3A9',
		category: IconCategory.Tags,
		tags: ['beaker','chemistry','science','contact','person'],
	});
	mDetails.set("TextAlignBottom", {
		type: IconType.Wireframe,
		unicode: 'E3E2',
		category: IconCategory.Formatting,
		tags: ['paragraph','alignment'],
	});
	mDetails.set("TextAlignMiddle", {
		type: IconType.Wireframe,
		unicode: 'E3E1',
		category: IconCategory.Formatting,
		tags: ['paragraph','alignment'],
	});
	mDetails.set("TextAlignTop", {
		type: IconType.Wireframe,
		unicode: 'E3E0',
		category: IconCategory.Formatting,
		tags: ['paragraph','alignment'],
	});
	mDetails.set("TextBox", {
		type: IconType.Wireframe,
		unicode: 'EDC2',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("TextCallout", {
		type: IconType.Wireframe,
		unicode: 'F2A2',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("TextDocument", {
		type: IconType.Wireframe,
		unicode: 'F029',
		category: IconCategory.Documents,
		tags: ['file','page'],
	});
	mDetails.set("TextDocumentEdit", {
		type: IconType.Wireframe,
		unicode: 'E43B',
		category: IconCategory.Documents,
		tags: ['file','page','pencil'],
	});
	mDetails.set("TextDocumentSettings", {
		type: IconType.Wireframe,
		unicode: 'E4AA',
		category: undefined,
		tags: [],
	});
	mDetails.set("TextDocumentShared", {
		type: IconType.Wireframe,
		unicode: 'F02B',
		category: IconCategory.Documents,
		tags: ['file','page','user'],
	});
	mDetails.set("TextField", {
		type: IconType.Wireframe,
		unicode: 'EDC3',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("TextOverflow", {
		type: IconType.Wireframe,
		unicode: 'F51F',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TextParagraphOption", {
		type: IconType.Wireframe,
		unicode: 'E3E3',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TextRecognition", {
		type: IconType.Wireframe,
		unicode: 'E394',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("TextRotate270Degrees", {
		type: IconType.Wireframe,
		unicode: 'E3E7',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TextRotate90Degrees", {
		type: IconType.Wireframe,
		unicode: 'E3E6',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TextRotateHorizontal", {
		type: IconType.Wireframe,
		unicode: 'E3E5',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TextRotation", {
		type: IconType.Wireframe,
		unicode: 'E3E4',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TFVCLogo", {
		type: IconType.Wireframe,
		unicode: 'F44D',
		category: IconCategory.Logos,
		tags: ['team foundation version control','brand','microsoft','sourcecontrol','node','mindmap'],
	});
	mDetails.set("ThisPC", {
		type: IconType.Wireframe,
		unicode: 'EC4E',
		category: IconCategory.Devices,
		tags: ['computer','keyboard','desktop','monitor','screen'],
	});
	mDetails.set("ThreeQuarterCircle", {
		type: IconType.Wireframe,
		unicode: 'F503',
		category: IconCategory.Shapes,
		tags: [],
	});
	mDetails.set("ThumbnailView", {
		type: IconType.Wireframe,
		unicode: 'E7B6',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ThumbnailViewMirrored", {
		type: IconType.Wireframe,
		unicode: 'EA67',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Thunderstorms", {
		type: IconType.Wireframe,
		unicode: 'E9C6',
		category: IconCategory.Weather,
		tags: ['clouds','lightning','rain'],
	});
	mDetails.set("Ticket", {
		type: IconType.Wireframe,
		unicode: 'EB54',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("Tiles", {
		type: IconType.Wireframe,
		unicode: 'ECA5',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Tiles2", {
		type: IconType.Wireframe,
		unicode: 'EF7C',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("TimeEntry", {
		type: IconType.Wireframe,
		unicode: 'EF95',
		category: IconCategory.Events,
		tags: ['clock'],
	});
	mDetails.set("Timeline", {
		type: IconType.Wireframe,
		unicode: 'ED9C',
		category: IconCategory.Tasks,
		tags: ['clock','schedule','plan'],
	});
	mDetails.set("TimelineDelivery", {
		type: IconType.Wireframe,
		unicode: 'F2AB',
		category: IconCategory.User,
		tags: ['person','contact','schedule','gant','plan'],
	});
	mDetails.set("TimelineMatrixView", {
		type: IconType.Wireframe,
		unicode: 'F361',
		category: IconCategory.Tables,
		tags: [],
	});
	mDetails.set("TimelineProgress", {
		type: IconType.Wireframe,
		unicode: 'F2AA',
		category: IconCategory.Tasks,
		tags: ['schedule','gant','plan'],
	});
	mDetails.set("TimePicker", {
		type: IconType.Wireframe,
		unicode: 'E367',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Timer", {
		type: IconType.Wireframe,
		unicode: 'E91E',
		category: IconCategory.Stuff,
		tags: ['stopwatch'],
	});
	mDetails.set("TimeSheet", {
		type: IconType.Wireframe,
		unicode: 'EA05',
		category: IconCategory.Events,
		tags: ['clock'],
	});
	mDetails.set("ToDoLogoBottom", {
		type: IconType.Colorfill,
		unicode: 'F4B3',
		category: IconCategory.Logos,
		tags: ['office','m365','microsoft','tasks','o365'],
	});
	mDetails.set("ToDoLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'F4BC',
		category: IconCategory.Logos,
		tags: ['office','m365','microsoft','tasks','o365','checkmark'],
	});
	mDetails.set("ToDoLogoOutline", {
		type: IconType.Wireframe,
		unicode: 'F75B',
		category: IconCategory.Logos,
		tags: ['office','m365','microsoft','tasks','o365','checkmark'],
	});
	mDetails.set("ToDoLogoTop", {
		type: IconType.Colorfill,
		unicode: 'F4B4',
		category: IconCategory.Logos,
		tags: ['office','m365','microsoft','tasks','o365'],
	});
	mDetails.set("ToggleBorder", {
		type: IconType.Wireframe,
		unicode: 'EC12',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("ToggleFilled", {
		type: IconType.Solid,
		unicode: 'EC11',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("ToggleLeft", {
		type: IconType.Wireframe,
		unicode: 'F19E',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("ToggleRight", {
		type: IconType.Wireframe,
		unicode: 'F19F',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("ToggleThumb", {
		type: IconType.Wireframe,
		unicode: 'EC14',
		category: IconCategory.Controls,
		tags: [],
	});
	mDetails.set("Toll", {
		type: IconType.Wireframe,
		unicode: 'F160',
		category: IconCategory.Stuff,
		tags: [],
	});
	mDetails.set("Toolbox", {
		type: IconType.Wireframe,
		unicode: 'ECED',
		category: IconCategory.Stuff,
		tags: ['storage'],
	});
	mDetails.set("Total", {
		type: IconType.Wireframe,
		unicode: 'E9DF',
		category: IconCategory.Math,
		tags: ['equation','symbol'],
	});
	mDetails.set("Touch", {
		type: IconType.Wireframe,
		unicode: 'E815',
		category: IconCategory.Interface,
		tags: ['finger','hand','point'],
	});
	mDetails.set("TouchPointer", {
		type: IconType.Wireframe,
		unicode: 'E7C9',
		category: IconCategory.Interface,
		tags: ['finger','hand','cursor'],
	});
	mDetails.set("Trackers", {
		type: IconType.Wireframe,
		unicode: 'EADF',
		category: IconCategory.Tasks,
		tags: ['checkmark','clipboard','list','todo'],
	});
	mDetails.set("TrackersMirrored", {
		type: IconType.Wireframe,
		unicode: 'EE92',
		category: IconCategory.Tasks,
		tags: ['checkmark','clipboard','list','todo'],
	});
	mDetails.set("Train", {
		type: IconType.Wireframe,
		unicode: 'E7C0',
		category: IconCategory.Vehicles,
		tags: ['choochoo','tracks','railroad','transportation','metro','monorail'],
	});
	mDetails.set("TrainSolid", {
		type: IconType.Solid,
		unicode: 'EB4D',
		category: IconCategory.Vehicles,
		tags: ['choochoo','tracks','railroad','transportation','metro','monorail'],
	});
	mDetails.set("TransferCall", {
		type: IconType.Wireframe,
		unicode: 'ED95',
		category: IconCategory.Communication,
		tags: ['telephone','arrows'],
	});
	mDetails.set("Transition", {
		type: IconType.Wireframe,
		unicode: 'F3BC',
		category: IconCategory.User,
		tags: ['person','contact','arrows','refresh','reload','reset'],
	});
	mDetails.set("TransitionEffect", {
		type: IconType.Wireframe,
		unicode: 'F5B4',
		category: IconCategory.Interface,
		tags: ['powerpoint','presentation','slides','arrows'],
	});
	mDetails.set("TransitionPop", {
		type: IconType.Wireframe,
		unicode: 'F5B2',
		category: IconCategory.Interface,
		tags: ['powerpoint','presentation','slides','arrows','expand'],
	});
	mDetails.set("TransitionPush", {
		type: IconType.Wireframe,
		unicode: 'F5B3',
		category: IconCategory.Interface,
		tags: ['powerpoint','presentation','slides','arrows','collapse'],
	});
	mDetails.set("Translate", {
		type: IconType.Wireframe,
		unicode: 'E7B2',
		category: IconCategory.Interface,
		tags: ['language'],
	});
	mDetails.set("Trending12", {
		type: IconType.Wireframe,
		unicode: 'F62D',
		category: IconCategory.Arrows,
		tags: ['stock','market'],
	});
	mDetails.set("TriangleDown12", {
		type: IconType.Wireframe,
		unicode: 'EED1',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleLeft12", {
		type: IconType.Wireframe,
		unicode: 'EED2',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleRight12", {
		type: IconType.Wireframe,
		unicode: 'EED3',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleShape", {
		type: IconType.Wireframe,
		unicode: 'F1A7',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleShapeSolid", {
		type: IconType.Solid,
		unicode: 'F63E',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleSolid", {
		type: IconType.Solid,
		unicode: 'EA08',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleSolidDown12", {
		type: IconType.Solid,
		unicode: 'EECD',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleSolidLeft12", {
		type: IconType.Solid,
		unicode: 'EECE',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleSolidRight12", {
		type: IconType.Wireframe,
		unicode: 'EECF',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleSolidUp12", {
		type: IconType.Solid,
		unicode: 'EECC',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriangleUp12", {
		type: IconType.Wireframe,
		unicode: 'EED0',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("TriggerApproval", {
		type: IconType.Wireframe,
		unicode: 'F3B2',
		category: IconCategory.Tables,
		tags: ['lightning bolt','checkmark','approved','completed','done'],
	});
	mDetails.set("TriggerAuto", {
		type: IconType.Wireframe,
		unicode: 'F24A',
		category: IconCategory.Tables,
		tags: ['lightning bolt','gear','settings','configuraiton'],
	});
	mDetails.set("TriggerUser", {
		type: IconType.Wireframe,
		unicode: 'F24B',
		category: IconCategory.Tables,
		tags: ['lightning bolt','person','contact'],
	});
	mDetails.set("Trim", {
		type: IconType.Wireframe,
		unicode: 'E78A',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TrimEnd", {
		type: IconType.Wireframe,
		unicode: 'F8BC',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TrimStart", {
		type: IconType.Wireframe,
		unicode: 'F8BB',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("TripleColumn", {
		type: IconType.Wireframe,
		unicode: 'F1D5',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("TripleColumnEdit", {
		type: IconType.Wireframe,
		unicode: 'F323',
		category: IconCategory.Layout,
		tags: ['pencil'],
	});
	mDetails.set("TripleColumnWide", {
		type: IconType.Wireframe,
		unicode: 'F66E',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Trophy", {
		type: IconType.Wireframe,
		unicode: 'ED3F',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("Trophy2", {
		type: IconType.Wireframe,
		unicode: 'F1AE',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("Trophy2Solid", {
		type: IconType.Solid,
		unicode: 'F337',
		category: IconCategory.Games,
		tags: ['award'],
	});
	mDetails.set("TurnRight", {
		type: IconType.Wireframe,
		unicode: 'E7DB',
		category: IconCategory.Arrows,
		tags: ['sign'],
	});
	mDetails.set("TVMonitor", {
		type: IconType.Wireframe,
		unicode: 'E7F4',
		category: IconCategory.Devices,
		tags: ['television','screen'],
	});
	mDetails.set("TVMonitorSelected", {
		type: IconType.Wireframe,
		unicode: 'EC77',
		category: IconCategory.Devices,
		tags: ['television','screen','accepted','approved','checkmark'],
	});
	mDetails.set("TypeScriptLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2F7',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Umbrella", {
		type: IconType.Wireframe,
		unicode: 'EC04',
		category: IconCategory.Stuff,
		tags: ['rain','weather','mary poppins','penguin'],
	});
	mDetails.set("Underline", {
		type: IconType.Wireframe,
		unicode: 'E8DC',
		category: IconCategory.Formatting,
		tags: ['text decoration','letter','style'],
	});
	mDetails.set("Undo", {
		type: IconType.Wireframe,
		unicode: 'E7A7',
		category: IconCategory.Interface,
		tags: [],
	});
	mDetails.set("Uneditable", {
		type: IconType.Wireframe,
		unicode: 'ED1D',
		category: IconCategory.Interface,
		tags: ['pencil','modify','banned','blocked'],
	});
	mDetails.set("Uneditable2", {
		type: IconType.Wireframe,
		unicode: 'F876',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("Uneditable2Mirrored", {
		type: IconType.Wireframe,
		unicode: 'F877',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("UneditableMirrored", {
		type: IconType.Wireframe,
		unicode: 'F4B9',
		category: IconCategory.Interface,
		tags: ['pencil','modify','banned','blocked'],
	});
	mDetails.set("UneditableSolid12", {
		type: IconType.Wireframe,
		unicode: 'F4B7',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("UneditableSolidMirrored12", {
		type: IconType.Wireframe,
		unicode: 'F4B8',
		category: IconCategory.Interface,
		tags: ['pencil','modify'],
	});
	mDetails.set("Unfavorite", {
		type: IconType.Wireframe,
		unicode: 'E8D9',
		category: IconCategory.Stars,
		tags: ['banned','blocked','star'],
	});
	mDetails.set("UngroupObject", {
		type: IconType.Wireframe,
		unicode: 'F4F2',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Unknown", {
		type: IconType.Wireframe,
		unicode: 'E9CE',
		category: IconCategory.Stars,
		tags: ['question mark'],
	});
	mDetails.set("UnknownCall", {
		type: IconType.Wireframe,
		unicode: 'ED97',
		category: IconCategory.Communication,
		tags: ['telephone','question mark'],
	});
	mDetails.set("UnknownMirrored", {
		type: IconType.Wireframe,
		unicode: 'F22E',
		category: IconCategory.Stars,
		tags: ['question mark'],
	});
	mDetails.set("UnknownMirroredSolid", {
		type: IconType.Solid,
		unicode: 'F2E2',
		category: IconCategory.Stars,
		tags: ['question mark'],
	});
	mDetails.set("UnknownSolid", {
		type: IconType.Solid,
		unicode: 'F2E1',
		category: IconCategory.Stars,
		tags: ['question mark'],
	});
	mDetails.set("Unlock", {
		type: IconType.Wireframe,
		unicode: 'E785',
		category: IconCategory.Security,
		tags: [],
	});
	mDetails.set("UnlockSolid", {
		type: IconType.Solid,
		unicode: 'F304',
		category: IconCategory.Security,
		tags: [],
	});
	mDetails.set("Unpin", {
		type: IconType.Wireframe,
		unicode: 'E77A',
		category: IconCategory.Stuff,
		tags: ['thumbtack'],
	});
	mDetails.set("UnpublishContent", {
		type: IconType.Wireframe,
		unicode: 'E31F',
		category: IconCategory.Arrows,
		tags: ['up','banned','blocked'],
	});
	mDetails.set("UnSetColor", {
		type: IconType.Wireframe,
		unicode: 'F3F9',
		category: IconCategory.Formatting,
		tags: [],
	});
	mDetails.set("UnstackSelected", {
		type: IconType.Wireframe,
		unicode: 'E7FE',
		category: IconCategory.Layout,
		tags: ['arrow','right'],
	});
	mDetails.set("Unsubscribe", {
		type: IconType.Wireframe,
		unicode: 'EDA0',
		category: IconCategory.Communication,
		tags: ['inbox','x','cancel','delete','remove'],
	});
	mDetails.set("UnsyncFolder", {
		type: IconType.Wireframe,
		unicode: 'E8F6',
		category: IconCategory.Folders,
		tags: [],
	});
	mDetails.set("UnsyncOccurence", {
		type: IconType.Wireframe,
		unicode: 'F4A4',
		category: IconCategory.Arrows,
		tags: ['refresh','reload','loop'],
	});
	mDetails.set("Untag", {
		type: IconType.Wireframe,
		unicode: 'F60B',
		category: IconCategory.Tags,
		tags: ['x','cancel','clear','delete','remove'],
	});
	mDetails.set("Up", {
		type: IconType.Wireframe,
		unicode: 'E74A',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("UpdateRestore", {
		type: IconType.Wireframe,
		unicode: 'E777',
		category: IconCategory.Arrows,
		tags: ['loop'],
	});
	mDetails.set("UpgradeAnalysis", {
		type: IconType.Wireframe,
		unicode: 'EA0B',
		category: IconCategory.Documents,
		tags: ['arrow','up','checkmark','page','file'],
	});
	mDetails.set("Upload", {
		type: IconType.Wireframe,
		unicode: 'E898',
		category: IconCategory.Arrows,
		tags: [],
	});
	mDetails.set("URLBlock", {
		type: IconType.Wireframe,
		unicode: 'E3FE',
		category: IconCategory.Formatting,
		tags: ['chain','hyperlink','remove','delete','collapse'],
	});
	mDetails.set("USB", {
		type: IconType.Wireframe,
		unicode: 'E88E',
		category: IconCategory.Devices,
		tags: ['thumbdrive'],
	});
	mDetails.set("UserEvent", {
		type: IconType.Wireframe,
		unicode: 'F69C',
		category: IconCategory.User,
		tags: ['person','contact','lightning bolt'],
	});
	mDetails.set("UserFollowed", {
		type: IconType.Wireframe,
		unicode: 'F25C',
		category: IconCategory.User,
		tags: ['person','contact','checkmark','approved','accepted'],
	});
	mDetails.set("UserGauge", {
		type: IconType.Wireframe,
		unicode: 'F6ED',
		category: IconCategory.User,
		tags: ['person','contact','dashboard','speedometer'],
	});
	mDetails.set("UserOptional", {
		type: IconType.Wireframe,
		unicode: 'F767',
		category: IconCategory.User,
		tags: ['person','contact','info'],
	});
	mDetails.set("UserPause", {
		type: IconType.Wireframe,
		unicode: 'F2BA',
		category: IconCategory.User,
		tags: ['person','contact','media','controls'],
	});
	mDetails.set("UserRemove", {
		type: IconType.Wireframe,
		unicode: 'F69B',
		category: IconCategory.User,
		tags: ['person','contact','x','close','delete','cancel'],
	});
	mDetails.set("UserSync", {
		type: IconType.Wireframe,
		unicode: 'F2B9',
		category: IconCategory.User,
		tags: ['person','contact','refresh','reload','reset'],
	});
	mDetails.set("UserWarning", {
		type: IconType.Wireframe,
		unicode: 'E368',
		category: IconCategory.User,
		tags: ['person','contact','alert','exclamation mark'],
	});
	mDetails.set("Vacation", {
		type: IconType.Wireframe,
		unicode: 'F49F',
		category: IconCategory.Maps,
		tags: [],
	});
	mDetails.set("Vaccination", {
		type: IconType.Wireframe,
		unicode: 'EAE0',
		category: IconCategory.Health,
		tags: ['shot','immunization','medicine','needle','hypodermic'],
	});
	mDetails.set("Variable", {
		type: IconType.Wireframe,
		unicode: 'F305',
		category: IconCategory.Math,
		tags: ['formula','equation'],
	});
	mDetails.set("Variable2", {
		type: IconType.Wireframe,
		unicode: 'F86D',
		category: IconCategory.Math,
		tags: ['formula','equation'],
	});
	mDetails.set("Variable3", {
		type: IconType.Wireframe,
		unicode: 'E4D1',
		category: undefined,
		tags: [],
	});
	mDetails.set("VariableGroup", {
		type: IconType.Wireframe,
		unicode: 'F31B',
		category: IconCategory.Math,
		tags: ['formula','equation'],
	});
	mDetails.set("VB", {
		type: IconType.Wireframe,
		unicode: 'F2F2',
		category: IconCategory.Development,
		tags: ['language','programming'],
	});
	mDetails.set("VennDiagram", {
		type: IconType.Wireframe,
		unicode: 'F273',
		category: IconCategory.Charts,
		tags: [],
	});
	mDetails.set("VerifiedBrand", {
		type: IconType.Wireframe,
		unicode: 'F7BD',
		category: IconCategory.Stars,
		tags: ['burst','checkmark'],
	});
	mDetails.set("VerifiedBrandSolid", {
		type: IconType.Solid,
		unicode: 'F6AD',
		category: IconCategory.Stars,
		tags: ['burst','checkmark'],
	});
	mDetails.set("VersionControlPush", {
		type: IconType.Wireframe,
		unicode: 'F664',
		category: IconCategory.Development,
		tags: ['git','source control'],
	});
	mDetails.set("VerticalDistributeCenter", {
		type: IconType.Wireframe,
		unicode: 'F4FA',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Video", {
		type: IconType.Wireframe,
		unicode: 'E714',
		category: IconCategory.Devices,
		tags: ['camera','movie'],
	});
	mDetails.set("Video360Generic", {
		type: IconType.Wireframe,
		unicode: 'F609',
		category: IconCategory.Maps,
		tags: ['globe','arrow','right','travel','flight'],
	});
	mDetails.set("VideoLightOff", {
		type: IconType.Wireframe,
		unicode: 'EA74',
		category: IconCategory.Stuff,
		tags: ['flashlight','torch','tool'],
	});
	mDetails.set("VideoOff", {
		type: IconType.Wireframe,
		unicode: 'F4B0',
		category: IconCategory.Devices,
		tags: ['camera','movie','banned','blocked'],
	});
	mDetails.set("VideoOff2", {
		type: IconType.Wireframe,
		unicode: 'E43C',
		category: IconCategory.Devices,
		tags: ['camera','movie'],
	});
	mDetails.set("VideoSearch", {
		type: IconType.Wireframe,
		unicode: 'F4EA',
		category: IconCategory.Media,
		tags: ['film','movies'],
	});
	mDetails.set("VideoSolid", {
		type: IconType.Solid,
		unicode: 'EA0C',
		category: IconCategory.Devices,
		tags: ['camera','movie'],
	});
	mDetails.set("View", {
		type: IconType.Wireframe,
		unicode: 'E890',
		category: IconCategory.Interface,
		tags: ['eye','visible'],
	});
	mDetails.set("ViewAll", {
		type: IconType.Wireframe,
		unicode: 'E8A9',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ViewAll2", {
		type: IconType.Wireframe,
		unicode: 'EF56',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ViewDashboard", {
		type: IconType.Wireframe,
		unicode: 'F246',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ViewInAR", {
		type: IconType.Wireframe,
		unicode: 'E41F',
		category: IconCategory.Interface,
		tags: ['augmented reality','arrows','cube'],
	});
	mDetails.set("ViewList", {
		type: IconType.Wireframe,
		unicode: 'F247',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ViewListGroup", {
		type: IconType.Wireframe,
		unicode: 'F248',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ViewListTree", {
		type: IconType.Wireframe,
		unicode: 'F249',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("ViewOriginal", {
		type: IconType.Wireframe,
		unicode: 'E7B4',
		category: IconCategory.Interface,
		tags: [],
	});
	mDetails.set("VisioDiagram", {
		type: IconType.Wireframe,
		unicode: 'F2A0',
		category: IconCategory.Charts,
		tags: ['flowchart'],
	});
	mDetails.set("VisioDiagramSync", {
		type: IconType.Wireframe,
		unicode: 'F762',
		category: IconCategory.Charts,
		tags: ['refresh','flowchart'],
	});
	mDetails.set("VisioDocument", {
		type: IconType.Wireframe,
		unicode: 'F2A9',
		category: IconCategory.Documents,
		tags: ['office','file','vsx','vsd'],
	});
	mDetails.set("VisioLogo", {
		type: IconType.Wireframe,
		unicode: 'F2A7',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("VisioLogo16", {
		type: IconType.Wireframe,
		unicode: 'F3A3',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("VisioLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'ED7D',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("VisioLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F3A2',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("VisualBasicLanguage", {
		type: IconType.Wireframe,
		unicode: 'F2F1',
		category: IconCategory.Development,
		tags: ['programming'],
	});
	mDetails.set("VisuallyImpaired", {
		type: IconType.Wireframe,
		unicode: 'F866',
		category: IconCategory.Interface,
		tags: ['eye'],
	});
	mDetails.set("VisualsFolder", {
		type: IconType.Wireframe,
		unicode: 'F520',
		category: IconCategory.Folders,
		tags: ['chart','report'],
	});
	mDetails.set("VisualsStore", {
		type: IconType.Wireframe,
		unicode: 'F521',
		category: IconCategory.Stuff,
		tags: ['bag','cart','chart'],
	});
	mDetails.set("VisualStudioIDELogo32", {
		type: IconType.Wireframe,
		unicode: 'F5D0',
		category: IconCategory.Logos,
		tags: ['microsoft','brand'],
	});
	mDetails.set("VisualStudioLogo", {
		type: IconType.Wireframe,
		unicode: 'EC22',
		category: IconCategory.Logos,
		tags: ['microsoft','brand'],
	});
	mDetails.set("VoicemailForward", {
		type: IconType.Wireframe,
		unicode: 'ED87',
		category: IconCategory.Communication,
		tags: ['phone','arrow','right','send'],
	});
	mDetails.set("VoicemailIRM", {
		type: IconType.Wireframe,
		unicode: 'F421',
		category: IconCategory.Communication,
		tags: ['phone','minus','remove','delete'],
	});
	mDetails.set("VoicemailReply", {
		type: IconType.Wireframe,
		unicode: 'ED88',
		category: IconCategory.Communication,
		tags: ['phone','arrow','left'],
	});
	mDetails.set("Volume0", {
		type: IconType.Wireframe,
		unicode: 'E992',
		category: IconCategory.Media,
		tags: ['sound','speaker','loud'],
	});
	mDetails.set("Volume1", {
		type: IconType.Wireframe,
		unicode: 'E993',
		category: IconCategory.Media,
		tags: ['sound','speaker','loud'],
	});
	mDetails.set("Volume2", {
		type: IconType.Wireframe,
		unicode: 'E994',
		category: IconCategory.Media,
		tags: ['sound','speaker','loud'],
	});
	mDetails.set("Volume3", {
		type: IconType.Wireframe,
		unicode: 'E995',
		category: IconCategory.Media,
		tags: ['sound','speaker','loud'],
	});
	mDetails.set("VolumeDisabled", {
		type: IconType.Wireframe,
		unicode: 'EA85',
		category: IconCategory.Media,
		tags: ['sound','speaker','loud','muted'],
	});
	mDetails.set("VSTSAltLogo1", {
		type: IconType.Wireframe,
		unicode: 'F382',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','visual studio team system'],
	});
	mDetails.set("VSTSAltLogo2", {
		type: IconType.Wireframe,
		unicode: 'F383',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','visual studio team system'],
	});
	mDetails.set("VSTSLogo", {
		type: IconType.Wireframe,
		unicode: 'F381',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','visual studio team system'],
	});
	mDetails.set("Waffle", {
		type: IconType.Wireframe,
		unicode: 'ED89',
		category: IconCategory.Controls,
		tags: ['menu'],
	});
	mDetails.set("WaffleOffice365", {
		type: IconType.Wireframe,
		unicode: 'F4E0',
		category: IconCategory.Controls,
		tags: ['menu'],
	});
	mDetails.set("WaitlistConfirm", {
		type: IconType.Wireframe,
		unicode: 'F550',
		category: IconCategory.Tasks,
		tags: ['checkmark','approved','complete','done'],
	});
	mDetails.set("WaitlistConfirmMirrored", {
		type: IconType.Wireframe,
		unicode: 'F551',
		category: IconCategory.Tasks,
		tags: ['checkmark','approved','complete','done'],
	});
	mDetails.set("Warning", {
		type: IconType.Wireframe,
		unicode: 'E7BA',
		category: IconCategory.Stars,
		tags: ['exclamation mark','triangle','error'],
	});
	mDetails.set("Warning12", {
		type: IconType.Wireframe,
		unicode: 'F62F',
		category: IconCategory.Stars,
		tags: ['exclamation mark','triangle','error'],
	});
	mDetails.set("WarningSolid", {
		type: IconType.Solid,
		unicode: 'F736',
		category: IconCategory.Stars,
		tags: ['exclamation mark','triangle','error'],
	});
	mDetails.set("WavingHand", {
		type: IconType.Wireframe,
		unicode: 'F807',
		category: IconCategory.Stuff,
		tags: ['hello','shake'],
	});
	mDetails.set("WebAppBuilderFragment", {
		type: IconType.Wireframe,
		unicode: 'E314',
		category: IconCategory.Development,
		tags: ['hexagon'],
	});
	mDetails.set("WebAppBuilderFragmentCreate", {
		type: IconType.Wireframe,
		unicode: 'E31B',
		category: IconCategory.Development,
		tags: ['hexagon','plus','add','new'],
	});
	mDetails.set("WebAppBuilderFragmentLock", {
		type: IconType.Wireframe,
		unicode: 'E4DD',
		category: IconCategory.Development,
		tags: ['hexagon','locked','secure'],
	});
	mDetails.set("WebAppBuilderFragmentTest", {
		type: IconType.Wireframe,
		unicode: 'E4DE',
		category: IconCategory.Development,
		tags: ['hexagon','beaker','science','chemistry'],
	});
	mDetails.set("WebAppBuilderModule", {
		type: IconType.Wireframe,
		unicode: 'E313',
		category: IconCategory.Development,
		tags: ['hexagon'],
	});
	mDetails.set("WebAppBuilderModuleLock", {
		type: IconType.Wireframe,
		unicode: 'E4DF',
		category: IconCategory.Development,
		tags: ['hexagon'],
	});
	mDetails.set("WebAppBuilderModuleTest", {
		type: IconType.Wireframe,
		unicode: 'E4E0',
		category: IconCategory.Development,
		tags: ['hexagon','beaker','science','chemistry'],
	});
	mDetails.set("WebAppBuilderSlot", {
		type: IconType.Wireframe,
		unicode: 'E315',
		category: IconCategory.Development,
		tags: ['hexagon'],
	});
	mDetails.set("Webcam2", {
		type: IconType.Wireframe,
		unicode: 'E960',
		category: IconCategory.Devices,
		tags: ['camera','record','video'],
	});
	mDetails.set("Webcam2Off", {
		type: IconType.Wireframe,
		unicode: 'E36D',
		category: IconCategory.Devices,
		tags: ['camera','record','video'],
	});
	mDetails.set("WebComponents", {
		type: IconType.Wireframe,
		unicode: 'EC8B',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("WebEnvironment", {
		type: IconType.Wireframe,
		unicode: 'E3DB',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("WebPublish", {
		type: IconType.Wireframe,
		unicode: 'F52F',
		category: IconCategory.Development,
		tags: [],
	});
	mDetails.set("Website", {
		type: IconType.Wireframe,
		unicode: 'EB41',
		category: IconCategory.Stars,
		tags: [],
	});
	mDetails.set("WebTemplate", {
		type: IconType.Wireframe,
		unicode: 'F6B2',
		category: IconCategory.Layout,
		tags: [],
	});
	mDetails.set("Weights", {
		type: IconType.Wireframe,
		unicode: 'EADB',
		category: IconCategory.Games,
		tags: ['exercise','sports','athletics','dumbbell'],
	});
	mDetails.set("Wheelchair", {
		type: IconType.Wireframe,
		unicode: 'F31F',
		category: IconCategory.Vehicles,
		tags: ['handicapped','racing','disabled','disability'],
	});
	mDetails.set("WhiteBoardApp16", {
		type: IconType.Wireframe,
		unicode: 'F673',
		category: IconCategory.Logos,
		tags: ['draw'],
	});
	mDetails.set("WhiteBoardApp32", {
		type: IconType.Wireframe,
		unicode: 'F674',
		category: IconCategory.Logos,
		tags: ['draw'],
	});
	mDetails.set("WifiEthernet", {
		type: IconType.Wireframe,
		unicode: 'EE77',
		category: IconCategory.Devices,
		tags: ['network'],
	});
	mDetails.set("WifiWarning4", {
		type: IconType.Wireframe,
		unicode: 'EB63',
		category: IconCategory.Devices,
		tags: ['network','outage','alert','exclamation mark','error'],
	});
	mDetails.set("WindDirection", {
		type: IconType.Wireframe,
		unicode: 'EBE6',
		category: IconCategory.Arrows,
		tags: ['up','triangle'],
	});
	mDetails.set("WindowEdit", {
		type: IconType.Wireframe,
		unicode: 'F50E',
		category: IconCategory.Interface,
		tags: ['pencil'],
	});
	mDetails.set("WindowsLogo", {
		type: IconType.Wireframe,
		unicode: 'E782',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','m365','os'],
	});
	mDetails.set("Wines", {
		type: IconType.Wireframe,
		unicode: 'EABF',
		category: IconCategory.Food,
		tags: ['alcohol','glass','drink','beverage'],
	});
	mDetails.set("WipePhone", {
		type: IconType.Wireframe,
		unicode: 'ED8D',
		category: IconCategory.Devices,
		tags: ['eraser','smart phone','clean'],
	});
	mDetails.set("WordDocument", {
		type: IconType.Wireframe,
		unicode: 'EF71',
		category: IconCategory.Documents,
		tags: ['office','file','docx'],
	});
	mDetails.set("WordLogo", {
		type: IconType.Wireframe,
		unicode: 'F1E3',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office'],
	});
	mDetails.set("WordLogo16", {
		type: IconType.Wireframe,
		unicode: 'F391',
		category: IconCategory.Logos,
		tags: ['office','brand','microsoft'],
	});
	mDetails.set("WordLogoInverse", {
		type: IconType.Wireframe,
		unicode: 'EC29',
		category: IconCategory.Logos,
		tags: ['office','brand','microsoft'],
	});
	mDetails.set("WordLogoInverse16", {
		type: IconType.Wireframe,
		unicode: 'F390',
		category: IconCategory.Logos,
		tags: ['office','brand','microsoft'],
	});
	mDetails.set("Work", {
		type: IconType.Wireframe,
		unicode: 'E821',
		category: IconCategory.Stuff,
		tags: ['luggage','suitcase','baggage','briefcase'],
	});
	mDetails.set("WorkFlow", {
		type: IconType.Wireframe,
		unicode: 'EA01',
		category: IconCategory.Arrows,
		tags: ['loop'],
	});
	mDetails.set("WorkforceManagement", {
		type: IconType.Wireframe,
		unicode: 'EE0F',
		category: IconCategory.User,
		tags: ['person','contact','people','bag','briefcase','crowd','luggage','suitcase'],
	});
	mDetails.set("WorkItem", {
		type: IconType.Wireframe,
		unicode: 'F314',
		category: IconCategory.Tasks,
		tags: ['clipboard','checkmark','approved','complete','done'],
	});
	mDetails.set("WorkItemAlert", {
		type: IconType.Wireframe,
		unicode: 'F78F',
		category: IconCategory.Tasks,
		tags: ['clipboard','bell','notification'],
	});
	mDetails.set("WorkItemBar", {
		type: IconType.Wireframe,
		unicode: 'F35C',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("WorkItemBarSolid", {
		type: IconType.Solid,
		unicode: 'F35D',
		category: IconCategory.Tasks,
		tags: [],
	});
	mDetails.set("WorkItemBug", {
		type: IconType.Wireframe,
		unicode: 'F315',
		category: IconCategory.Tasks,
		tags: ['clipboard','alert','warning','exclamation mark'],
	});
	mDetails.set("World", {
		type: IconType.Wireframe,
		unicode: 'E909',
		category: IconCategory.Maps,
		tags: ['earth','globe','planet'],
	});
	mDetails.set("WorldClock", {
		type: IconType.Wireframe,
		unicode: 'E918',
		category: IconCategory.Events,
		tags: ['globe','timezone'],
	});
	mDetails.set("YammerLogo", {
		type: IconType.Wireframe,
		unicode: 'ED19',
		category: IconCategory.Logos,
		tags: ['microsoft','brand','o365','m365','office','social'],
	});
	mDetails.set("ZipFolder", {
		type: IconType.Wireframe,
		unicode: 'F012',
		category: IconCategory.Folders,
		tags: ['compress'],
	});
	mDetails.set("Zoom", {
		type: IconType.Wireframe,
		unicode: 'E71E',
		category: IconCategory.Interface,
		tags: ['magnifying glass'],
	});
	mDetails.set("ZoomIn", {
		type: IconType.Wireframe,
		unicode: 'E8A3',
		category: IconCategory.Interface,
		tags: ['magnifying glass','plus','add'],
	});
	mDetails.set("ZoomOut", {
		type: IconType.Wireframe,
		unicode: 'E71F',
		category: IconCategory.Interface,
		tags: ['magnifying glass','minus'],
	});
	mDetails.set("ZoomToFit", {
		type: IconType.Wireframe,
		unicode: 'F649',
		category: IconCategory.Interface,
		tags: [],
	});


	//Extras
	mDetails.set("AmazonWebServicesLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("AndroidLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['robot','mobile','brand'],
	});
	mDetails.set("ApacheIvyLogo32", {
		type: IconType.Solid,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','leaf'],
	});
	mDetails.set("ApacheMavenLogo", {
		type: IconType.Solid,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("BitbucketLogo32", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','sourcecontrol'],
	});
	mDetails.set("BoxLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("ChronosLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','time','hourglass'],
	});
	mDetails.set("DockerLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','animals','whale','containers'],
	});
	mDetails.set("DropboxLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("EgnyteLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("ExternalGit", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','sourcecontrol'],
	});
	mDetails.set("FacebookLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','social'],
	});
	mDetails.set("GitFork", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','sourcecontrol'],
	});
	mDetails.set("GitHubLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','sourcecontrol'],
	});
	mDetails.set("GitLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','sourcecontrol'],
	});
	mDetails.set("GoogleDriveLogoBottomBlue", {
		type: IconType.Colorfill,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','parallelogram'],
	});
	mDetails.set("GoogleDriveLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("GoogleDriveLogoLeftGreen", {
		type: IconType.Colorfill,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','parallelogram'],
	});
	mDetails.set("GoogleDriveLogoRightYellow", {
		type: IconType.Colorfill,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','parallelogram'],
	});
	mDetails.set("GradleLogo32", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','animals','elephant','mascot'],
	});
	mDetails.set("iOSAppStoreLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','mobile','apple'],
	});
	mDetails.set("JavaLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','coffee','beverage','drink','steam','tea'],
	});
	mDetails.set("JenkinsLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','face','butler','bowtie','person','man','mascot'],
	});
	mDetails.set("KeubernetesLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("LinuxLogo32", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','animals','penguin','mascot'],
	});
	mDetails.set("MiracastLogoLarge", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("MixerLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("MTMLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("NonprofitLogo32", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','heart','hands','handshake'],
	});
	mDetails.set("NPMLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("OneDriveFileVault", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','safe','locked'],
	});
	mDetails.set("ParatureLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("Project2019Document", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','paper','file'],
	});
	mDetails.set("PythonLogoBlue", {
		type: IconType.Colorfill,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("PythonLogoYellow", {
		type: IconType.Colorfill,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("ReactLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','atom','nucleus'],
	});
	mDetails.set("RubyGemsLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','diamond'],
	});
	mDetails.set("RustLanguageLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("SVNLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("SwiftLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("TwitterLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','animals','bird','social'],
	});
	mDetails.set("XamarinLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand'],
	});
	mDetails.set("XboxController", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Games,
		tags: ['brand','console','gaming','videogames'],
	});
	mDetails.set("XboxLogo", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Logos,
		tags: ['brand','console','gaming','videogames'],
	});
	mDetails.set("XboxOneConsole", {
		type: IconType.Wireframe,
		unicode: undefined,
		category: IconCategory.Games,
		tags: ['brand','console','gaming','videogames','device'],
	});


	return mDetails;
};