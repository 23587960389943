import {ApplicationInsights, SeverityLevel} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

//Code adapted from https://github.com/Azure-Samples/application-insights-react-demo/blob/master/src/TelemetryService.js

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey:string) => {

        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided!')
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
				instrumentationKey: instrumentationKey,
				loggingLevelConsole: process.env.NODE_ENV === "development" ? 2 : 0, //Internal logging of AI to console (0=off, 1=critical only, 2=errors & warnings)
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {}
                }
            }
        });
        appInsights.loadAppInsights();
    };

    return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;


//Extra helper functions to make it easier to interact with logging

export const LogException = (error:Error) => {
	appInsights.trackException({
		error: error,
		severityLevel: SeverityLevel.Error
	});
};

export const logError = (message:string) => {
	appInsights.trackException({
		error: new Error(message),
		severityLevel: SeverityLevel.Error
	});
};

export const logMessage = (message:string) => {
	appInsights.trackTrace({
		message: message,
		severityLevel: SeverityLevel.Information
	});
};

export const logWarning= (message:string) => {
	appInsights.trackTrace({
		message: message,
		severityLevel: SeverityLevel.Warning
	});
};

export const logEvent= (eventName:string, data?:any) => {
	appInsights.trackEvent({name:eventName}, data);
	if(process.env.NODE_ENV === "development") {
		console.log("Event: " + eventName);
		if(Boolean(data)) {
			console.log(data);
		}
	}
};