import { CommandBar, Icon, Stack, Text } from "@fluentui/react";
import React, { useContext, useState } from "react";

import { ThemeContext } from "../../App";
import { getClassNames } from "./header.classNames";
import ThemePanel from "./themePanel/themePanel";
import HelpPanel from "./helpPanel/helpPanel";

import {logEvent} from '../../helpers/telemetryService';

const Header: React.FC<{}> = () => {
	const { palette, theme, changeTheme, tryTheme } = useContext(ThemeContext);
	const classes = getClassNames(palette);

	const [themePanelVisible, setThemePanelVisible] = useState(false);
	const [helpPanelVisible, setHelpPanelVisible] = useState(false);

	return (
		<Stack className={classes.root} horizontal verticalAlign="center">
			<Stack.Item>
				<Icon iconName="TestBeakerSolid" className={classes.icon} />
			</Stack.Item>
			<Stack.Item grow>
				<Text className={classes.label}>Flicon</Text>
			</Stack.Item>
			<Stack.Item>
				<CommandBar className={classes.menu}
					items={[
						{ key: 'help', iconProps: { iconName: 'Help' }, disabled: helpPanelVisible, title: 'Flicon Guidance', onClick: () => {
							logEvent('Opened Help Panel');
							setHelpPanelVisible(true);
						}},
						{ key: 'theme', iconProps: { iconName: 'ColorSolid' }, disabled: themePanelVisible, title: 'Display Options', onClick: () => {
							logEvent('Opened Display Panel');
							setThemePanelVisible(true);
						}},
					]}
				/>
			</Stack.Item>
			<ThemePanel
				visible={themePanelVisible}
				currentTheme={theme}
				updatePanelVisibility={(visible: boolean) => setThemePanelVisible(visible)}
				tryThemeSelection={(themeName: string) => tryTheme(themeName)}
				updateThemeSelection={(themeName: string) => changeTheme(themeName)} />
			<HelpPanel
				visible={helpPanelVisible}
				updatePanelVisibility={(visible: boolean) => setHelpPanelVisible(visible)}/>
		</Stack>
	);
}
export default Header;